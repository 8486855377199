import BaseOfflineResource from '@/offline/base'
import { GRADO_ANOMALIA } from '@/utils/consts'

export default class GradoAnomaliaOfflineResource extends BaseOfflineResource {
  selectGradoAnomaliaCorrecto () {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.grado_anomalia)
      .where(this.db.op.and(
        tables.grado_anomalia.clasificacion.eq(GRADO_ANOMALIA.clasificaciones.correcto),
        tables.grado_anomalia.estado.gt(0)
      ))
      .exec()
  }
  selectGradosAnomaliaNoProcede () {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.grado_anomalia)
      .where(this.db.op.and(
        tables.grado_anomalia.clasificacion.eq(GRADO_ANOMALIA.clasificaciones.noProcede),
        tables.grado_anomalia.estado.gt(0)
      ))
      .exec()
  }
  selectGradosAnomaliaConAnomalias () {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.grado_anomalia)
      .where(this.db.op.and(
        tables.grado_anomalia.clasificacion.eq(GRADO_ANOMALIA.clasificaciones.incorrecto),
        tables.grado_anomalia.estado.gt(0)
      ))
      .exec()
  }
}
