import BaseOnlineResource from '@/online/base'
import { TABLA } from '@/utils/consts'

export default class TablaOnlineResource extends BaseOnlineResource {
  getIdTablaDeRouteParams (routeParams) {
    for (const key in routeParams) {
      const nombreTabla = key.replace('id', '')
      if (TABLA.hasOwnProperty(nombreTabla)) {
        return TABLA[nombreTabla]['idtabla']
      }
    }
  }
}
