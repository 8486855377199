import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'
import { TABLA } from '@/utils/consts'

export default [
  {
    path: 'add',
    name: 'operacionescrm__operacioncrm-add',
    component: load('operacionescrm/operacioncrmAdd/OperacioncrmAdd'),
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.insert,
      },
    },
    props: {
      viewRoute: 'operacionescrm__operacioncrm-view',
    },
  },
  {
    path: 'abiertas',
    name: 'operacionescrm__abiertas-operacioncrm-list',
    component: load('operacionescrm/operacioncrmList/OperacioncrmList'),
    props: {
      abierta: true,
      asignada: true,
      addRoute: 'operacionescrm__operacioncrm-add',
      viewRoute: 'operacionescrm__operacioncrm-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'cerradas',
    name: 'operacionescrm__cerradas-operacioncrm-list',
    component: load('operacionescrm/operacioncrmList/OperacioncrmList'),
    props: {
      abierta: false,
      asignada: true,
      addRoute: 'operacionescrm__operacioncrm-add',
      viewRoute: 'operacionescrm__operacioncrm-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: '',
    name: 'operacionescrm__operacioncrm-list',
    component: load('operacionescrm/operacioncrmList/OperacioncrmList'),
    props: {
      addRoute: 'operacionescrm__operacioncrm-add',
      viewRoute: 'operacionescrm__operacioncrm-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idoperacioncrm',
    name: 'operacionescrm__operacioncrm-view',
    component: load('operacionescrm/operacioncrmView/OperacioncrmView'),
    props: {
      editRoute: 'operacionescrm__operacioncrm-edit',
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idoperacioncrm/edit',
    name: 'operacionescrm__operacioncrm-edit',
    component: load('operacionescrm/operacioncrmEdit/OperacioncrmEdit'),
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.edit,
      },
    },
  },
  // contactos
  {
    path: ':idoperacioncrm/contactos',
    name: 'contactos__contacto-cliente-list',
    component: load('contactosCliente/contactoClienteList/ContactoClienteList'),
    props: {
      addRoute: 'contactos__contacto-cliente-add',
      viewRoute: 'contactos__contacto-cliente-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.operacionesContactos.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idoperacioncrm/contactos/:idcontacto_cliente',
    name: 'contactos__contacto-cliente-view',
    component: load('contactosCliente/contactoClienteView/ContactoClienteView'),
    props: {
      editRoute: 'contactos__contacto-cliente-edit',
    },
    meta: {
      permission: {
        idobjeto: permissions.operacionesContactos.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idoperacioncrm/contactos/:idcontacto_cliente/edit',
    name: 'contactos__contacto-cliente-edit',
    component: load('contactosCliente/contactoClienteEdit/ContactoClienteEdit'),
    meta: {
      permission: {
        idobjeto: permissions.operacionesContactos.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idoperacioncrm/contactos/add',
    name: 'contactos__contacto-cliente-add',
    component: load('contactosCliente/contactoClienteAdd/ContactoClienteAdd'),
    props: {
      viewRoute: 'contactos__contacto-cliente-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.operacionesContactos.id,
        access: ACCESS.view,
      },
    },
  },
  // clientes
  {
    path: 'clientes/:idcliente/add',
    name: 'operacionescrm__clientes-operacioncrm-add',
    component: load('operacionescrm/operacioncrmAdd/OperacioncrmAdd'),
    props: {
      tabla: TABLA.cliente,
      viewRoute: 'operacionescrm__clientes-operacioncrm-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'clientes/:idcliente',
    name: 'operacionescrm__clientes-operacioncrm-list',
    component: load('operacionescrm/operacioncrmList/OperacioncrmList'),
    props: {
      addRoute: 'operacionescrm__clientes-operacioncrm-add',
      viewRoute: 'operacionescrm__clientes-operacioncrm-view',
      tabla: TABLA.cliente
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/:idcliente/:idoperacioncrm',
    name: 'operacionescrm__clientes-operacioncrm-view',
    component: load('operacionescrm/operacioncrmView/OperacioncrmView'),
    props: {
      editRoute: 'operacionescrm__operacioncrm-edit',
      loperacioncrmRoute: 'loperacionescrm__clientes-loperaciocrm-list',
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  // sistemas
  {
    path: 'sistemas/:idsistema/add',
    name: 'operacionescrm__sistemas-operacioncrm-add',
    component: load('operacionescrm/operacioncrmAdd/OperacioncrmAdd'),
    props: {
      viewRoute: 'operacionescrm__sistemas-operacioncrm-view',
      tabla: TABLA.sistema,
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'sistemas/:idsistema',
    name: 'operacionescrm__sistemas-operacioncrm-list',
    component: load('operacionescrm/operacioncrmList/OperacioncrmList'),
    props: {
      addRoute: 'operacionescrm__sistemas-operacioncrm-add',
      viewRoute: 'operacionescrm__sistemas-operacioncrm-view',
      tabla: TABLA.sistema,
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'sistemas/:idsistema/:idoperacioncrm',
    name: 'operacionescrm__sistemas-operacioncrm-view',
    component: load('operacionescrm/operacioncrmView/OperacioncrmView'),
    props: {
      editRoute: 'operacionescrm__operacioncrm-edit',
      loperacioncrmRoute: 'loperacionescrm__sistemas-loperacioncrm-list',
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  // clientes-potenciales
  {
    path: 'clientes-potenciales/:idcliente_potencial',
    name: 'operacionescrm__clientes-potenciales-operacioncrm-list',
    component: load('operacionescrm/operacioncrmList/OperacioncrmList'),
    props: {
      addRoute: 'operacionescrm__clientes-potenciales-operacioncrm-add',
      viewRoute: 'operacionescrm__clientes-potenciales-operacioncrm-view',
      tabla: TABLA.cliente_potencial,
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/:idoperacioncrm',
    name: 'operacionescrm__clientes-potenciales-operacioncrm-view',
    component: load('operacionescrm/operacioncrmView/OperacioncrmView'),
    props: {
      editRoute: 'operacionescrm__operacioncrm-edit',
      loperacioncrmRoute: 'loperacionescrm__clientes-potenciales-loperacioncrm-list',
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/add',
    name: 'operacionescrm__clientes-potenciales-operacioncrm-add',
    component: load('operacionescrm/operacioncrmAdd/OperacioncrmAdd'),
    props: {
      tabla: TABLA.cliente_potencial,
      viewRoute: 'operacionescrm__clientes-potenciales-operacioncrm-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.insert,
      },
    },
  },
  // presupuestos
  {
    path: 'presupuestos/:idpresupuestocli',
    name: 'operacionescrm__presupuestos-operacioncrm-list',
    component: load('operacionescrm/operacioncrmList/OperacioncrmList'),
    props: {
      addRoute: 'operacionescrm__presupuestos-operacioncrm-add',
      viewRoute: 'operacionescrm__presupuestos-operacioncrm-view',
      tabla: TABLA.presupuestocli,
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'presupuestos/:idpresupuestocli/:idoperacioncrm',
    name: 'operacionescrm__presupuestos-operacioncrm-view',
    component: load('operacionescrm/operacioncrmView/OperacioncrmView'),
    props: {
      editRoute: 'operacionescrm__operacioncrm-edit',
      loperacioncrmRoute: 'loperacionescrm__presupuestos-loperacioncrm-list',
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'presupuestos/:idpresupuestocli/add',
    name: 'operacionescrm__presupuestos-operacioncrm-add',
    component: load('operacionescrm/operacioncrmAdd/OperacioncrmAdd'),
    props: {
      tabla: TABLA.presupuestocli,
      viewRoute: 'operacionescrm__presupuestos-operacioncrm-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.insert,
      },
    },
  },
  // Lineas
  {
    path: ':idoperacioncrm/lineas',
    name: 'loperacionescrm-loperaciocrm-list',
    component: load('loperacionescrm/loperacioncrmList/LoperacioncrmList'),
    meta: {
      permission: {
        idobjeto: permissions.operaciones.id,
        access: ACCESS.view,
      },
    },
  },
]