import BaseOfflineResource from '@/offline/base'


export default class AccionCorrectoraOfflineResource extends BaseOfflineResource {
  async MensajeFacturacionAccionCorrectora (Vue, accionCorrectora, materialAfectado) {
    // Devuelve lo que facturaría una acción correctora, el artículo, la acción ó el artículo facturable por la acción si ésta es facturable
    let toReturn = ''
    let articulo = null
    if (accionCorrectora.accion_correctora.idarticulo) {
      articulo = await Vue.$offline.articulo.row(accionCorrectora.accion_correctora.idarticulo)
      toReturn = `Artículo: ${articulo.articulo.descripcion} facturable`
    } else if (accionCorrectora.accion_correctora.idmaccion) {
      const maccion = await Vue.$offline.maccion.row(accionCorrectora.accion_correctora.idmaccion)
      if (!maccion.maccion.facturable) {
        toReturn = `Acción: ${maccion.maccion.descripcion}`
      } else {
        articulo = (await Vue.$offline.accionEjec.selectArticuloFacturar(
          accionCorrectora.accion_correctora.idmaccion, materialAfectado.material_sistema.idarticulo
        ))[0]
        toReturn = `Artículo: ${articulo.articulo.descripcion} facturable`
      }
    }
    return toReturn
  }
}