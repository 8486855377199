import BaseOfflineResource from '@/offline/base'
import { currentDateTime } from '@/utils/date'

export default class SyncUploadOfflineResource extends BaseOfflineResource {
  async insert (data = {}, options = {}) {
    data.timestamp = currentDateTime()
    data.count_ok = null
    data.count_errors = null
    data.synced = false
    return await super.insert(data, options)
  }
}
