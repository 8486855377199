import BaseOfflineResource from '@/offline/base'

export default class AnticipocliOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'anticipocli.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
}
