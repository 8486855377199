export const API_RESULT_DATATYPES = {
  datetime: 'datetime',
  number: 'number',
  string: 'string',
  boolean: 'boolean'
}

export const API_FILTER_OPERATORS = {
  OR: 'or',
  AND: 'and'
}

const API_FILTER_COMPARISON_OPERATORS = {
  ILIKE: 'ilike',
  ICONTAINSWORDS: 'icontainswords',
  ISNULL: 'isnull',
  IN: 'in',
  GT: '>',
  GTE: '>=',
  LT: '<',
  LTE: '<=',
  EXACT: '=',
  IEXACT: 'iexact',
  NE: '<>',
  NOP: '',
}

/**
 * Filtro para aplicar a llamadas a la API
 */
export const APIFilter = (operator = API_FILTER_OPERATORS.AND) => {
  let filter = {
    operator: operator,
    clauses: []
  }
  filter.clear = () => {
    // TODO: memory leak?
    filter.clauses.length = 0
    return filter
  }
  filter.setOperator = (operator) => {
    filter.operator = operator
    return filter
  }
  filter._addClause = (field, operator, value, not = false) => {
    if (operator === 'ilike') {
      value = `%${value}%`
    }
    filter.clauses.push({
      field,
      operator,
      value,
      not
    })
    return filter
  }
  filter.addILike = (field, value, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.ILIKE, value, not)
  }
  filter.addIContainsWords = (field, value, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.ICONTAINSWORDS, value, not)
  }
  filter.addIsNull = (field, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.ISNULL, null, not)
  }
  filter.addIn = (field, value, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.IN, value, not)
  }
  filter.addGT = (field, value, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.GT, value, not)
  }
  filter.addGTE = (field, value, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.GTE, value, not)
  }
  filter.addLT = (field, value, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.LT, value, not)
  }
  filter.addLTE = (field, value, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.LTE, value, not)
  }
  filter.addExact = (field, value, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.EXACT, value, not)
  }
  filter.addIExact = (field, value, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.IEXACT, value, not)
  }
  filter.addNE = (field, value, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.NE, value, not)
  }
  filter.addNOP = (field, not = false) => {
    return filter._addClause(field, API_FILTER_COMPARISON_OPERATORS.NOP, null, not)
  }
  filter.addNestedFilter = (newFilter) => {
    filter.clauses.push(newFilter)
    return filter
  }
  return filter
}
