export const pad = (v, length = 2, char = '0') => {
  let val = v.toString()
  return val.length >= length
    ? val
    : new Array(length - val.length + 1).join(char) + val
}

export const capitalize = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  } else {
    return ''
  }
}

export const camelToSnakeCase = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}
