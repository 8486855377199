import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      fcita: {
        label: 'Fecha',
        type: 'date',
        value: null,
        field: 'fcita',
      },
      ffin: {
        label: 'Fecha de fin',
        type: 'date',
        value: null,
        field: 'ffin',
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)