<template>
  <v-list
    :two-line="listItemLines === 2"
    :three-line="listItemLines === 3"
  >
    <template
      v-for="(detail, index) in sortedDetails"
    >
      <v-subheader
        v-if="detail.type === 'header'"
        :key="index"
        v-text="detail.title"
      />
      <v-list-item
        v-else
        :key="index"
        :disabled="detail.disabled"
        @click="clickDetail(detail, index)"
      >
        <v-list-item-avatar>
          <slot
            name="avatar"
            :detail="detail"
          >
            <v-icon
              :color="detail.iconColor"
            >
              {{ $vuetify.icons.values[detail.icon] }}
            </v-icon>
          </slot>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            v-html="detail.title"
          />
          <v-list-item-subtitle
            v-if="detail.subtitle"
            v-html="detail.subtitle"
          />
          <v-list-item-subtitle
            v-show="detail.totals"
            v-html="detail.totals"
          />
        </v-list-item-content>
        <v-list-item-action>
          <v-chip
            v-if="detail.badge !== ''"
            dark
            small
            :color="defaultBadgeColor(detail)"
          >
            {{ detail.badge }}
          </v-chip>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import _ from '@/utils/lodash'
import colors from 'vuetify/lib/util/colors'

export default {
  props: {
    details: {
      type: Array,
      required: true,
    },
    listItemLines: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    sortedDetails () {
      return _.sortBy(this.details, ['order'])
    }
  },
  methods: {
    clickDetail (detail, index) {
      if (!detail.disabled) {
        this.$emit('click-detail', { detail, index })
      }
    },
    defaultBadgeColor (detail) {
      if (detail.badgeColor) {
        return detail.badgeColor
      } else if (isNaN(+detail.badge)) {
        return 'info'
      } else {
        return +detail.badge > 0 ? 'info' : colors.grey.base
      }
    },
  },
}
</script>

<style scoped>
.v-list-item--disabled {
  pointer-events: auto !important;
}
</style>