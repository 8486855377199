import { ListStoreBase, CreateStore } from '@/store/pages/base'
import { DEUDA_CLIENTE } from '@/utils/consts'

const pageStore = {
  state: {
    filter: {
      estado: {
        label: 'Estado',
        type: 'multiple-select',
        value: null,
        items: [
          {
            estado: DEUDA_CLIENTE.estados.pendiente,
            descripcion: DEUDA_CLIENTE.descripciones.pendiente
          },
          {
            estado: DEUDA_CLIENTE.estados.cobrada,
            descripcion: DEUDA_CLIENTE.descripciones.cobrada
          },
          {
            estado: DEUDA_CLIENTE.estados.parcialmenteCobrada,
            descripcion: DEUDA_CLIENTE.descripciones.parcialmenteCobrada
          },
          {
            estado: DEUDA_CLIENTE.estados.dudosoCobro,
            descripcion: DEUDA_CLIENTE.descripciones.dudosoCobro
          },
          {
            estado: DEUDA_CLIENTE.estados.incobrable,
            descripcion: DEUDA_CLIENTE.descripciones.incobrable
          },
        ],
        field: 'estado_deuda',
        selectItemValue: 'estado',
        selectItemText: 'descripcion',
      },
      fdesde: {
        label: 'Fecha desde',
        type: 'date',
        value: null,
        field: 'ffactura',
      },
      fhasta: {
        label: 'Fecha hasta',
        type: 'date',
        value: null,
        field: 'ffactura',
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
