import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      fdesde: {
        label: 'Fecha desde',
        type: 'date',
        value: null,
        field: 'fcontacto',
      },
      fhasta: {
        label: 'Fecha hasta',
        type: 'date',
        value: null,
        field: 'fcontacto',
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)