import BaseOfflineResource from '@/offline/base'

export default class SistemaTviacomunicacionOfflineResource extends BaseOfflineResource {
  selectDeSistema (idsistema) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.sistema_tviacomunicacion)
      .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
      .where(
        this.db.op.and(
          tables.sistema_tviacomunicacion.idsistema.eq(idsistema),
          tables.sistema_tviacomunicacion.estado.gt(0),
        )
      )
      .orderBy(tables.sistema_tviacomunicacion.orden)
      .exec()
  }
}
