import store from '@/store'
import { ENTITIES } from '@/store/dirty/index'

export default {
  install: (Vue) => {
    Vue.$dirty = Vue.prototype.$dirty = {
      ENTITIES,
      async modified (entity, id) {
        await store.dispatch('dirty/setDirty', { entity, id, deleted: false })
      },
      async deleted (entity, id) {
        await store.dispatch('dirty/setDirty', { entity, id, deleted: true })
      },
      async popDirty (route) {
        return await store.dispatch('dirty/popDirty', { route })
      },
    }
  }
}
