import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  {
    path: '',
    name: 'clientespotenciales__cliente-potencial-list',
    component: load('clientesPotenciales/clientePotencialList/ClientePotencialList'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencial.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'add',
    name: 'clientespotenciales__cliente-potencial-add',
    component: load('clientesPotenciales/clientePotencialAdd/ClientePotencialAdd'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencial.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: ':idcliente_potencial',
    name: 'clientespotenciales__cliente-potencial-view',
    component: load('clientesPotenciales/clientePotencialView/ClientePotencialView'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencial.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idcliente_potencial/edit',
    name: 'clientespotenciales__cliente-potencial-edit',
    component: load('clientesPotenciales/clientePotencialEdit/ClientePotencialEdit'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencial.id,
        access: ACCESS.edit,
      },
    },
  },
]
