export const nonEmpty = (strings, ...expressionValues) => {
  var nonEmptyString = ''
  for (let i = 0; i < strings.length; i++) {
    if (i > 0) {
      if (expressionValues[i - 1] !== null && expressionValues[i - 1] !== undefined) {
        nonEmptyString += expressionValues[i - 1]
      }
    }
    nonEmptyString += strings[i]
  }
  return nonEmptyString
}
