import BaseOfflineResource from '@/offline/base'

export default class DatoMaterialSistemaOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'datoMaterialSistema.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'datoMaterialSistema.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idDatoMaterialSistema) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'datoMaterialSistema.deleteSync',
      params: {
        iddato_material_sistema: idDatoMaterialSistema
      }
    })
    await this.Vue.$offline.datoMaterialSistema.delete({
      where: tables.dato_material_sistema.iddato_material_sistema.eq(idDatoMaterialSistema)
    })
  }
}
