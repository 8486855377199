import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  // clientes
  {
    path: 'clientes/:idcliente',
    name: 'telefonos__clientes-cliente-telefono-list',
    component: load('telefonos/clienteTelefonoList/ClienteTelefonoList'),
    meta: {
      permission: {
        idobjeto: permissions.clienteTelefono.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'telefonos__clientes-cliente-telefono-add',
      viewRoute: 'telefonos__clientes-cliente-telefono-view',
    },
  },
  {
    path: 'clientes/:idcliente/add',
    name: 'telefonos__clientes-cliente-telefono-add',
    component: load('telefonos/clienteTelefonoAdd/ClienteTelefonoAdd'),
    meta: {
      permission: {
        idobjeto: permissions.clienteTelefono.id,
        access: ACCESS.insert,
      },
    },
    props: {
      viewRoute: 'telefonos__clientes-cliente-telefono-view',
    },
  },
  {
    path: 'clientes/:idcliente/:idcliente_telefono',
    name: 'telefonos__clientes-cliente-telefono-view',
    component: load('telefonos/clienteTelefonoView/ClienteTelefonoView'),
    meta: {
      permission: {
        idobjeto: permissions.clienteTelefono.id,
        access: ACCESS.view,
      },
    },
    props: {
      editRoute: 'telefonos__clientes-cliente-telefono-edit',
    },
  },
  {
    path: 'clientes/:idcliente/:idcliente_telefono/edit',
    name: 'telefonos__clientes-cliente-telefono-edit',
    component: load('telefonos/clienteTelefonoEdit/ClienteTelefonoEdit'),
    meta: {
      permission: {
        idobjeto: permissions.clienteTelefono.id,
        access: ACCESS.edit,
      },
    },
  },
  // clientes potenciales
  {
    path: 'clientes-potenciales/:idcliente_potencial',
    name: 'telefonos__clientes-potenciales-cliente-telefono-list',
    component: load('telefonos/clienteTelefonoList/ClienteTelefonoList'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialTelefono.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'telefonos__clientes-potenciales-cliente-telefono-add',
      viewRoute: 'telefonos__clientes-potenciales-cliente-telefono-view',
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/add',
    name: 'telefonos__clientes-potenciales-cliente-telefono-add',
    component: load('telefonos/clienteTelefonoAdd/ClienteTelefonoAdd'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialTelefono.id,
        access: ACCESS.insert,
      },
    },
    props: {
      viewRoute: 'telefonos__clientes-potenciales-cliente-telefono-view',
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/:idcliente_telefono',
    name: 'telefonos__clientes-potenciales-cliente-telefono-view',
    component: load('telefonos/clienteTelefonoView/ClienteTelefonoView'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialTelefono.id,
        access: ACCESS.view,
      },
    },
    props: {
      editRoute: 'telefonos__clientes-potenciales-cliente-telefono-edit',
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/:idcliente_telefono/edit',
    name: 'telefonos__clientes-potenciales-cliente-telefono-edit',
    component: load('telefonos/clienteTelefonoEdit/ClienteTelefonoEdit'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialTelefono.id,
        access: ACCESS.edit,
      },
    },
  },
  // sistemas
  {
    path: 'sistemas/:idsistema',
    name: 'telefonos__sistemas-cliente-telefono-list',
    component: load('telefonos/clienteTelefonoList/ClienteTelefonoList'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaTelefono.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'telefonos__sistemas-cliente-telefono-add',
      viewRoute: 'telefonos__sistemas-cliente-telefono-view',
    },
  },
  {
    path: 'sistemas/:idsistema/add',
    name: 'telefonos__sistemas-cliente-telefono-add',
    component: load('telefonos/clienteTelefonoAdd/ClienteTelefonoAdd'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaTelefono.id,
        access: ACCESS.insert,
      },
    },
    props: {
      viewRoute: 'telefonos__sistemas-cliente-telefono-view',
    },
  },
  {
    path: 'sistemas/:idsistema/:idcliente_telefono',
    name: 'telefonos__sistemas-cliente-telefono-view',
    component: load('telefonos/clienteTelefonoView/ClienteTelefonoView'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaTelefono.id,
        access: ACCESS.view,
      },
    },
    props: {
      editRoute: 'telefonos__sistemas-cliente-telefono-edit',
    },
  },
  {
    path: 'sistemas/:idsistema/:idcliente_telefono/edit',
    name: 'telefonos__sistemas-cliente-telefono-edit',
    component: load('telefonos/clienteTelefonoEdit/ClienteTelefonoEdit'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaTelefono.id,
        access: ACCESS.edit,
      },
    },
  },
]
