import BaseOfflineResource from '@/offline/base'
import { v4 as uuidv4 } from 'uuid'
import _ from '@/utils/lodash'

export default class OrdenTrabajoSubsisOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'ordenTrabajoSubsis.insertSync',
      params: { values },
    })
    const toReturn = await super.insert(values, options)
    await this.Vue.$offline.checklistOt.annadirPreguntasFaltan(values.idorden_trabajo)
    return toReturn
  }
  async insertOrdenTrabajoSubsis (idordenTrabajo, idsubsis) {
    const ordenTrabajoSubsis = await this.Vue.$offline.ordenTrabajoSubsis.selectPorSubsis(
      idordenTrabajo, idsubsis
    )
    let idordenTrabajoSubsis
    if (ordenTrabajoSubsis.length > 0) {
      idordenTrabajoSubsis = ordenTrabajoSubsis[0].orden_trabajo_subsis.idorden_trabajo_subsis
    } else {
      idordenTrabajoSubsis = uuidv4()
      await this.Vue.$offline.ordenTrabajoSubsis.insertSync({
        idorden_trabajo_subsis: idordenTrabajoSubsis,
        estado: 1,
        idorden_trabajo: idordenTrabajo,
        idsubsis,
      })
    }
    return idordenTrabajoSubsis
  }
  async deleteSync (idordenTrabajoSubsis) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'ordenTrabajoSubsis.deleteSync',
      params: { idorden_trabajo_subsis: idordenTrabajoSubsis },
    })
    await this.Vue.$offline.ordenTrabajoSubsis.delete({
      where: tables.orden_trabajo_subsis.idorden_trabajo_subsis.eq(idordenTrabajoSubsis)
    })
  }
  async selectSubsisTsubsisFaltan (idordenTrabajo) {
    const tables = this.db.tables
    const ot = await this.Vue.$offline.ordenTrabajo.row(idordenTrabajo)
    const sistema = await this.Vue.$offline.sistema.row(ot.orden_trabajo.idsistema)
    const subsisActuales = await this.Vue.$offline.db
      .select()
      .from(tables.orden_trabajo_subsis)
      .innerJoin(
        tables.subsis,
        tables.orden_trabajo_subsis.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        this.Vue.$offline.db.op.and(
          tables.orden_trabajo_subsis.idorden_trabajo.eq(idordenTrabajo),
          tables.orden_trabajo_subsis.estado.gt(0),
          tables.subsis.estado.gt(0),
        )
      )
      .exec()
    const inSubsisActuales = _.map(subsisActuales, 'subsis.idsubsis')
    // TODO: https://github.com/google/lovefield/issues/274
    inSubsisActuales.push(-1)
    const subsisFaltan = await this.Vue.$offline.db
      .select()
      .from(tables.subsis)
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .where(
        this.Vue.$offline.db.op.and(
          tables.subsis.idsistema.eq(ot.orden_trabajo.idsistema),
          tables.subsis.estado.gt(0),
          this.Vue.$offline.db.op.not(
            tables.subsis.idsubsis.in(inSubsisActuales),
          )
        )
      )
      .orderBy(tables.subsis.orden)
      .exec()
    const tsubsis = await this.Vue.$offline.sistema.selectTsubsis(ot.orden_trabajo.idsistema)
    const toReturn = []
    if (subsisFaltan.length > 0) {
      toReturn.push({
        descripcion: 'Subsistema existente',
        value: {
          tipo: 'divider',
          color: 'success',
        },
      })
    }
    return [
      ...toReturn,
      ..._.map(subsisFaltan, item => {
        return {
          descripcion: item.subsis.descripcion,
          value: {
            // idsubsis string -> se puede insertar offline
            id: item.subsis.idsubsis.toString(),
            idtsubsis: item.subsis.idtsubsis,
            descripcionPorDefecto: item.subsis.descripcion,
            codigoPorDefecto: `${sistema.sistema.codigo}.${item.tsubsis.desc_corta}`,
            tipo: 'subsis',
          },
        }
      }),
      {
        descripcion: 'Nuevo subsistema',
        value: {
          tipo: 'divider',
          color: 'warning',
        },
      },
      ..._.map(tsubsis, item => {
        return {
          descripcion: item.tsubsis.descripcion,
          value: {
            id: item.tsubsis.idtsubsis,
            idtsubsis: item.tsubsis.idtsubsis,
            descripcionPorDefecto: item.tsubsis.descripcion,
            codigoPorDefecto: `${sistema.sistema.codigo}.${item.tsubsis.desc_corta}`,
            tipo: 'tsubsis',
          },
        }
      }),
    ]
  }
  async selectSubsisTsubsis (idordenTrabajo) {
    const ot = await this.Vue.$offline.ordenTrabajo.row(idordenTrabajo)
    return await this.Vue.$offline.subsis.selectSubsisTsubsis(ot.orden_trabajo.idsistema)
  }
  selectPorSubsis (idordenTrabajo, idsubsis) {
    const tables = this.Vue.$offline.db.tables
    return this.Vue.$offline.db
      .select()
      .from(tables.orden_trabajo_subsis)
      .innerJoin(
        tables.subsis,
        tables.orden_trabajo_subsis.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        this.Vue.$offline.db.op.and(
          tables.orden_trabajo_subsis.estado.gt(0),
          tables.orden_trabajo_subsis.idorden_trabajo.eq(idordenTrabajo),
          tables.subsis.idsubsis.eq(idsubsis)
        )
      )
      .exec()
  }
}