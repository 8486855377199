import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      fdesde: {
        label: 'Fecha inicio',
        type: 'date',
        value: null,
        field: 'finicio'
      },
      fhasta: {
        label: 'Fecha final',
        type: 'date',
        value: null,
        field: 'ffin'
      },
      idparteTrabajo: {
        label: 'Parte de trabajo',
        type: 'number',
        value: null,
        field: 'idparte_trabajo',
      },
      ttiempoTrabajado:{
        label: 'Tipo de registro',
        type: 'select',
        value: null,
        items: [],
        field: 'idttiempo_trabajado',
        selectItemValue: 'idttiempo_trabajado',
        selectItemText: 'descripcion',
      },
      ttarea:{
        label: 'Tipo de tarea',
        type: 'select',
        value: null,
        items: [],
        field: 'idttarea',
        selectItemValue: 'idttarea',
        selectItemText: 'descripcion',
      },
      esDesplazamiento:{
        label: 'Es desplazamiento',
        type: 'check',
        value: null,
        field: 'es_desplazamiento',
      }
    }
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
