import { load } from '@/router/utils'
import { ORDEN_TRABAJO } from '@/utils/consts'

export default [
  // ordenes
  {
    path: 'pendientes',
    name: 'ordenes__pendientes-orden-trabajo-list',
    component: load('ordenes/ordenTrabajoList/OrdenTrabajoList'),
    props: {
      estado: [ORDEN_TRABAJO.estados.pendiente],
      soloMisOT: false,
    },
  },
  {
    path: 'finalizadas-tecnico',
    name: 'ordenes__finalizadas-tecnico-orden-trabajo-list',
    component: load('ordenes/ordenTrabajoList/OrdenTrabajoList'),
    props: {
      estado: [ORDEN_TRABAJO.estados.finalizada],
      soloMisOT: true,
    },
  },
  {
    path: 'finalizadas',
    name: 'ordenes__finalizadas-orden-trabajo-list',
    component: load('ordenes/ordenTrabajoList/OrdenTrabajoList'),
    props: {
      estado: [ORDEN_TRABAJO.estados.finalizada],
      soloMisOT: false,
    },
  },
  {
    path: ':idorden_trabajo',
    name: 'ordenes__orden-trabajo-view',
    component: load('ordenes/ordenTrabajoView/OrdenTrabajoView'),
  },
  {
    path: ':idorden_trabajo/asignar',
    name: 'ordenes__orden-trabajo-asignar',
    component: load('ordenes/ordenTrabajoAsignar/OrdenTrabajoAsignar'),
  },
  {
    path: ':idorden_trabajo/material-afectado',
    name: 'ordenes__orden_trabajo_material_afectado-subsis-list',
    component: load('ordenes/ordenTrabajoMatsistSubsisList/OrdenTrabajoMatsistSubsisList'),
    props: {
      viewRoute: 'ordenes__orden_trabajo_material_afectado-list',
    },
  },
  {
    path: ':idorden_trabajo/material-afectado/:idsubsis',
    name: 'ordenes__orden_trabajo_material_afectado-list',
    component: load('ordenes/ordenTrabajoMatsistList/OrdenTrabajoMatsistList'),
  },
  {
    path: ':idorden_trabajo/material-instalar',
    name: 'ordenes__orden_trabajo_subsis-list',
    component: load('ordenes/ordenTrabajoSubsisList/OrdenTrabajoSubsisList'),
    props: {
      viewRoute: 'ordenes__lorden-trabajo-list',
    },
  },
  {
    path: ':idorden_trabajo/material-instalar/:idsubsis',
    name: 'ordenes__lorden-trabajo-list',
    component: load('ordenes/lordenTrabajoList/LordenTrabajoList'),
  },
  {
    path: ':idorden_trabajo/partes',
    name: 'ordenes__parte-trabajo-list',
    component: load('partes/parteTrabajoList/ParteTrabajoList'),
    props: {
      viewRoute: 'partes__parte-trabajo-view',
    },
  },
]
