import { APIFilter } from '@/utils/api'
import BaseOnlineResource from '@/online/base'

export default class PlantillaArticuloOnlineResource extends BaseOnlineResource {
  title (row) {
    return row.descripcion
  }
  subtitle (row) {
    return `${row.cant_articulos} artículo/s, ${row.cant_cuotas} cuotas/s y ${row.cant_revisiones} revision/es`
  }
  async selectLineasPlantilla (idplantillaArticulo) {
    let apiCalls = []
    const apiFilter = new APIFilter()
      .addExact('idplantilla_articulo', idplantillaArticulo)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectLplantillaArticulo', method: 'lplantillaArticulo.select', params: { filter: apiFilter } })
    apiCalls.push({ name: 'selectLplantillaCuota', method: 'lplantillaCuota.select', params: { filter: apiFilter } })
    apiCalls.push({ name: 'selectLplantillaMant', method: 'lplantillaMant.select', params: { filter: apiFilter } })
    return await this.Vue.$api.batchCall(apiCalls)
  }
  async batchCallinsertDeFormData(formData, idpresupuestocli) {
    let apiCalls = []
    let idlplantillaArticulo
    let idlplantillaCuota
    let idlplantillaMant
    for (const fieldname in formData) {
      if (fieldname.startsWith('lplantilla_articulo_') && formData[fieldname]) {
        idlplantillaArticulo = parseInt(fieldname.substring('lplantilla_articulo_'.length))
        apiCalls.push({
          name: `insertLplantillaArticulo${idlplantillaArticulo}`,
          method: 'lpresupuestocli.insertLplantillaArticulo',
          params: {
            idpresupuestocli: idpresupuestocli,
            idlplantilla_articulo: idlplantillaArticulo,
          },
        })
      }
      if (fieldname.startsWith('lplantilla_cuota_') && formData[fieldname]) {
        idlplantillaCuota = parseInt(fieldname.substring('lplantilla_cuota_'.length))
        apiCalls.push({
          name: `insertLplantillaCuota${idlplantillaCuota}`,
          method: 'presupuestocliCuota.insertLplantillaCuota',
          params: {
            idpresupuestocli: idpresupuestocli,
            idlplantilla_cuota: idlplantillaCuota,
          },
        })
      }
      if (fieldname.startsWith('lplantilla_mant_') && formData[fieldname]) {
        idlplantillaMant = parseInt(fieldname.substring('lplantilla_mant_'.length))
        apiCalls.push({
          name: `insertLplantillaMant${idlplantillaMant}`,
          method: 'presupuestocliMant.insertLplantillaMant',
          params: {
            idpresupuestocli: idpresupuestocli,
            idlplantilla_mant: idlplantillaMant,
          },
        })
      }
    }
    return (apiCalls)
  }
}
