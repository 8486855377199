import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  // clientes
  {
    path: 'clientes/:idcliente',
    name: 'cuentasbancarias__clientes-cliente-cuenta-list',
    component: load('cuentasBancarias/clienteCuentaList/ClienteCuentaList'),
    props: {
      addRoute: 'cuentasbancarias__clientes-cliente-cuenta-add',
      viewRoute: 'cuentasbancarias__clientes-cliente-cuenta-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteCuenta.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/:idcliente/add',
    name: 'cuentasbancarias__clientes-cliente-cuenta-add',
    component: load('cuentasBancarias/clienteCuentaAdd/ClienteCuentaAdd'),
    meta: {
      permission: {
        idobjeto: permissions.clienteCuenta.id,
        access: ACCESS.insert,
      },
    },
    props: {
      viewRoute: 'cuentasbancarias__clientes-cliente-cuenta-view',
    },
  },
  {
    path: 'clientes/:idcliente/:idcliente_cuenta',
    name: 'cuentasbancarias__clientes-cliente-cuenta-view',
    component: load('cuentasBancarias/clienteCuentaView/ClienteCuentaView'),
    props: {
      editRoute: 'cuentasbancarias__clientes-cliente-cuenta-edit',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteCuenta.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/:idcliente/:idcliente_cuenta/edit',
    name: 'cuentasbancarias__clientes-cliente-cuenta-edit',
    component: load('cuentasBancarias/clienteCuentaEdit/ClienteCuentaEdit'),
    meta: {
      permission: {
        idobjeto: permissions.clienteCuenta.id,
        access: ACCESS.edit,
      },
    },
  },
  // clientes potenciales
  {
    path: 'clientes-potenciales/:idcliente_potencial',
    name: 'cuentasbancarias__clientes-potenciales-cliente-cuenta-list',
    component: load('cuentasBancarias/clienteCuentaList/ClienteCuentaList'),
    props: {
      addRoute: 'cuentasbancarias__clientes-potenciales-cliente-cuenta-add',
      viewRoute: 'cuentasbancarias__clientes-potenciales-cliente-cuenta-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialCuenta.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/add',
    name: 'cuentasbancarias__clientes-potenciales-cliente-cuenta-add',
    component: load('cuentasBancarias/clienteCuentaAdd/ClienteCuentaAdd'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialCuenta.id,
        access: ACCESS.insert,
      },
    },
    props: {
      viewRoute: 'cuentasbancarias__clientes-potenciales-cliente-cuenta-view',
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/:idcliente_cuenta',
    name: 'cuentasbancarias__clientes-potenciales-cliente-cuenta-view',
    component: load('cuentasBancarias/clienteCuentaView/ClienteCuentaView'),
    props: {
      editRoute: 'cuentasbancarias__clientes-potenciales-cliente-cuenta-edit',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialCuenta.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/:idcliente_cuenta/edit',
    name: 'cuentasbancarias__clientes-potenciales-cliente-cuenta-edit',
    component: load('cuentasBancarias/clienteCuentaEdit/ClienteCuentaEdit'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialCuenta.id,
        access: ACCESS.edit,
      },
    },
  },
]
