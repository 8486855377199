import BaseOfflineResource from '@/offline/base'
import filters from '@/utils/filters'
import { likeRegExp } from '@/offline/database'

export default class LordenTrabajoDetalleOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'lordenTrabajoDetalle.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'lordenTrabajoDetalle.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idlordenTrabajoDetalle) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'lordenTrabajoDetalle.deleteSync',
      params: {
        idlorden_trabajo_detalle: idlordenTrabajoDetalle
      }
    })
    await this.Vue.$offline.lordenTrabajoDetalle.delete({
      where: tables.lorden_trabajo_detalle.idlorden_trabajo_detalle.eq(idlordenTrabajoDetalle)
    })
  }
  resumenFichaTecnica (lordenTrabajoDetalleRow) {
    const resumenFicha = []
    resumenFicha.push(`Unidades: ${filters.decimal(lordenTrabajoDetalleRow.lorden_trabajo_detalle.unidades)}`)
    resumenFicha.push(`Código: ${lordenTrabajoDetalleRow.articulo.codigo}`)
    return resumenFicha
  }
  async _addSubqueriesLordenTrabajoDetalle (rows) {
    for (let row of rows) {
      const tables = this.db.tables
      row.articulo_escandallo = (await this.db
        .select()
        .from(tables.articulo_escandallo)
        .where(
          this.db.op.and(
            tables.articulo_escandallo.idarticulo.eq(row.lorden_trabajo.idarticulo),
            tables.articulo_escandallo.idescandallo.eq(row.lorden_trabajo_detalle.idarticulo)
          )
        ).exec())[0]
    }
    return rows
  }
  _selectEscandalloBase () {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.lorden_trabajo_detalle)
      .innerJoin(
        tables.lorden_trabajo,
        tables.lorden_trabajo_detalle.idlorden_trabajo.eq(tables.lorden_trabajo.idlorden_trabajo)
      )
      .innerJoin(
        tables.articulo,
        tables.lorden_trabajo_detalle.idarticulo.eq(tables.articulo.idarticulo)
      )
      .orderBy(tables.lorden_trabajo_detalle.idlorden_trabajo_detalle)
  }
  async selectEscandalloDeIdlordenTrabajo (filter, search, sorter, page, idlordenTrabajo) {
    const tables = this.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        this.db.op.or(
          tables.lorden_trabajo_detalle.descripcion.match(reQ),
          tables.lorden_trabajo_detalle.observacion.match(reQ),
        )
      )
    }
    where.push(
      this.db.op.and(
        tables.lorden_trabajo_detalle.idlorden_trabajo.eq(idlordenTrabajo),
        tables.lorden_trabajo_detalle.estado.gt(0),
        ...whereSearch,
        ...whereFilter
      )
    )
    const rows =
        await this._selectEscandalloBase()
          .where(...where)
          .limit(this.db.ITEMS_PER_PAGE)
          .skip(page * this.db.ITEMS_PER_PAGE)
          .exec()
    return [(await this._addSubqueriesLordenTrabajoDetalle(rows))]
  }
  async selectEscandalloDeIdlordenTrabajoRows (pks) {
    const tables = this.db.tables
    const rows = await this._selectEscandalloBase()
      .where(tables.lorden_trabajo_detalle.idlorden_trabajo_detalle.in(pks))
      .exec()
    return [(await this._addSubqueriesLordenTrabajoDetalle(rows))][0]
  }
  async selectEscandalloDeIdlordenTrabajoDetalle (idlordenTrabajoDetalle) {
    const tables = this.db.tables
    let where = []
    where.push(tables.lorden_trabajo_detalle.idlorden_trabajo_detalle.eq(idlordenTrabajoDetalle))
    const rows =
        await this._selectEscandalloBase()
          .where(...where)
          .exec()
    return (await this._addSubqueriesLordenTrabajoDetalle(rows))[0]
  }
}
