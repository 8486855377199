import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      descripcion: {
        label: 'Descripción',
        type: 'string',
        value: null,
        field: 'descripcion',
      },
      codigo: {
        label: 'Código de artículo',
        type: 'string',
        value: null,
        field: 'codigo',
      },
      nelemento: {
        label: 'Nº de elemento',
        type: 'string',
        value: null,
        field: 'nelemento',
      },
      nserie: {
        label: 'Nº de serie',
        type: 'string',
        value: null,
        field: 'nserie',
      },
      netiqueta: {
        label: 'Nº de etiqueta',
        type: 'string',
        value: null,
        field: 'netiqueta',
      },
      ubicacion: {
        label: 'Ubicación',
        type: 'string',
        value: null,
        field: 'ubicacion',
      },
      num_zona: {
        label: 'Nº de zona',
        type: 'string',
        value: null,
        field: 'num_zona',
      },
      fdesdeInstalacion: {
        label: 'Instalación desde',
        type: 'date',
        value: null,
        field: 'fsistema',
      },
      fhastaInstalacion: {
        label: 'Instalación hasta',
        type: 'date',
        value: null,
        field: 'fsistema',
      },
      fultRetimbrado: {
        label: 'Último retimbrado',
        type: 'date',
        value: null,
        field: 'fult_retimbrado',
      },
      fultRevision: {
        label: 'Última revisión',
        type: 'date',
        value: null,
        field: 'fultrevision',
      },
      ffabricacion: {
        label: 'Fabricación',
        type: 'date',
        value: null,
        field: 'ffabricacion',
      },
      idtimportancia_material: {
        label: 'Importancia',
        type: 'select',
        value: null,
        items: [],
        field: 'idtimportancia_material',
        selectItemValue: 'timportancia_material.idtimportancia_material',
        selectItemText: 'timportancia_material.descripcion',
      },
    },
    verCompletado: false,
  },
  getters: {},
  mutations: {
    RESET_STORE_CUSTOM: (state) => {
      state.verCompletado = false
      state.filter.idtimportancia_material.items = []
    },
  },
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
