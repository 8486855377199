import BaseOfflineResource from '@/offline/base'

export default class TecnicoTtareaOfflineResource extends BaseOfflineResource {
  selectTtareaPrincipal (idtecnico) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.tecnico_ttarea)
      .innerJoin(
        tables.ttarea,
        tables.tecnico_ttarea.idttarea.eq(tables.ttarea.idttarea)
      )
      .where(
        this.db.op.and(
          tables.tecnico_ttarea.idtecnico.eq(idtecnico),
          tables.tecnico_ttarea.por_defecto.eq(true),
          tables.tecnico_ttarea.estado.gt(0),
        )
      )
      .exec()
  }
  selectTtarea (idtecnico, idttarea) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.tecnico_ttarea)
      .innerJoin(
        tables.ttarea,
        tables.tecnico_ttarea.idttarea.eq(tables.ttarea.idttarea)
      )
      .where(
        this.db.op.and(
          tables.tecnico_ttarea.idtecnico.eq(idtecnico),
          tables.tecnico_ttarea.idttarea.eq(idttarea),
          tables.tecnico_ttarea.estado.gt(0),
        )
      )
      .exec()
  }
}
