<template>
  <v-datetime-picker
    ref="picker"
    :label="title"
    :datetime="value"
    :disabled="disabled"
    clear-text="Borrar"
    ok-text="Aceptar"
    :text-field-props="textFieldProps"
    :date-picker-props="datePickerProps"
    :time-picker-props="timePickerProps"
    :error-messages="errorMessages"
    :rules="rules"
    date-format="dd/MM/yyyy"
    time-format="HH:mm"
    @input="inputValue"
  />
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Date,
      default: function () { return new Date() },
    },
    rules: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textFieldProps () {
      return  {
        rules: this.rules,
        errorMessages: this.errorMessages,
      }
    },
    datePickerProps () {
      return  {
        locale: 'es',
        'first-day-of-week': 1,
      }
    },
    timePickerProps () {
      return  {
        format: '24hr',
      }
    },
  },
  watch: {
    value (newValue) {
      if (newValue === null) {
        this.$refs.picker.clearHandler()
      }
    },
  },
  methods: {
    inputValue (value) {
      this.$emit('input', value)
    },
  },
}
</script>
