export function load (component) {
  return () => import(`@/pages/${component}.vue`)
}

export function appendRoutes (routes, prefix, routesToAdd) {
  for (let route of routesToAdd) {
    routes.push(route)
    routes[routes.length - 1].path = `${prefix}${routes[routes.length - 1].path}`
  }
}
