import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  // clientes
  {
    path: 'clientes/:idcliente',
    name: 'direcciones__clientes-cliente-direccion-list',
    component: load('direcciones/clienteDireccionList/ClienteDireccionList'),
    props: {
      tipo: 'cliente',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteDireccion.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/:idcliente/add',
    name: 'direcciones__clientes-cliente-direccion-add',
    component: load('direcciones/clienteDireccionAdd/ClienteDireccionAdd'),
    props: {
      tipo: 'cliente',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteDireccion.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'clientes/:idcliente/:idcliente_direccion',
    name: 'direcciones__clientes-cliente-direccion-view',
    component: load('direcciones/clienteDireccionView/ClienteDireccionView'),
    props: {
      tipo: 'cliente',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteDireccion.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/:idcliente/:idcliente_direccion/edit',
    name: 'direcciones__clientes-cliente-direccion-edit',
    component: load('direcciones/clienteDireccionEdit/ClienteDireccionEdit'),
    props: {
      tipo: 'cliente',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteDireccion.id,
        access: ACCESS.edit,
      },
    },
  },
  // clientes potenciales
  {
    path: 'clientes-potenciales/:idcliente_potencial',
    name: 'direcciones__clientes-potenciales-cliente-direccion-list',
    component: load('direcciones/clienteDireccionList/ClienteDireccionList'),
    props: {
      tipo: 'cliente_potencial',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialDireccion.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/add',
    name: 'direcciones__clientes-potenciales-cliente-direccion-add',
    component: load('direcciones/clienteDireccionAdd/ClienteDireccionAdd'),
    props: {
      tipo: 'cliente_potencial',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialDireccion.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/:idcliente_direccion',
    name: 'direcciones__clientes-potenciales-cliente-direccion-view',
    component: load('direcciones/clienteDireccionView/ClienteDireccionView'),
    props: {
      tipo: 'cliente_potencial',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialDireccion.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/:idcliente_direccion/edit',
    name: 'direcciones__clientes-potenciales-cliente-direccion-edit',
    component: load('direcciones/clienteDireccionEdit/ClienteDireccionEdit'),
    props: {
      tipo: 'cliente_potencial',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialDireccion.id,
        access: ACCESS.edit,
      },
    },
  },
]
