import BaseOnlineResource from '@/online/base'

export default class LplantillaCuotaOnlineResource extends BaseOnlineResource {
  title (row) {
    return `${row.concepto_cuota_desc} - ${row.periodo_cuota_desc}`
  }
  subtitle (row) {
    return row.descripcion
  }
}
