import BaseOfflineResource from '@/offline/base'
import { nonEmpty } from '@/utils/templates'
import filters from '@/utils/filters'

export default class ClienteDireccionOfflineResource extends BaseOfflineResource {
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'clienteDireccion.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  formatearDireccion (direccion) {
    const direccionFormateada = filters.linebreaksBr(nonEmpty`
      ${direccion.tvia_descripcion} ${direccion.calle} ${direccion.num} ${direccion.localidad} ${direccion.cp}`)
    return direccionFormateada
  }
}
