import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      unidadesDesde: {
        label: 'Unidades desde',
        type: 'number',
        value: null,
        field: 'unidades'
      },
      unidadesHasta: {
        label: 'Unidades hasta',
        type: 'number',
        value: null,
        field: 'unidades'
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
