import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      idparteTrabajo: {
        label: 'Nº de parte',
        type: 'number',
        value: null,
        field: 'idparte_trabajo'
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
