import BaseOfflineResource from '@/offline/base'
import { PARAMETRO } from '@/utils/consts'

export default class IdiomaOfflineResource extends BaseOfflineResource {
  async traducir (tabla, campo, idfila, ididioma) {
    let traduccion = ''
    const tables = this.db.tables
    const cadena = await this.db
      .select(tables[tabla][campo])
      .from(tables[tabla])
      .where(tables[tabla]['id' + tabla].eq(idfila))
      .exec()
    traduccion = cadena[0][campo]
    const idiomaDefecto = (
      await this.Vue.$offline.parametro.valorParametro(PARAMETRO.parametros.IDIOMA_DEFECTO)
    )
    if (ididioma !== idiomaDefecto) {
      const tablaTraduccion = await this.db
        .select()
        .from(tables.traduccion)
        .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
        .where(
          this.db.op.and(
            tables.traduccion.tabla.eq(tabla),
            tables.traduccion.campo.eq(campo),
            tables.traduccion.idfila.eq(idfila),
            tables.traduccion.ididioma.eq(ididioma)
          )
        )
        .exec()
      if (tablaTraduccion.length > 0) {
        traduccion = tablaTraduccion[0].traduccion.traduccion_campo
      }
    }
    return traduccion
  }
}
