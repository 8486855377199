import { v4 as uuidv4 } from 'uuid'
import _ from '@/utils/lodash'
import filters from '@/utils/filters'
import BaseOfflineResource from '@/offline/base'
import { currentDate, addMonths } from '@/utils/date'
import { MATERIAL_SISTEMA } from '@/utils/consts'

export default class MaterialSistemaOfflineResource extends BaseOfflineResource {
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'materialSistema.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  rowMaterialSistema (idmaterialSistema) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.material_sistema)
      .leftOuterJoin(
        tables.fabricante,
        tables.material_sistema.idfabricante.eq(tables.fabricante.idfabricante)
      )
      .leftOuterJoin(
        tables.agente_propelente,
        tables.material_sistema.idagente_propelente.eq(tables.agente_propelente.idagente_propelente)
      )
      .leftOuterJoin(
        tables.agente_extintor,
        tables.material_sistema.idagente_extintor.eq(tables.agente_extintor.idagente_extintor)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .leftOuterJoin(
        tables.ficha_tecnica,
        tables.articulo.idficha_tecnica.eq(tables.ficha_tecnica.idficha_tecnica)
      )
      .where(tables.material_sistema.idmaterial_sistema.eq(idmaterialSistema))
      .exec()
  }
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'materialSistema.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  resumenFichaTecnica (materialSistemaRow) {
    if (
      !_.has(materialSistemaRow, 'material_sistema') ||
      !_.has(materialSistemaRow, 'tsubsis')
    ) {
      console.error('Falta propiedad material_sistema/tsubsis')
    }
    const resumenFicha = []
    resumenFicha.push(`Instalación: ${filters.shortDate(materialSistemaRow.material_sistema.fsistema)}`)
    if (materialSistemaRow.material_sistema.unidades > 1) {
      resumenFicha.push(`Unidades: ${materialSistemaRow.material_sistema.unidades}`)
    }
    if (materialSistemaRow.tsubsis.ver_mat_nserie && materialSistemaRow.material_sistema.nserie) {
      resumenFicha.push(`Nº de serie: ${materialSistemaRow.material_sistema.nserie}`)
    }
    if (materialSistemaRow.tsubsis.ver_mat_nelemento && materialSistemaRow.material_sistema.nelemento) {
      resumenFicha.push(`Nº de elemento: ${materialSistemaRow.material_sistema.nelemento}`)
    }
    if (materialSistemaRow.tsubsis.ver_mat_ubicacion && materialSistemaRow.material_sistema.ubicacion) {
      resumenFicha.push(`Ubicación: ${materialSistemaRow.material_sistema.ubicacion}`)
    }
    if (materialSistemaRow.tsubsis.ver_mat_netiqueta && materialSistemaRow.material_sistema.netiqueta) {
      resumenFicha.push(`Nº de etiqueta: ${materialSistemaRow.material_sistema.netiqueta}`)
    }
    if (materialSistemaRow.tsubsis.ver_mat_num_zona && materialSistemaRow.material_sistema.num_zona) {
      resumenFicha.push(`Nº de zona: ${materialSistemaRow.material_sistema.num_zona}`)
    }
    if (materialSistemaRow.tsubsis.ver_mat_particion && materialSistemaRow.material_sistema.particion) {
      resumenFicha.push(`Partición: ${materialSistemaRow.material_sistema.particion}`)
    }
    if (materialSistemaRow.tsubsis.ver_mat_fult_revision && materialSistemaRow.material_sistema.fultrevision) {
      const fultRevision = filters.shortDate(materialSistemaRow.material_sistema.fultrevision)
      resumenFicha.push(`Última revisión: ${fultRevision}`)
    }
    // (no existe aún en offline, es un campo calculado en el servidor)
    // if (materialSistemaRow.tsubsis.ver_mat_fprox_revision && ) {
    //   resumenFicha.push(`Próxima revisión: ${}`, )
    // }
    if (materialSistemaRow.tsubsis.ver_mat_fult_retimbrado && materialSistemaRow.material_sistema.fult_retimbrado) {
      const fultRetimbrado = filters.shortDate(materialSistemaRow.material_sistema.fult_retimbrado)
      resumenFicha.push(`Último retimbrado: ${fultRetimbrado}`)
    }
    // (no existe aún en offline, es un campo calculado en el servidor)
    // if (materialSistemaRow.tsubsis.ver_mat_fprox_retimbrado && ) {
    //   resumenFicha.push(`Próximo retimbrado: ${}`, )
    // }
    if (materialSistemaRow.tsubsis.ver_mat_fcaducidad && materialSistemaRow.material_sistema.fcaducidad) {
      const fcaducidad = filters.shortDate(materialSistemaRow.material_sistema.fcaducidad)
      resumenFicha.push(`Fecha de caducidad: ${fcaducidad}`)
    }
    if (materialSistemaRow.tsubsis.ver_mat_ffin_garantia && materialSistemaRow.material_sistema.ffin_garantia) {
      const ffinGarantia = filters.shortDate(materialSistemaRow.material_sistema.ffin_garantia)
      resumenFicha.push(`Fin de garantía: ${ffinGarantia}`)
    }
    if (materialSistemaRow.material_sistema.fdesinstalacion) {
      const fechaDesinstalacion = filters.shortDate(materialSistemaRow.material_sistema.fdesinstalacion)
      resumenFicha.push(`Fecha de desinstalación: ${fechaDesinstalacion}`)
    }
    return resumenFicha
  }
  selectDatoMaterialSistemaValor (idmaterialSistema, idtdatoArticulo) {
    const tables = this.db.tables
    return this.Vue.$offline.datoMaterialSistema.select({
      where: this.Vue.$offline.db.op.and(
        tables.dato_material_sistema.idmaterial_sistema.eq(idmaterialSistema),
        tables.dato_material_sistema.idtdato_articulo.eq(idtdatoArticulo)
      )
    })
  }
  async selectMaxNorden (idsubsis) {
    const tables = this.Vue.$offline.db.tables
    return this.Vue.$offline.db
      .select(this.Vue.$offline.db.fn.max(tables.material_sistema.n_orden).as('n_orden'))
      .from(tables.material_sistema)
      .where(tables.material_sistema.idsubsis.eq(idsubsis))
      .exec()
  }
  async insertarMaterialSistema(idarticulo, unidades, idsubsis) {
    const tables = this.db.tables
    let articulo = await this.Vue.$offline.articulo.row(idarticulo)
    const idmaterialSistema = uuidv4()
    const maxNorden = await this.selectMaxNorden(idsubsis)
    let norden = maxNorden[0].n_orden + 1
    const tsubfamilia = (await this.Vue.$offline.db
      .select()
      .from(tables.articulo)
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .innerJoin(
        tables.tsubfamilia,
        tables.subfamilia.idtsubfamilia.eq(tables.tsubfamilia.idtsubfamilia)
      )
      .where(tables.articulo.idarticulo.eq(idarticulo))
      .exec()
    )[0]
    const ahora = currentDate()
    await this.insertSync({
      idmaterial_sistema: idmaterialSistema,
      idarticulo,
      fsistema: ahora,
      nserie: null,
      nelemento: null,
      ubicacion: null,
      netiqueta: null,
      num_zona: null,
      evento: null,
      funcion: null,
      particion: null,
      // fult_revision (no debe ser editable)
      // fprox_revision (no existe aún en offline, es un campo calculado en el servidor)
      fult_retimbrado: null,
      // fprox_retimbrado (no existe aún en offline, es un campo calculado en el servidor)
      fcaducidad: null,
      ffin_garantia: articulo.articulo.meses_garantia > 0 ? addMonths(ahora, articulo.articulo.meses_garantia) : null,
      idfabricante: articulo.articulo.idfabricante,
      ffabricacion: null,
      idagente_extintor: articulo.articulo.idagente_extintor,
      idagente_propelente: articulo.articulo.idagente_propelente,
      volumen: articulo.articulo.volumen,
      peso_total: articulo.articulo.peso_total,
      peso_agente_extintor: articulo.articulo.peso_agente_extintor,
      eficacia: articulo.articulo.eficacia,
      presion: articulo.articulo.presion,
      idsubsis,
      codigo: articulo.articulo.codigo,
      descripcion: articulo.articulo.descripcion,
      fdesinstalacion: null,
      unidades,
      n_orden: norden,
      estado: 1,
      idest_material_sistema: MATERIAL_SISTEMA.estados.sistema,
      material_con_revision: articulo.articulo.material_con_revision,
      permite_retirar: tsubfamilia.tsubfamilia.permite_retirar,
      idtimportancia_material: articulo.articulo.idtimportancia_material,
    })
    return idmaterialSistema
  }
}
