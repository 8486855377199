import BaseOnlineResource from '@/online/base'
import { APIFilter } from '@/utils/api'
import _ from '@/utils/lodash'

export default class SistemaTelefonoOnlineResource extends BaseOnlineResource {
  async selectContactosDelSistema (idsistema, idcliente) {
    // los contactos del sistema son: los de SISTEMA_TELEFONO + CLIENTE_TELEFONO con para_todos_los_sistemas = 1
    let apiCalls = []
    const apiFilterSistema = new APIFilter()
    apiFilterSistema.addExact('idsistema', idsistema)
    apiFilterSistema.addGT('estado', 0)
    apiCalls.push({ name: 'selectSistemaTelefono', method: 'sistemaTelefono.select', params: { filter: apiFilterSistema } })
    const apiFilterCliente = new APIFilter()
    apiFilterCliente
      .addExact('idcliente', idcliente)
      .addGT('estado', 0)
      .addExact('para_todos_los_sistemas', 1)
    apiCalls.push({ name: 'selectClienteTelefono', method: 'clienteTelefono.select', params: { filter: apiFilterCliente } })
    const resp = await this.Vue.$api.batchCall(apiCalls)
    let personasContacto = _.uniqBy(
      _.concat(resp.data.selectSistemaTelefono.result.dataset, resp.data.selectClienteTelefono.result.dataset),
      'idcliente_telefono'
    )
    return [ personasContacto, resp.data.selectClienteTelefono.result.metadata ]
  }
  async tieneContactosDeSistema (idsistema) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idsistema', idsistema)
    apiFilter.addGT('estado', 0)
    const resp = await this.Vue.$api.call(
      'sistemaTelefono.select', { filter: apiFilter, wrapper: 'count' }
    )
    return resp.data.result.dataset[0].count > 0
  }
  async selectContactoDeSistema (idclienteTelefono, idsistema) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idsistema', idsistema)
    apiFilter.addExact('idcliente_telefono', idclienteTelefono)
    apiFilter.addGT('estado', 0)
    const resp = await this.Vue.$api.call('sistemaTelefono.select', { filter: apiFilter })
    return resp.data.result.dataset
  }
  async esContactoDeSistema (idclienteTelefono, idsistema) {
    const selectContactoDeSistema = await this.selectContactoDeSistema(idclienteTelefono, idsistema)
    return selectContactoDeSistema.length > 0
  }
  async selectConSAT (idsistema, idcliente) {
    // los contactos del sistema son: los de SISTEMA_TELEFONO + CLIENTE_TELEFONO con para_todos_los_sistemas = 1
    let apiCalls = []
    const apiFilterSistema = new APIFilter()
    apiFilterSistema.addExact('idsistema', idsistema)
      .addGT('estado', 0)
      .addExact('email_notifica_sat', 1)
      .addIsNull('email', true)
    apiCalls.push({ name: 'selectSistemaTelefono', method: 'sistemaTelefono.select', params: { filter: apiFilterSistema } })
    const apiFilterCliente = new APIFilter()
    apiFilterCliente
      .addExact('idcliente', idcliente)
      .addGT('estado', 0)
      .addExact('para_todos_los_sistemas', 1)
      .addExact('email_notifica_sat', 1)
      .addIsNull('email', true)
    apiCalls.push({ name: 'selectClienteTelefono', method: 'clienteTelefono.select', params: { filter: apiFilterCliente } })
    const resp = await this.Vue.$api.batchCall(apiCalls)
    let personasContacto = _.uniqBy(
      _.concat(resp.data.selectSistemaTelefono.result.dataset, resp.data.selectClienteTelefono.result.dataset),
      'idcliente_telefono'
    )
    return [ personasContacto, resp.data.selectClienteTelefono.result.metadata ]
  }
}
