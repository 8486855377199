import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import {  nonEmpty } from '@/utils/templates'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class ClienteOnlineResource extends BaseOnlineResource {
  title (row) {
    if (row.cif) {
      return `${row.nombre} (${row.cif})`
    } else {
      return row.nombre
    }
  }
  subtitle (row) {
    return filters.linebreaksBr(
      nonEmpty`${row.tvia_descripcion} ${row.calle} ${row.num} ${row.localidad} ${row.cp}`
    )
  }
  searchFilter (value) {
    const apiFilter = new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('nombre', value)
      .addILike('razon_social', value)
      .addILike('calle', value)
      .addILike('cp', value)
      .addILike('cif', value)
      .addILike('telefono', value)
    if (!isNaN(value)) {
      apiFilter.addExact('idcliente', value)
    }
    return apiFilter
  }
  searchConPotencialesFilter (value) {
    const apiFilter = new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('nombre', value)
      .addILike('razon_social', value)
      .addILike('calle', value)
      .addILike('cp', value)
      .addILike('cif', value)
      .addILike('telefono', value)
    if (!isNaN(value)) {
      apiFilter.addExact('id', value)
    }
    return apiFilter
  }
}
