import _ from '@/utils/lodash'

export default {
  install: async (Vue) => {
    Vue.$online = Vue.prototype.$online = {}
    // registrar dinámicamente los recursos desde /online/resources/
    const requireRecursoOnline = require.context(
      '@/online/resources/', false, /[\w-]+\.js$/
    )
    const customResources = []
    await requireRecursoOnline.keys().forEach(async (filename) => {
      const sanitizedFilename = filename.replace(/^\.\//, '')
      const baseFilename = sanitizedFilename.replace(/\.\w+$/, '')
      const snackCaseBaseFilename = _.snakeCase(baseFilename)
      const resourceModule = await require(`@/online/resources/${sanitizedFilename}`)
      /* eslint-disable new-cap */
      const resource = await new resourceModule.default(Vue, snackCaseBaseFilename)
      Vue.$online[baseFilename] = resource
      customResources.push(snackCaseBaseFilename)
    })
  }
}
