import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  {
    path: 'operaciones/:idoperacioncrm',
    name: 'citas__operacioncrm-cita-list',
    component: load('citas/citaList/CitaList'),
    props: {
      addRoute: 'citas__operacioncrm-cita-add',
      viewRoute: 'citas__operacioncrm-cita-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.citas.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'operaciones/:idoperacioncrm/:idcita',
    name: 'citas__operacioncrm-cita-view',
    component: load('citas/citaView/CitaView'),
    props: {
      editRoute: 'citas__operacioncrm-cita-edit',
    },
    meta: {
      permission: {
        idobjeto: permissions.citas.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'operaciones/:idoperacioncrm/add',
    name: 'citas__operacioncrm-cita-add',
    component: load('citas/citaAdd/CitaAdd'),
    props: {
      viewRoute: 'citas__operacioncrm-cita-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.citas.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'operaciones/:idoperacioncrm/:idcita/edit',
    name: 'citas__operacioncrm-cita-edit',
    component: load('citas/citaEdit/CitaEdit'),
    props: {
      viewRoute: 'citas__operacioncrm-cita-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.citas.id,
        access: ACCESS.edit,
      },
    },
  },
  {
    path: '',
    name: 'citas__cita-list',
    component: load('citas/citaList/CitaList'),
    props: {
      addRoute: 'citas__cita-add',
      viewRoute: 'citas__cita-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.citas.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idcita',
    name: 'citas__cita-view',
    component: load('citas/citaView/CitaView'),
    props: {
      editRoute: 'citas__cita-edit',
    },
    meta: {
      permission: {
        idobjeto: permissions.citas.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idcita/edit',
    name: 'citas__cita-edit',
    component: load('citas/citaEdit/CitaEdit'),
    props: {
      viewRoute: 'citas__cita-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.citas.id,
        access: ACCESS.edit,
      },
    },
  },
  {
    path: 'add',
    name: 'citas__cita-add',
    component: load('citas/citaAdd/CitaAdd'),
    props: {
      viewRoute: 'citas__cita-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.citas.id,
        access: ACCESS.insert,
      },
    },
  },
]
