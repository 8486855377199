import { make } from 'vuex-pathify'
import _ from '@/utils/lodash'

/*
  Definición:
  * Un array `dirtyMapping` que mapea `route` -> `entity` (entidad principal afectada por esa ruta)
  Marcar "dirty":
  * Cuando un registro de una entidad se modifica/elimina se llama a: `setDirty(entity, id, deleted)`
  * Se busca en el array de mapeo las rutas asociadas a esa entidad
  * Se agregan al array `dirty` todas las rutas afectadas: `route/id/deleted`
  Recuperar "dirty" para refrescar:
  * Se llama a `popDirty(route)` que devuelve un objeto `{ modified: [], deleted: [] }` con
    dos arrays de ID modificados/eliminados
  * `popDirty(route)` vacía los objetos "dirty" para esa ruta
*/

export const ENTITIES = {
  local: {
    accionCorrectoraOt: 'local__accion_correctora_ot',
    articulo: 'local__articulo',
    datoSistema: 'local__dato_sistema',
    clienteTelefono: 'local__cliente_telefono',
    fichero: 'local__fichero',
    lordenTrabajo: 'local__lorden_trabajo',
    lordenTrabajoDetalle: 'local__lorden_trabajo_detalle',
    lparteTrabajo: 'local__lparte_trabajo',
    lparteTrabajoSubsis: 'local__lparte_trabajo__subsis',
    materialSistema: 'local__material_sistema',
    ordenTrabajoSubsis: 'local__orden_trabajo_subsis',
    parteTrabajo: 'local__parte_trabajo',
    parteTrabajoMatsist: 'local__parte_trabajo_matsist',
    parteTrabajoMatsistSubsis: 'local__parte_trabajo_matsist__subsis',
    parteTrabajoTecnico: 'local__parte_trabajo_tecnico',
    preguntaChecklistOt: 'local__pregunta_checklist_ot',
    sistemaCuota: 'local__sistema_cuota',
    sistemaMant: 'local__sistema_mant',
    subsis: 'local__subsis',
    sync: 'local__sync',
    syncUpload: 'local__sync_upload',
    tanomaliaChecklistOt: 'local__tanomalia_checklist_ot',
    tiempoTrabajado: 'local__tiempo_trabajado',
  },
  remote: {
    cita: 'remote__cita',
    cliente: 'remote__cliente',
    clientePotencial: 'remote__cliente_potencial',
    clienteTelefono: 'remote__cliente_telefono',
    clienteDireccion: 'remote__cliente_direccion',
    clienteCuenta: 'remote__cliente_cuenta',
    contactoCliente: 'remote__contacto_cliente',
    contrato: 'remote__contrato',
    contratoCuota: 'remote__contrato_cuota',
    contratoMaterial: 'remote__contrato_material',
    dato: 'remote__dato',
    datoSistema: 'remote__dato_sistema',
    documento: 'remote__documento',
    estudioFinanciero: 'remote__estudio_financiero',
    fichero: 'remote__fichero',
    llavero: 'remote__llavero',
    lparteNovedad: 'remote__lparte_novedad',
    lpresupuestocli: 'remote__lpresupuestocli',
    mronda: 'remote__mronda',
    mrondaPunto: 'remote__mronda_punto',
    materialSistema: 'remote__material_sistema',
    operacionCRM: 'remote__operacioncrm',
    ordenTrabajo: 'remote__orden_trabajo',
    ordenTrabajoMatsist: 'remote__orden_trabajo_matsist',
    parteNovedad: 'remote__parte_novedad',
    parteTrabajo: 'remote__parte_trabajo',
    parteTrabajoVehiculo: 'remote__parte_trabajo__vehiculo',
    parteTrabajoTecnico: 'remote__parte_trabajo_tecnico',
    pedidocli: 'remote__pedidocli',
    presencia: 'remote__presencia',
    presupuestocli: 'remote__presupuestocli',
    presupuestoCuota: 'remote__presupuesto_cuota',
    presupuestocliMant: 'remote__presupuestocli_mant',
    presupuestocliSubsis: 'remote__presupuestocli_subsis',
    ubiLpresupuestocli: 'remote__ubi_lpresupuestocli',
    puestoServicio: 'remote__puesto_servicio',
    puestoServicioServicio: 'remote__puesto_servicio_servicio',
    representante: 'remote__representante',
    ronda: 'remote__ronda',
    servicioContratado: 'remote__servicio_contratado',
    sistema: 'remote__sistema',
    sistemaMant: 'remote__sistema_mant',
    sistemaTviacomunicacion: 'remote__sistema_tviacomunicacion',
    sobre: 'remote__sobre',
    sobreDestinatario: 'remote__sobre_destinatario',
    sobreDocumento: 'remote__sobre_documento',
    sobreEnvio: 'remote__sobre_envio',
    syncUpload: 'remote__sync_upload',
    tturnoPuestoServicio: 'remote__tturno_puesto_servicio',
    vigilante: 'remote__vigilante',
    tiempoTrabajado: 'remote__tiempo_trabajado',
    facturacli: 'remote__facturacli',
  },
}

const state = {
  dirtyMapping: [
    // tablas IndexedDB (locales)
    { route: 'offline__accion-correctora-list', entity: ENTITIES.local.accionCorrectoraOt },
    { route: 'offline__accion-correctora-fichero-list', entity: ENTITIES.local.fichero },
    { route: 'offline__anomalia-fichero-list', entity: ENTITIES.local.fichero },
    { route: 'offline__anomalia-list', entity: ENTITIES.local.tanomaliaChecklistOt },
    { route: 'offline__cliente-telefono-list', entity: ENTITIES.local.clienteTelefono },
    { route: 'offline__sistemas__dato-sistema-list', entity: ENTITIES.local.datoSistema },
    { route: 'offline__fichero-list', entity: ENTITIES.local.fichero },
    { route: 'offline__lorden-trabajo-list', entity: ENTITIES.local.lordenTrabajo },
    { route: 'offline__lorden-trabajo-detalle-list', entity: ENTITIES.local.lordenTrabajoDetalle },
    { route: 'offline__lparte-trabajo-list', entity: ENTITIES.local.lparteTrabajo },
    { route: 'offline__lparte-trabajo-subsis-list', entity: ENTITIES.local.lparteTrabajoSubsis },
    { route: 'offline__lparte-trabajo-material-afectado-list', entity: ENTITIES.local.parteTrabajoMatsist },
    { route: 'offline__lparte-trabajo-material-afectado-subsis-list', entity: ENTITIES.local.parteTrabajoMatsistSubsis },
    { route: 'offline__material-sistema-list', entity: ENTITIES.local.materialSistema },
    { route: 'offline__orden-trabajo-subsis-list', entity: ENTITIES.local.ordenTrabajoSubsis },
    { route: 'offline__parte-descargados-list', entity: ENTITIES.local.parteTrabajo },
    { route: 'offline__parte-fichero-list', entity: ENTITIES.local.fichero },
    { route: 'offline__parte-trabajo-tecnico-list', entity: ENTITIES.local.parteTrabajoTecnico },
    { route: 'offline__grupos-pregunta-list', entity: ENTITIES.local.preguntaChecklistOt },
    { route: 'offline__materiales-pregunta-list', entity: ENTITIES.local.preguntaChecklistOt },
    { route: 'offline__sistema-cuota-list', entity: ENTITIES.local.sistemaCuota },
    { route: 'offline__sistema-fichero-list', entity: ENTITIES.local.fichero },
    { route: 'offline__sistema-mant-list', entity: ENTITIES.local.sistemaMant },
    { route: 'offline__subsis-list', entity: ENTITIES.local.subsis },
    { route: 'offline__tiempo-trabajado-list', entity: ENTITIES.local.tiempoTrabajado },
    { route: 'offline__sync-list', entity: ENTITIES.local.sync },
    { route: 'offline__sync-upload-list', entity: ENTITIES.local.syncUpload },
    { route: 'offline__articulo-list', entity: ENTITIES.local.articulo },
    // tablas API (remotas)
    { route: 'citas__operacioncrm-cita-list', entity: ENTITIES.remote.cita },
    { route: 'citas__cita-list', entity: ENTITIES.remote.cita },
    { route: 'cuentasbancarias__clientes-cliente-cuenta-list', entity: ENTITIES.remote.clienteCuenta },
    { route: 'cuentasbancarias__clientes-potenciales-cliente-cuenta-list', entity: ENTITIES.remote.clienteCuenta },
    { route: 'direcciones__clientes-cliente-direccion-list', entity: ENTITIES.remote.clienteDireccion },
    { route: 'direcciones__clientes-potenciales-cliente-direccion-list', entity: ENTITIES.remote.clienteDireccion },
    { route: 'clientes__cliente-list', entity: ENTITIES.remote.cliente },
    { route: 'clientespotenciales__cliente-potencial-list', entity: ENTITIES.remote.clientePotencial },
    { route: 'contactos__contacto-cliente-list', entity: ENTITIES.remote.contactoCliente },
    { route: 'contratos__cuotas__contratocuota-list', entity: ENTITIES.remote.contratoCuota },
    { route: 'contratos__servicioscontratados__serviciocontratado-list', entity: ENTITIES.remote.servicioContratado },
    { route: 'contratos__materiales__material-list', entity: ENTITIES.remote.contratoMaterial },
    { route: 'contratos__presupuestos-contrato-list', entity: ENTITIES.remote.contrato },
    { route: 'custodiallaves__llavero-list', entity: ENTITIES.remote.llavero },
    { route: 'datos__contratos-dato-list', entity: ENTITIES.remote.dato },
    { route: 'datos__presupuestos-dato-list', entity: ENTITIES.remote.dato },
    { route: 'documentacionenviar__presupuestos-documento-list', entity: ENTITIES.remote.documento },
    { route: 'estudiosfinancieros__clientes-estudio-financiero-list', entity: ENTITIES.remote.estudioFinanciero },
    { route: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-list', entity: ENTITIES.remote.estudioFinanciero },
    { route: 'facturas__facturacli-list', entity: ENTITIES.remote.facturacli },
    { route: 'ficheros__clientes-fichero-list', entity: ENTITIES.remote.fichero },
    { route: 'ficheros__clientes-potenciales-fichero-list', entity: ENTITIES.remote.fichero },
    { route: 'ficheros__contratos-fichero-list', entity: ENTITIES.remote.fichero },
    { route: 'ficheros__partes-novedad-novedades-fichero-list', entity: ENTITIES.remote.fichero },
    { route: 'ficheros__ordenes-fichero-list', entity: ENTITIES.remote.fichero },
    { route: 'ficheros__partes-fichero-list', entity: ENTITIES.remote.fichero },
    { route: 'ficheros__puestos-servicio-fichero-list', entity: ENTITIES.remote.fichero },
    { route: 'ficheros__presupuestos-fichero-list', entity: ENTITIES.remote.fichero },
    { route: 'ficheros__sistemas-fichero-list', entity: ENTITIES.remote.fichero },
    { route: 'sistemas__material-sistema-list', entity: ENTITIES.remote.materialSistema },
    { route: 'operacionescrm__abiertas-operacioncrm-list', entity: ENTITIES.remote.operacionCRM },
    { route: 'operacionescrm__cerradas-operacioncrm-list', entity: ENTITIES.remote.operacionCRM },
    { route: 'operacionescrm__clientes-operacioncrm-list', entity: ENTITIES.remote.operacionCRM },
    { route: 'operacionescrm__clientes-potenciales-operacioncrm-list', entity: ENTITIES.remote.operacionCRM },
    { route: 'operacionescrm__operacioncrm-list', entity: ENTITIES.remote.operacionCRM },
    { route: 'operacionescrm__presupuestos-operacioncrm-list', entity: ENTITIES.remote.operacionCRM },
    { route: 'operacionescrm__sistemas-operacioncrm-list', entity: ENTITIES.remote.operacionCRM },
    { route: 'ordenes__finalizadas-orden-trabajo-list', entity: ENTITIES.remote.ordenTrabajo },
    { route: 'ordenes__finalizadas-tecnico-orden-trabajo-list', entity: ENTITIES.remote.ordenTrabajo },
    { route: 'ordenes__orden_trabajo_material_afectado-list', entity: ENTITIES.remote.ordenTrabajoMatsist },
    { route: 'ordenes__pendientes-orden-trabajo-list', entity: ENTITIES.remote.ordenTrabajo },
    { route: 'partes__parte-asignados-list', entity: ENTITIES.remote.parteTrabajo },
    { route: 'partes__tecnicosasignados__parte-trabajo-tecnico-list', entity: ENTITIES.remote.parteTrabajoTecnico },
    { route: 'partesnovedad__lparte-novedad-list', entity: ENTITIES.remote.lparteNovedad },
    { route: 'partesnovedad__parte-novedad-list', entity: ENTITIES.remote.parteNovedad },
    { route: 'pedidos__pedido-cliente-list', entity: ENTITIES.remote.pedidocli },
    { route: 'presencia__presencia-list', entity: ENTITIES.remote.presencia },
    { route: 'presupuestos__cuotas__presupuestocuota-list', entity: ENTITIES.remote.presupuestoCuota },
    { route: 'presupuestos__revisiones__presupuestocli-mant-list', entity: ENTITIES.remote.presupuestocliMant },
    { route: 'presupuestos__subsistemas__presupuestocli-subsis-list', entity: ENTITIES.remote.presupuestocliSubsis },
    { route: 'presupuestos__ubicaciones__ubi-lpresupuestocli-list', entity: ENTITIES.remote.ubiLpresupuestocli },
    { route: 'presupuestos__lineas__lpresupuestocli-list', entity: ENTITIES.remote.lpresupuestocli },
    { route: 'presupuestos__aceptados-presupuestocli-list', entity: ENTITIES.remote.presupuestocli },
    { route: 'presupuestos__pendientes-presupuestocli-list', entity: ENTITIES.remote.presupuestocli },
    { route: 'presupuestos__rechazados-presupuestocli-list', entity: ENTITIES.remote.presupuestocli },
    { route: 'puestosservicio__puesto-servicio-list', entity: ENTITIES.remote.puestoServicio },
    { route: 'puestosservicio__servicio-list', entity: ENTITIES.remote.puestoServicioServicio },
    { route: 'puestosservicio__turno-servicio-list', entity: ENTITIES.remote.tturnoPuestoServicio },
    { route: 'representantes__clientes-representante-list', entity: ENTITIES.remote.representante },
    { route: 'representantes__clientes-potenciales-representante-list', entity: ENTITIES.remote.representante },
    { route: 'rondas__mronda-list', entity: ENTITIES.remote.mronda },
    { route: 'rondas__mronda-punto-list', entity: ENTITIES.remote.mrondaPunto },
    { route: 'rondas__ronda-list', entity: ENTITIES.remote.ronda },
    { route: 'sincronizacion__sync-upload-list', entity: ENTITIES.remote.syncUpload },
    { route: 'sistemas__sistema-list', entity: ENTITIES.remote.sistema },
    { route: 'sistemas__sistema-mant-list', entity: ENTITIES.remote.sistemaMant },
    { route: 'sistemas__sistema-tviacomunicacion-list', entity: ENTITIES.remote.sistemaTviacomunicacion },
    { route: 'sistemas__dato-sistema-list', entity: ENTITIES.remote.datoSistema },
    { route: 'sobres__presupuestos-sobre-list', entity: ENTITIES.remote.sobre },
    { route: 'sobres__destinatarios__sobre-destinatario-list', entity: ENTITIES.remote.sobreDestinatario },
    { route: 'sobres__documentos__sobre-documento-list', entity: ENTITIES.remote.sobreDocumento },
    { route: 'sobres__envios__sobre-envio-list', entity: ENTITIES.remote.sobreEnvio },
    { route: 'telefonos__clientes-potenciales-cliente-telefono-list', entity: ENTITIES.remote.clienteTelefono },
    { route: 'telefonos__clientes-cliente-telefono-list', entity: ENTITIES.remote.clienteTelefono },
    { route: 'telefonos__sistemas-cliente-telefono-list', entity: ENTITIES.remote.clienteTelefono },
    { route: 'tiempos__tiempo-list', entity: ENTITIES.remote.tiempoTrabajado },
    { route: 'vehiculos__material-vehiculo-list', entity: ENTITIES.remote.parteTrabajoVehiculo },
    { route: 'vigilantes__vigilante-list', entity: ENTITIES.remote.vigilante },
  ],
  dirty: [],
}

const mutations = {
  ...make.mutations(state),
  ADD_DIRTY (state, payload) {
    // para todas las rutas afectadas por la entidad `payload.entity`
    for (const mapping of _.filter(state.dirtyMapping, { entity: payload.entity })) {
      const itemIndex = _.findIndex(state.dirty, {
        route: mapping.route,
        id: payload.id,
        deleted: payload.deleted,
      })
      if (itemIndex === -1) {
        state.dirty.push({
          route: mapping.route,
          id: payload.id,
          deleted: payload.deleted,
        })
      }
    }
  },
  CLEAN_DIRTY (state, payload) {
    _.remove(state.dirty, { route: payload.route })
  },
}

const actions = {
  ...make.actions(state),
  // marcar un ID de una entidad como sucio -> lo marca en todas sus rutas
  setDirty ({ commit }, payload) {
    commit('ADD_DIRTY', payload)
  },
  // devuelve todos los ID sucios de una ruta y limpia esa ruta
  popDirty ({ state, commit }, payload) {
    const modified = _.filter(state.dirty, { route: payload.route, deleted: false })
    const deleted = _.filter(state.dirty, { route: payload.route, deleted: true })
    commit('CLEAN_DIRTY', payload)
    return {
      modified: [..._.map(modified, 'id')],
      deleted: [..._.map(deleted, 'id')],
    }
  },
  clean ({ commit }) {
    commit('SET_DIRTY', [])
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
