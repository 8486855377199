import { load } from '@/router/utils'

export default [
  // maestros de rondas
  {
    path: 'rondas-programadas',
    name: 'rondas__mronda-list',
    component: load('rondas/mrondaList/MrondaList'),
  },
  {
    path: 'rondas-programadas/:idmronda',
    name: 'rondas__mronda-view',
    component: load('rondas/mrondaView/MrondaView'),
  },
  // puntos de ronda
  {
    path: 'rondas-programadas/:idmronda/puntos',
    name: 'rondas__mronda-punto-list',
    component: load('rondas/mrondaPuntoList/MrondaPuntoList'),
  },
  // rondas
  {
    path: 'rondas-realizadas',
    name: 'rondas__ronda-list',
    component: load('rondas/rondaList/RondaList'),
  },
  {
    path: 'rondas-realizadas/:idronda/realizar',
    name: 'rondas__ronda-realizar',
    component: load('rondas/rondaRealizar/RondaRealizar'),
  },
]
