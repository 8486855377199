import { GenericStoreBase, CreateStore } from '@/store/pages/base'
import { AGENDA } from '@/utils/consts'

const pageStore = {
  state: {
    eventos: [],
    fecha: null,
    type: AGENDA.tipo.diario,
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new GenericStoreBase(), pageStore)
