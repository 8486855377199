import lovefield from 'lovefield'
import _ from '@/utils/lodash'
import { getTables } from '@/offline/database'
import BaseOfflineResource from '@/offline/base'

export default {
  install: async (Vue, db) => {
    Vue.$offline = Vue.prototype.$offline = { db }
    // registrar todas las tablas para faciltar la construcción de selects
    const tables = await db.getSchema().tables()
    Vue.$offline.db.tables = []
    for (let t of tables) {
      Vue.$offline.db.tables[t.getName()] = t
    }
    // registrar operadores y funciones de Lovefield
    Vue.$offline.db.order = lovefield.Order
    Vue.$offline.db.op = lovefield.op
    Vue.$offline.db.fn = lovefield.fn
    Vue.$offline.db.ITEMS_PER_PAGE = 50
    Vue.$offline.db.isOfflinePk = (pk) => {
      // es un UUID
      return pk.length === 36
    }
    // registrar dinámicamente los recursos desde /offline/resources/
    const requireRecursoOffline = require.context(
      '@/offline/resources/', false, /[\w-]+\.js$/
    )
    const customResources = []
    await requireRecursoOffline.keys().forEach(async (filename) => {
      const sanitizedFilename = filename.replace(/^\.\//, '')
      const baseFilename = sanitizedFilename.replace(/\.\w+$/, '')
      const snackCaseBaseFilename = _.snakeCase(baseFilename)
      const resourceModule = await require(`@/offline/resources/${sanitizedFilename}`)
      /* eslint-disable new-cap */
      const resource = await new resourceModule.default(
        Vue, db, snackCaseBaseFilename, `id${snackCaseBaseFilename}`
      )
      Vue.$offline[baseFilename] = resource
      customResources.push(snackCaseBaseFilename)
    })
    // registrar el resto de tablas como recursos -> BaseOfflineResource
    for (let table of getTables(db)) {
      const tablename = table.getName()
      if (customResources.indexOf(tablename) === -1) {
        const tablenameCamelCase = _.camelCase(tablename)
        Vue.$offline[tablenameCamelCase] = new BaseOfflineResource(
          Vue, db, tablename, `id${tablename}`
        )
      }
    }
    // ordenación
    Vue.$offline.db.applySorter = (query, metadata) => {
      for (const sort in metadata.sorter) {
        const sorterDescIndex = _.findIndex(metadata.sorter_desc, { name: metadata.sorter[sort].field })
        const field = metadata.sorter_desc[sorterDescIndex].field
        if (metadata.sorter[sort].asc) {
          query = query.orderBy(_.resolveProperty(field, Vue.$offline.db.tables), Vue.$offline.db.order.ASC)
        } else {
          query = query.orderBy(_.resolveProperty(field, Vue.$offline.db.tables), Vue.$offline.db.order.DESC)
        }
      }
      return query
    }
    // transacciones (NO USADO POR EL MOMENTO)
    Vue.$offline.db._currentTx = null
    Vue.$offline.db.startTransaction = async (tables) => {
      Vue.$offline.db._currentTx = Vue.$offline.db.createTransaction()
      await Vue.$offline.db._currentTx.begin(tables)
    }
    Vue.$offline.db.getCurrentTransaction = () => {
      return Vue.$offline.db._currentTx
    }
    Vue.$offline.db.attachToTransaction = async (query) => {
      return await Vue.$offline.db._currentTx.attach(query)
    }
    Vue.$offline.db.commitTransaction = async () => {
      try {
        return await Vue.$offline.db._currentTx.commit()
      } finally {
        Vue.$offline.db._currentTx = null
      }
    }
    Vue.$offline.db.rollbackTransaction = async () => {
      try {
        return await Vue.$offline.db._currentTx.rollback()
      } finally {
        Vue.$offline.db._currentTx = null
      }
    }
  }
}
