import { ListStoreBase, CreateStore } from '@/store/pages/base'
import { ESTUDIO_FINANCIERO } from '@/utils/consts'

const pageStore = {
  state: {
    filter: {
      resultado: {
        label: 'Resultado',
        type: 'select',
        value: null,
        items: [
          {
            resultado: ESTUDIO_FINANCIERO.resultado.favorable,
            descripcion: ESTUDIO_FINANCIERO.descripciones[ESTUDIO_FINANCIERO.resultado.favorable],
          },
          {
            resultado: ESTUDIO_FINANCIERO.resultado.noFavorable,
            descripcion: ESTUDIO_FINANCIERO.descripciones[ESTUDIO_FINANCIERO.resultado.noFavorable],
          },
        ],
        field: 'resultado',
        selectItemValue: 'resultado',
        selectItemText: 'descripcion',
      },
      testudioFinanciero: {
        label: 'Tipo de estudio financiero',
        type: 'select',
        value: null,
        items: [],
        field: 'idtestudio_financiero',
        selectItemValue: 'idtestudio_financiero',
        selectItemText: 'descripcion',
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
