import { load } from '@/router/utils'

export default [
  // partes de novedad
  {
    path: '',
    name: 'partesnovedad__parte-novedad-list',
    component: load('partesNovedad/parteNovedadList/ParteNovedadList'),
  },
  {
    path: 'add',
    name: 'partesnovedad__parte-novedad-add',
    component: load('partesNovedad/parteNovedadAdd/ParteNovedadAdd'),
  },
  {
    path: ':idparte_novedad',
    name: 'partesnovedad__parte-novedad-view',
    component: load('partesNovedad/parteNovedadView/ParteNovedadView'),
  },
  {
    path: ':idparte_novedad/edit',
    name: 'partesnovedad__parte-novedad-edit',
    component: load('partesNovedad/parteNovedadEdit/ParteNovedadEdit'),
  },
  // novedades
  {
    path: ':idparte_novedad/novedades',
    name: 'partesnovedad__lparte-novedad-list',
    component: load('partesNovedad/lparteNovedadList/LparteNovedadList'),
  },
  {
    path: ':idparte_novedad/novedades/add',
    name: 'partesnovedad__lparte-novedad-add',
    component: load('partesNovedad/lparteNovedadAdd/LparteNovedadAdd'),
  },
  {
    path: ':idparte_novedad/novedades/:idlparte_novedad',
    name: 'partesnovedad__lparte-novedad-view',
    component: load('partesNovedad/lparteNovedadView/LparteNovedadView'),
  },
  {
    path: ':idparte_novedad/novedades/:idlparte_novedad/edit',
    name: 'partesnovedad__lparte-novedad-edit',
    component: load('partesNovedad/lparteNovedadEdit/LparteNovedadEdit'),
  },
]
