<template>
  <v-list-item-avatar
    class="body-2 white--text py-1"
    :rounded="true"
    :color="color"
    min-width="110"
    height="auto"
  >
    <slot />
  </v-list-item-avatar>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'info',
    }
  }
}
</script>
