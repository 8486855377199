import BaseOnlineResource from '@/online/base'
import { nonEmpty } from '@/utils/templates'
import filters from '@/utils/filters'

export default class ClienteDireccionOnlineResource extends BaseOnlineResource {
  title (direccion) {
    return filters.linebreaksBr(
      nonEmpty`${direccion.tvia_descripcion} ${direccion.calle} ${direccion.num}`
    )
  }
  subtitle (direccion) {
    return filters.linebreaksBr(
      nonEmpty`${direccion.cp}  ${direccion.localidad} (${direccion.provincia})`)
  }
  async geolocalizar (Vue, idclienteDireccion, latitud, longitud) {
    const resp = await Vue.$api.call(
      'clienteDireccion.update', {
        values: {
          idcliente_direccion: idclienteDireccion,
          latitud,
          longitud,
        },
      })
    return resp.data.result
  }
}
