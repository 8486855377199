import { load } from '@/router/utils'

export default [
  {
    path: '',
    name: 'presencia__presencia-list',
    component: load('presencia/presenciaList/PresenciaList'),
  },
  {
    path: ':idpresencia',
    name: 'presencia__presencia-view',
    component: load('presencia/presenciaView/PresenciaView'),
  },
  {
    path: 'registrar-entrada-salida/:tipo',
    name: 'presencia__registrar-entrada-salida',
    component: load('presencia/registrarEntradaSalida/RegistrarEntradaSalida'),
  },
]
