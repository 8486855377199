import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  {
    path: '',
    name: 'puestosservicio__puesto-servicio-list',
    component: load('puestosServicio/puestoServicioList/PuestoServicioList'),
    meta: {
      permission: {
        idobjeto: permissions.puestoServicio.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpuesto_servicio',
    name: 'puestosservicio__puesto-servicio-view',
    component: load('puestosServicio/puestoServicioView/PuestoServicioView'),
    meta: {
      permission: {
        idobjeto: permissions.puestoServicio.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpuesto_servicio/servicios',
    name: 'puestosservicio__servicio-list',
    component: load('puestosServicio/servicioList/ServicioList'),
    meta: {
      permission: {
        idobjeto: permissions.servicio.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpuesto_servicio/servicios/:idpuesto_servicio_servicio',
    name: 'puestosservicio__servicio-view',
    component: load('puestosServicio/servicioView/ServicioView'),
    meta: {
      permission: {
        idobjeto: permissions.servicio.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpuesto_servicio/servicios/:idpuesto_servicio_servicio/turnos',
    name: 'puestosservicio__turno-servicio-list',
    component: load('puestosServicio/turnoServicioList/TurnoServicioList'),
    meta: {
      permission: {
        idobjeto: permissions.tturnoServicio.id,
        access: ACCESS.view,
      },
    },
  },
]
