import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      nombreVigilante: {
        label: 'Nombre y apellido',
        type: 'string',
        value: null,
        field: 'nombre_vigilante'
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
