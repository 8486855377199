import { v4 as uuidv4 } from 'uuid'
import { GRADO_ANOMALIA } from '@/utils/consts'

export const doUpgrade = async (rawDb) => {
  let version = rawDb.getVersion()
  let indexedDbTx = rawDb.getRawTransaction()
  if (version === 6) {
    // cambio de PK sync.idsync de integer a string (UUID)
    // actualizar todos los cambios pendientes de sincronizar
    let syncTable = indexedDbTx.objectStore('sync')
    let cursorRequest = syncTable.openCursor()
    cursorRequest.onsuccess = (e) => {
      let cursor = e.target.result
      if (cursor) {
        let updateData = cursor.value
        updateData.value.idsync = uuidv4()
        cursor.update(updateData)
        cursor.continue()
      }
    }
    version = 7
  }
  if (version === 7) {
    await rawDb.addTableColumn('sync_upload', 'synced', true)
    version = 8
  }
  if (version === 8) {
    await rawDb.addTableColumn('material_sistema', 'ffin_garantia', null)
    version = 9
  }
  if (version === 9) {
    await rawDb.addTableColumn('fichero', 'visible_movilidad_sat', true)
    await rawDb.addTableColumn('fichero', 'visible_movilidad_comercial', true)
    await rawDb.addTableColumn('fichero', 'visible_movilidad_vigilancia', true)
    await rawDb.addTableColumn('tfichero', 'visible_movilidad_sat', true)
    await rawDb.addTableColumn('tfichero', 'visible_movilidad_comercial', true)
    await rawDb.addTableColumn('tfichero', 'visible_movilidad_vigilancia', true)
    version = 10
  }
  if (version === 10) {
    await rawDb.addTableColumn('parte_trabajo', 'clasificacion', 1)
    version = 11
  }
  if (version === 11) {
    await rawDb.addTableColumn('tanomalia_checklist_ot', 'descripcion_ampliada', '')
    version = 12
  }
  if (version === 12) {
    await rawDb.addTableColumn('pregunta_checklist_ot', 'notas_internas', '')
    version = 13
  }
  if (version === 13) {
    await rawDb.addTableColumn('grado_anomalia', 'clasificacion', null)
    let syncTable = indexedDbTx.objectStore('grado_anomalia')
    let cursorRequest = syncTable.openCursor()
    cursorRequest.onsuccess = (e) => {
      let cursor = e.target.result
      if (cursor) {
        let updateData = cursor.value
        if (updateData.value.correcto) {
          updateData.value.clasificacion = GRADO_ANOMALIA.clasificaciones.correcto
        } else if (updateData.value.no_procede) {
          updateData.value.clasificacion = GRADO_ANOMALIA.clasificaciones.noProcede
        } else {
          updateData.value.clasificacion = GRADO_ANOMALIA.clasificaciones.incorrecto
        }
        cursor.update(updateData)
        cursor.continue()
      }
    }
    version = 14
  }
  if (version === 14) {
    await rawDb.addTableColumn('tsubfamilia', 'meses_caducidad', 0)
    version = 15
  }
  if (version === 15) {
    await rawDb.addTableColumn('sistema_telefono', 'orden_cra', null)
    await rawDb.addTableColumn('sistema_telefono', 'codigo_cra', null)
    await rawDb.addTableColumn('sistema_telefono', 'codigo_coaccion_cra', null)
    version = 16
  }
  if (version === 16) {
    await rawDb.addTableColumn('pregunta_checklist', 'observaciones_internas', null)
    version = 17
  }
  if (version === 17) {
    // tablas nuevas: tstock, stock
    version = 18
  }
  if (version === 18) {
    await rawDb.addTableColumn('forma_pago', 'estado', 1)
    await rawDb.addTableColumn('forma_pago', 'movilidad', true)
    await rawDb.addTableColumn('forma_pago', 'idtforma_pago', true)
    // tablas nuevas: tforma_pago, tcaja, caja, empresa, empleado_caja, anticipocli
    version = 19
  }
  if (version === 19) {
    await rawDb.dropTableColumn('cliente', 'fdescarga')
    await rawDb.dropTableColumn('sistema', 'fdescarga')
    await rawDb.dropTableColumn('orden_trabajo', 'fdescarga')
    await rawDb.dropTableColumn('checklist_ot', 'fdescarga')
    version = 20
  }
  if (version === 20) {
    await rawDb.addTableColumn('articulo', 'visible_movilidad', true)
    await rawDb.addTableColumn('articulo', 'modificar_desc_movilidad', true)
    version = 21
  }
  if (version === 21) {
    // tablas nuevas: tsistema_tsubsis
    version = 22
  }
  if (version === 22) {
    await rawDb.addTableColumn('sync', 'idusuario')
    version = 23
  }
  if (version === 23) {
    await rawDb.addTableColumn('parte_trabajo', 'fprevista_fin')
    version = 24
  }
  if (version === 24) {
    // tablas nuevas: plantilla_articulo, plantilla_grupo_articulo, lplantilla_articulo
    version = 25
  }
  if (version === 25) {
    await rawDb.addTableColumn('articulo', 'desglosar_ubicacion', true)
    version = 26
  }
  if (version === 26) {
    // tabla nueva: timportancia_material
    await rawDb.addTableColumn('articulo', 'idfabricante')
    await rawDb.addTableColumn('articulo', 'idagente_extintor')
    await rawDb.addTableColumn('articulo', 'idagente_propelente')
    await rawDb.addTableColumn('articulo', 'volumen')
    await rawDb.addTableColumn('articulo', 'peso_total')
    await rawDb.addTableColumn('articulo', 'peso_agente_extintor')
    await rawDb.addTableColumn('articulo', 'presion')
    await rawDb.addTableColumn('articulo', 'eficacia')
    await rawDb.addTableColumn('articulo', 'meses_garantia')
    await rawDb.addTableColumn('articulo', 'idtimportancia_material')
    version = 27
  }
  if (version === 27) {
    await rawDb.addTableColumn('lorden_trabajo', 'unidades_pend_instalar', 0)
    version = 28
  }
  if (version === 28) {
    await rawDb.addTableColumn('lorden_trabajo', 'netiqueta')
    await rawDb.addTableColumn('lorden_trabajo', 'particion')
    version = 29
  }
  if (version === 29) {
    await rawDb.addTableColumn('material_sistema', 'idtimportancia_material')
    version = 30
  }
  if (version === 30) {
    await rawDb.addTableColumn('fichero', 'fcreacion', new Date())
    await rawDb.addTableColumn('fichero', 'carpeta')
    version = 31
  }
  if (version === 31) {
    // tabla nueva: marca
    await rawDb.addTableColumn('articulo', 'idmarca')
    version = 32
  }
  if (version === 32) {
    // tabla nueva: cliente_aviso
    await rawDb.dropTableColumn('sistema', 'sistema_avisos')
    await rawDb.dropTableColumn('cliente', 'cliente_avisos')
    version = 33
  }
  if (version === 33) {
    await rawDb.addTableColumn('orden_trabajo', 'pendiente_devolucion', false)
    version = 34
  }
  if (version === 34) {
    // tabla nueva: vehiculo
    await rawDb.addTableColumn('tiempo_trabajado', 'idvehiculo', null)
    version = 35
  }
  if (version === 35) {
    await rawDb.addTableColumn('tanomalia_checklist_ot', 'notas_internas', null)
    version = 36
  }
  if (version === 36) {
    let cursorRequestAgrupacionPreguntaOt = indexedDbTx.objectStore('agrupacion_pregunta_ot').openCursor()
    cursorRequestAgrupacionPreguntaOt.onsuccess = (e) => {
      let cursor = e.target.result
      if (cursor) {
        let updateData = cursor.value
        updateData.value.idagrupacion_pregunta_ot = String(updateData.value.idagrupacion_pregunta_ot)
        cursor.update(updateData)
        cursor.continue()
      }
    }
    let cursorRequestPreguntaChecklistOt = indexedDbTx.objectStore('pregunta_checklist_ot').openCursor()
    cursorRequestPreguntaChecklistOt.onsuccess = (e) => {
      let cursor = e.target.result
      if (cursor) {
        let updateData = cursor.value
        if (updateData.value.idagrupacion_pregunta_ot) {
          updateData.value.idagrupacion_pregunta_ot = String(updateData.value.idagrupacion_pregunta_ot)
        }
        cursor.update(updateData)
        cursor.continue()
      }
    }
    let cursorRequestValorColumnaOt = indexedDbTx.objectStore('valor_columna_ot').openCursor()
    cursorRequestValorColumnaOt.onsuccess = (e) => {
      let cursor = e.target.result
      if (cursor) {
        let updateData = cursor.value
        updateData.value.idvalor_columna_ot = String(updateData.value.idvalor_columna_ot)
        updateData.value.idagrupacion_pregunta_ot = String(updateData.value.idagrupacion_pregunta_ot)
        updateData.value.idpregunta_checklist_ot = String(updateData.value.idpregunta_checklist_ot)
        cursor.update(updateData)
        cursor.continue()
      }
    }
    version = 37
  }
  if (version === 37) {
    // eliminar el campo concepto_cuota.idtservicio_contratado
    await rawDb.dropTableColumn('concepto_cuota', 'idtservicio_contratado')
    version = 38
  }
  if (version === 38) {
    // añadir campo ttarifa en OT
    await rawDb.addTableColumn('orden_trabajo', 'idttarifa', false)
    version = 39
  }
  if (version === 39) {
    // cambio de nombre de columna concepto_cuota.descripcion a concepto_cuota.descripcion_cuota
    await rawDb.dropTableColumn('concepto_cuota', 'descripcion')
    await rawDb.addTableColumn('concepto_cuota', 'descripcion_cuota')
    version = 40
  }
  if (version === 40) {
    await rawDb.addTableColumn('accion_correctora', 'idarticulo')
    await rawDb.addTableColumn('accion_correctora', 'idmaccion')
    await rawDb.addTableColumn('accion_correctora', 'unidades')
    await rawDb.addTableColumn('accion_correctora_ot', 'idarticulo')
    await rawDb.addTableColumn('accion_correctora_ot', 'idmaccion')
    await rawDb.addTableColumn('accion_correctora_ot', 'unidades')
    await rawDb.addTableColumn('accion_correctora_ot', 'descripcion_ampliada')
    version = 41
  }
  if (version === 41) {
    await rawDb.addTableColumn('agregaradefinicioncheck', 'tanomalia_checklist_ot')
    version = 42
  }
  if (version === 42) {
    // dropTable son llamadas síncronas
    rawDb.dropTable('cliente_subfamilia')
    rawDb.dropTable('cliente_familia')
    rawDb.dropTable('cliente_precio')
    await rawDb.addTableColumn('articulo', 'idgrupo_descuento')
    await rawDb.addTableColumn('orden_trabajo', 'idbanco_precio')
    version = 43
  }
  if (version === 43) {
    // nueva tabla lstock
    await rawDb.addTableColumn('articulo', 'sin_stock')
    let cursorRequestArticulo = indexedDbTx.objectStore('articulo').openCursor()
    cursorRequestArticulo.onsuccess = (e) => {
      let cursor = e.target.result
      if (cursor) {
        let updateData = cursor.value
        updateData.value.sin_stock = String(updateData.value.sin_stock)
        cursor.update(updateData)
        cursor.continue()
      }
    }
    version = 44
  }
  if (version === 44) {
    await rawDb.addTableColumn('tactuacion_tsistema', 'incluir_material_al_parte')
    version = 45
  }
  if (version === 45) {
    let clienteTelefono = indexedDbTx.objectStore('cliente_telefono').openCursor()
    clienteTelefono.onsuccess = (e) => {
      let cursor = e.target.result
      if (cursor) {
        let updateData = cursor.value
        updateData.value.para_todos_los_sistemas = String(updateData.value.para_todos_los_sistemas)
        cursor.update(updateData)
        cursor.continue()
      }
    }
    version = 46
  }
  if (version === 46) {
    // tablas nuevas dato_sistema, tdato_sistema y tsistema_tdatosist
    version = 47
  }
  if (version === 47) {
    // tviacomunicacion, ttransmisor, sistema_tviacomunicacion
    version = 48
  }
  return rawDb
}
