import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { CONTRATO } from '@/utils/consts'

export default class ContratoOnlineResource extends BaseOnlineResource {
  title (row) {
    return `${row.contrato_serie_numero} - ${row.objeto}`
  }
  subtitle (row) {
    return `${filters.shortDate(row.fcontrato)}`
  }
  badge (row) {
    if (row.ffirma) {
      return {
        badge: `Firmado ${filters.shortDate(row.ffirma)}`,
        badgeColor: CONTRATO.colores.firmado,
      }
    } else {
      return {
        badge: `Sin firmar`,
        badgeColor: CONTRATO.colores.sinFirmar,
      }
    }
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('objeto', value)
  }
}
