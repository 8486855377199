import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { DEUDA_CLIENTE } from '@/utils/consts'

export default class FacturacliOnlineResource extends BaseOnlineResource {
  title (row) {
    return `${filters.shortDate(row.ffactura)} - ${row.serie_numero}`
  }
  subtitle (row) {
    if (row.estado_deuda === DEUDA_CLIENTE.estados.parcialmenteCobrada) {
      return `Pendiente de cobro: ${filters.currency(row.importe_resta_cobrar)}`
    }
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('serie_numero', value)
  }
}
