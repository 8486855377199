import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      nombreFichero: {
        label: 'Nombre del fichero',
        type: 'string',
        value: null,
        field: 'nombre_fichero',
      },
      observaciones: {
        label: 'Observaciones',
        type: 'string',
        value: null,
        field: 'observaciones',
      },
      tipoFichero: {
        label: 'Tipo de fichero',
        type: 'select',
        value: null,
        items: [],
        field: 'idtfichero',
        selectItemValue: 'idtfichero',
        selectItemText: 'descripcion',
      },
      tipoFicheroClasificacion: {
        label: 'Clasificación',
        type: 'select',
        value: null,
        items: [],
        field: 'idclasificacion_tfichero',
        selectItemValue: 'idclasificacion',
        selectItemText: 'descripcion',
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
