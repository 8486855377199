import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { DOCUMENTO } from '@/utils/consts'

export default class DocumentoOnlineResource extends BaseOnlineResource {
  title (row) {
    return row.tdocumento_descripcion
  }
  subtitle (row) {
    return row.documento_serie_numero
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('tdocumento_descripcion', value)
      .addILike('informe_descripcion', value)
  }
  badge (row) {
    if (row.documento_idfichero) {
      return {
        badge: DOCUMENTO.descripciones.conFicheroGenerado,
        badgeColor: DOCUMENTO.colores.conFicheroGenerado,
      }
    } else {
      return {
        badge: DOCUMENTO.descripciones.sinFicheroGenerado,
        badgeColor: DOCUMENTO.colores.sinFicheroGenerado,
      }
    }
  }
}
