import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import {  nonEmpty } from '@/utils/templates'
import { ORDEN_TRABAJO } from '@/utils/consts'

export default class OrdenTrabajoOnlineResource extends BaseOnlineResource {
  title (row) {
    let nabonado = ''
    if (row.sistema_nabonado1) {
      nabonado = `(Abonado Nº${row.sistema_nabonado1})`
    }
    if (row.idest_orden_trabajo === ORDEN_TRABAJO.estados.pendiente) {
      return filters.linebreaksBr(nonEmpty`
        ${filters.shortDate(row.forden)} - ${row.orden_trabajo_serie_numero} - ${row.cliente_nombre} (${row.idcliente}) ${nabonado}`
      )
    } else {
      return filters.linebreaksBr(nonEmpty`
        ${filters.shortDate(row.ffinalizacion)} - ${row.orden_trabajo_serie_numero} - ${row.cliente_nombre} (${row.idcliente}) ${nabonado}`
      )
    }
  }
  subtitle (row) {
    return filters.linebreaksBr(nonEmpty`
      ${row.tactuacion_descripcion} de ${row.tsistema_descripcion} de sistema ${row.sistema_descripcion} (${row.sistema_codigo})
      ${row.sistema_direccion}
      ${row.observacion}
    `)
  }
  async asignar (idordenTrabajo, idtecnico) {
    return (
      await this.Vue.$api.call(
        'ordenTrabajo.crearParteTrabajoDesdeOT',
        {
          idorden_trabajo: idordenTrabajo,
          idtecnico,
        }
      )
    ).data.result.dataset[0]
  }
  async finalizar (idordenTrabajo, albaranarMaterialPendienteInstalacion) {
    return (
      await this.Vue.$api.call(
        'ordenTrabajo.finalizarOt',
        {
          idorden_trabajo: idordenTrabajo,
          albaranar_material_pendiente_instalacion: albaranarMaterialPendienteInstalacion,
        }
      )
    ).data.result.dataset[0]
  }
}
