import BaseOfflineResource from '@/offline/base'

export default class DatoSistemaOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'datoSistema.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'datoSistema.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idDatoSistema) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'datoSistema.deleteSync',
      params: {
        iddato_sistema: idDatoSistema
      }
    })
    await this.Vue.$offline.datoSistema.delete({
      where: tables.dato_sistema.iddato_sistema.eq(idDatoSistema)
    })
  }
}
