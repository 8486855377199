import BaseOnlineResource from '@/online/base'
import { TDATO_TIPO_DATO } from '@/utils/consts'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import filters from '@/utils/filters'

export default class ArticuloOnlineResource extends BaseOnlineResource {
  title (row) {
    if (row.valor || row.valor_fecha || row.valor_numerico ) {
      let valorUtilizado = null
      if (TDATO_TIPO_DATO.tiposNumber.includes(row.tdato_idtipo_tdato)){
        valorUtilizado = filters.decimal(row.valor_numerico)
      } else if (row.valor_fecha != null) {
        if (row.tdato_idtipo_tdato === TDATO_TIPO_DATO.tipos.fecha) {
          valorUtilizado = filters.shortDate(row.valor_fecha)
        } else if (row.tdato_idtipo_tdato === TDATO_TIPO_DATO.tipos.fechaHora) {
          valorUtilizado = filters.shortDateTime(row.valor_fecha)
        } else if (row.tdato_idtipo_tdato === TDATO_TIPO_DATO.tipos.hora) {
          valorUtilizado = filters.shortTime(row.valor_fecha)
        }
      } else {
        valorUtilizado = row.valor
      }
      return `${row.tdato_descripcion}: "${valorUtilizado}"`
    } else {
      return row.tdato_descripcion
    }
  }
  subtitle (row) {
    if (row.tgrupo_tdato_descripcion) {
      return row.tgrupo_tdato_descripcion
    } else {
      return 'Sin agrupación'
    }
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addIContainsWords('tdato_descripcion', value)
      .addIContainsWords('valor', value)
      .addIContainsWords('valor_fecha', value)
      .addIContainsWords('valor_numerico', value)
  }
}
