import { make } from 'vuex-pathify'

const state = {
  forgottenTips: {
    pages: {
      pedidos: {
        pedidoClienteServir: {
          tipAlertCodBarPedido: []
        }
      }
    }
  }
}

const mutations = {
  ...make.mutations(state),
}

const actions = {
  ...make.actions(state),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
