<template>
  <v-container>
    <v-layout
      row
    >
      <v-flex
        text-xs-center
      >
        <v-btn
          :disabled="disabled"
          :loading="disabled"
          color="primary"
          block
          text
          @click.stop="clickLoadMore()"
        >
          {{ buttonCaption }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    pageSize: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    buttonCaption () {
      if (this.pageSize > 0) {
        return `Cargar ${this.pageSize} más...`
      } else {
        return 'Cargar más...'

      }
    }
  },
  methods: {
    clickLoadMore () {
      this.$emit('load-more')
    }
  }
}
</script>
