import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { SOBRE } from '@/utils/consts'

export default class SobreOnlineResource extends BaseOnlineResource {
  title (row) {
    return `Sobre de documentación Nº${row.idsobre}`
  }
  subtitle (row) {
    return row.tsobre_descripcion
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('tabla_descripcion', value)
      .addILike('tsobre_descripcion', value)
  }
  badge (row) {
    return {
      badge: SOBRE.descripciones[row.estado],
      badgeColor: SOBRE.colores[row.estado],
    }
  }
}
