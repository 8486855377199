import BaseOfflineResource from '@/offline/base'

export default class ClienteTelefonoOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'clienteTelefono.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'clienteTelefono.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
}
