import BaseOfflineResource from '@/offline/base'

export default class TficheroOfflineResource extends BaseOfflineResource {
  rowTficheroClasificacion (clasificacion) {
    const tables = this.db.tables
    return this.db
      .select(this.db.fn.max(tables.tfichero.idtfichero).as('idtfichero'))
      .from(tables.tfichero)
      .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
      .where(tables.tfichero.idclasificacion_tfichero.eq(clasificacion))
      .exec()
  }
  async selectTficheroDisponibles (esTecnico, esComercial, esVigilante) {
    const tables = this.db.tables
    let whereVisibilidad = []
    if (esTecnico) {
      whereVisibilidad.push(tables.tfichero.visible_movilidad_sat.eq(true))
    }
    if (esComercial) {
      whereVisibilidad.push(tables.tfichero.visible_movilidad_comercial.eq(true))
    }
    if (esVigilante) {
      whereVisibilidad.push(tables.tfichero.visible_movilidad_vigilancia.eq(true))
    }
    return await this.db
      .select()
      .from(tables.tfichero)
      .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
      .where(
        this.db.op.and(
          tables.tfichero.estado.gt(0),
          this.db.op.or(
            ...whereVisibilidad
          )
        )
      )
      .exec()
  }
}
