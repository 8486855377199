import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { SOBRE_DESTINATARIO } from '@/utils/consts'

export default class SobreDestinatario extends BaseOnlineResource {
  title (row) {
    return row.email
  }
  subtitle (row) {
    return SOBRE_DESTINATARIO.descripcion[row.clasificacion]
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('email', value)
  }
}
