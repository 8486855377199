import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  // presupuestos
  {
    path: 'presupuestos/:id/:idtabla',
    name: 'ficheros__presupuestos-fichero-list',
    component: load('ficheros/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'ficheros__presupuestos-fichero-add',
      viewRoute: 'ficheros__presupuestos-fichero-view',
    },
  },
  {
    path: 'presupuestos/:id/:idtabla/add',
    name: 'ficheros__presupuestos-fichero-add',
    component: load('ficheros/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'presupuestos/:id/:idtabla/:idfichero',
    name: 'ficheros__presupuestos-fichero-view',
    component: load('ficheros/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliAdjunto.id,
        access: ACCESS.view,
      },
    },
  },
  // contratos
  {
    path: 'contratos/:id/:idtabla',
    name: 'ficheros__contratos-fichero-list',
    component: load('ficheros/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.contratoAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'ficheros__contratos-fichero-add',
      viewRoute: 'ficheros__contratos-fichero-view',
    },
  },
  {
    path: 'contratos/:id/:idtabla/add',
    name: 'ficheros__contratos-fichero-add',
    component: load('ficheros/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.contratoAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'contratos/:id/:idtabla/:idfichero',
    name: 'ficheros__contratos-fichero-view',
    component: load('ficheros/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.contratoAdjunto.id,
        access: ACCESS.view,
      },
    },
  },
  // clientes
  {
    path: 'clientes/:id/:idtabla',
    name: 'ficheros__clientes-fichero-list',
    component: load('ficheros/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.clienteAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'ficheros__clientes-fichero-add',
      viewRoute: 'ficheros__clientes-fichero-view',
    },
  },
  {
    path: 'clientes/:id/:idtabla/add',
    name: 'ficheros__clientes-fichero-add',
    component: load('ficheros/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.clienteAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'clientes/:id/:idtabla/:idfichero',
    name: 'ficheros__clientes-fichero-view',
    component: load('ficheros/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.clienteAdjunto.id,
        access: ACCESS.view,
      },
    },
  },
  // clientes potenciales
  {path: 'clientes-potenciales/:id/:idtabla',
    name: 'ficheros__clientes-potenciales-fichero-list',
    component: load('ficheros/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'ficheros__clientes-potenciales-fichero-add',
      viewRoute: 'ficheros__clientes-potenciales-fichero-view',
    },
  },
  {
    path: 'clientes-potenciales/:id/:idtabla/add',
    name: 'ficheros__clientes-potenciales-fichero-add',
    component: load('ficheros/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'clientes-potenciales/:id/:idtabla/:idfichero',
    name: 'ficheros__clientes-potenciales-fichero-view',
    component: load('ficheros/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialAdjunto.id,
        access: ACCESS.view,
      },
    },
  },
  // sistemas
  {
    path: 'sistemas/:id/:idtabla',
    name: 'ficheros__sistemas-fichero-list',
    component: load('ficheros/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'ficheros__sistemas-fichero-add',
      viewRoute: 'ficheros__sistemas-fichero-view',
    },
  },
  {
    path: 'sistemas/:id/:idtabla/add',
    name: 'ficheros__sistemas-fichero-add',
    component: load('ficheros/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'sistemas/:id/:idtabla/:idfichero',
    name: 'ficheros__sistemas-fichero-view',
    component: load('ficheros/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaAdjunto.id,
        access: ACCESS.view,
      },
    },
  },
  // ordenes de trabajo
  {
    path: 'ordenes/:id/:idtabla',
    name: 'ficheros__ordenes-fichero-list',
    component: load('ficheros/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.ordenTrabajoAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'ficheros__ordenes-fichero-add',
      viewRoute: 'ficheros__ordenes-fichero-view',
    },
  },
  {
    path: 'ordenes/:id/:idtabla/add',
    name: 'ficheros__ordenes-fichero-add',
    component: load('ficheros/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.ordenTrabajoAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'ordenes/:id/:idtabla/:idfichero',
    name: 'ficheros__ordenes-fichero-view',
    component: load('ficheros/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.ordenTrabajoAdjunto.id,
        access: ACCESS.view,
      },
    },
  },
  // partes
  {
    path: 'partes/:id/:idtabla',
    name: 'ficheros__partes-fichero-list',
    component: load('ficheros/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.parteTrabajoAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'ficheros__partes-fichero-add',
      viewRoute: 'ficheros__partes-fichero-view',
    },
  },
  {
    path: 'partes/:id/:idtabla/add',
    name: 'ficheros__partes-fichero-add',
    component: load('ficheros/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.parteTrabajoAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'partes/:id/:idtabla/:idfichero',
    name: 'ficheros__partes-fichero-view',
    component: load('ficheros/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.parteTrabajoAdjunto.id,
        access: ACCESS.view,
      },
    },
  },
  // puestos de servicio
  {
    path: 'puestos-servicio/:id/:idtabla',
    name: 'ficheros__puestos-servicio-fichero-list',
    component: load('ficheros/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.puestoServicioAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'ficheros__puestos-servicio-fichero-add',
      viewRoute: 'ficheros__puestos-servicio-fichero-view',
    },
  },
  {
    path: 'puestos-servicio/:id/:idtabla/add',
    name: 'ficheros__puestos-servicio-fichero-add',
    component: load('ficheros/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.puestoServicioAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'puestos-servicio/:id/:idtabla/:idfichero',
    name: 'ficheros__puestos-servicio-fichero-view',
    component: load('ficheros/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.puestoServicioAdjunto.id,
        access: ACCESS.view,
      },
    },
  },
  // novedadades de partes de novedad
  {
    path: 'partes-novedad/:idparte_novedad/novedades/:id/:idtabla',
    name: 'ficheros__partes-novedad-novedades-fichero-list',
    component: load('ficheros/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.lparteNovedadAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'ficheros__partes-novedad-novedades-fichero-add',
      viewRoute: 'ficheros__partes-novedad-novedades-fichero-view',
    },
  },
  {
    path: 'partes-novedad/:idparte_novedad/novedades/:id/:idtabla/add',
    name: 'ficheros__partes-novedad-novedades-fichero-add',
    component: load('ficheros/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.lparteNovedadAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'partes-novedad/:idparte_novedad/novedades/:id/:idtabla/:idfichero',
    name: 'ficheros__partes-novedad-novedades-fichero-view',
    component: load('ficheros/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.lparteNovedadAdjunto.id,
        access: ACCESS.view,
      },
    },
  },
  // operaciones crm
  {
    path: 'operaciones/:id/:idtabla',
    name: 'ficheros__operacionescrm-fichero-list',
    component: load('ficheros/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.operacionesAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'ficheros__operacionescrm-fichero-add',
      viewRoute: 'ficheros__operacionescrm-fichero-view',
    },
  },
  {
    path: 'operaciones/:id/:idtabla/add',
    name: 'ficheros__operacionescrm-fichero-add',
    component: load('ficheros/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.operacionesAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'operaciones/:id/:idtabla/:idfichero',
    name: 'ficheros__operacionescrm-fichero-view',
    component: load('ficheros/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.operacionesAdjunto.id,
        access: ACCESS.view,
      },
    },
  },
]
