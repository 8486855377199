<template>
  <v-dialog
    v-model="internalShowing"
    scrollable
  >
    <v-card>
      <v-card-title>Enviar correo electrónico</v-card-title>
      <v-card-text
        class="pt-2"
      >
        <ValidationObserver
          ref="observer"
        >
          <v-form>
            <ValidationProvider
              v-slot="{ errors }"
              name="destinatarios"
              :rules="formRules.destinatarios"
            >
              <v-combobox
                v-model="form.destinatarios"
                multiple
                label="Destinatarios"
                append-icon
                chips
                deletable-chips
                clearable
                required
                :error-messages="errors"
              />
            </ValidationProvider>
            <v-text-field
              v-model="form.asunto"
              label="Asunto"
              :value="asunto"
              clearable
            />
            <b10-autocomplete
              v-model="form.plantilla"
              :items="plantillas"
              item-value="body"
              item-text="body"
              label="Plantilla de correo"
              clearable
              @change="changePlantilla"
            />
            <b10-textarea
              v-model="form.body"
              label="Cuerpo del correo"
            />
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click.stop="clickClose"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click="clickSend"
        >
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import _ from '@/utils/lodash'
import { regexEmail } from '@/utils/regex'
import  { extend } from 'vee-validate'

export default {
  mixins: [internalShowingMixin],
  props: {
    asunto: {
      type: String,
      default: '',
    },
    plantillas: {
      type: Array,
      default: () => [],
    },
    destinatarios: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        destinatarios: [],
        asunto: null,
        plantilla: null,
        body: null,
      },
      formRules: {
        destinatarios: { required: true, emailsValidos: true },
      },
    }
  },
  watch: {
    internalShowing (newinternalShowing) {
      if (newinternalShowing) {
        this.$set(this.form, 'asunto', this.asunto)
        this.$set(this.form, 'destinatarios', _.filter(_.uniqBy(this.destinatarios), destinatario => !!destinatario))
        const plantillaEmailDefecto = _.find(this.plantillas, { defecto: true})
        if (plantillaEmailDefecto) {
          this.$set(this.form, 'body', plantillaEmailDefecto.body)
          this.$set(this.form, 'plantilla', plantillaEmailDefecto.body)
        }
      }
    },
  },
  created () {
    extend('emailsValidos', {
      validate: (value) => {
        for (const email of value) {
          if (!regexEmail.test(email)) {
            return `El correo electrónico "${email}" no es válido`
          }
        }
        return true
      }
    })
  },
  methods: {
    clickClose () {
      this.internalShowing = false
    },
    async clickSend () {
      const valid = await this.$refs.observer.validate()
      if (valid) {
        this.$emit('click-send', {
          destinatarios: this.form.destinatarios,
          asunto: this.form.asunto,
          body: this.form.body,
        })
        this.$alert.showSnackbarSuccess('Se ha programado el envío del correo electrónico')
      }
    },
    async changePlantilla (text) {
      let res = null
      if (text) {
        if (this.form.body) {
          res = await this.$alert.showConfirm(
            'Se va a sustituir lo que tengas en el cuerpo del correo por lo que indique la plantilla seleccionada. ¿Deseas continuar?'
          )
        }
        if (res || !this.form.body) {
          this.$set(this.form, 'body', text)
        }
      }
    },
  }
}
</script>
