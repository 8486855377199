import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  {
    path: 'agenda',
    name: 'cuadrantes__cuadrante-agenda',
    component: load('cuadrantes/cuadranteAgenda/CuadranteAgenda'),
    meta: {
      permission: {
        idobjeto: permissions.cuadrante.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpuesto_servicio/:idpuesto_servicio_servicio/:year/:month/edit',
    name: 'cuadrantes__cuadrante-edit',
    component: load('cuadrantes/cuadranteEdit/CuadranteEdit'),
    meta: {
      permission: {
        idobjeto: permissions.cuadrante.id,
        access: ACCESS.edit,
      },
    },
  },
]
