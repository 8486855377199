import BaseOfflineResource from '@/offline/base'

export default class OrdenTrabajoOfflineResource extends BaseOfflineResource {
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'ordenTrabajo.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async totalCobrado (idordenTrabajo) {
    const tables = this.Vue.$offline.db.tables
    let total = (
      await this.Vue.$offline.db
        .select(
          this.Vue.$offline.db.fn.sum(tables.anticipocli.importe).as('total'),
        )
        .from(tables.anticipocli)
        .where(
          this.Vue.$offline.db.op.and(
            tables.anticipocli.estado.gt(0),
            tables.anticipocli.idorden_trabajo.eq(idordenTrabajo),
          )
        )
        .exec()
    )[0].total
    if (!total) {
      // null si no hay nada cobrado
      total = 0
    }
    return total
  }
}
