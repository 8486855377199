import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  {
    path: ':idcontrato/cuotas',
    name: 'contratos__cuotas__contratocuota-list',
    component: load('contratos/cuotas/contratoCuotaList/ContratoCuotaList'),
    meta: {
      permission: {
        idobjeto: permissions.contratoCuota.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idcontrato/cuotas/:idcontrato_cuota',
    name: 'contratos__cuotas__contratocuota-view',
    component: load('contratos/cuotas/contratoCuotaView/ContratoCuotaView'),
    meta: {
      permission: {
        idobjeto: permissions.contratoCuota.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idcontrato/servicios-contratados',
    name: 'contratos__servicioscontratados__serviciocontratado-list',
    component: load('contratos/serviciosContratados/servicioContratadoList/ServicioContratadoList'),
    meta: {
      permission: {
        idobjeto: permissions.servicioContratado.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idcontrato/servicios-contratados/edit',
    name: 'contratos__servicioscontratados__serviciocontratado-edit',
    component: load('contratos/serviciosContratados/servicioContratadoEdit/ServicioContratadoEdit'),
    meta: {
      permission: {
        idobjeto: permissions.servicioContratado.id,
        access: ACCESS.edit,
      },
    },
  },
  {
    path: ':idcontrato/materiales',
    name: 'contratos__materiales__material-list',
    component: load('contratos/materiales/contratoMaterialList/ContratoMaterialList'),
    meta: {
      permission: {
        idobjeto: permissions.contratoMaterial.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idcontrato',
    name: 'contratos__contrato-view',
    component: load('contratos/contratoView/ContratoView'),
    meta: {
      permission: {
        idobjeto: permissions.contrato.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idcontrato/edit',
    name: 'contratos__contrato-edit',
    component: load('contratos/contratoEdit/ContratoEdit'),
    meta: {
      permission: {
        idobjeto: permissions.contrato.id,
        access: ACCESS.edit,
      },
    },
  },
  // presupuestos
  {
    path: 'presupuestos/:idpresupuestocli',
    name: 'contratos__presupuestos-contrato-list',
    component: load('contratos/contratoList/ContratoList'),
    meta: {
      permission: {
        idobjeto: permissions.contrato.id,
        access: ACCESS.view,
      },
    },
  },
]
