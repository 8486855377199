import BaseOfflineResource from '@/offline/base'

export default class SistemaMantOfflineResource extends BaseOfflineResource {
  selectProximasRevisiones (idsistema) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.sistema_mant)
      .innerJoin(
        tables.tactuacion,
        tables.sistema_mant.idtactuacion.eq(tables.tactuacion.idtactuacion)
      )
      .innerJoin(
        tables.periodo_revision,
        tables.sistema_mant.idperiodo_revision.eq(tables.periodo_revision.idperiodo_revision)
      )
      .where(
        this.db.op.and(
          tables.sistema_mant.idsistema.eq(idsistema),
          tables.sistema_mant.activar.eq(true),
          tables.sistema_mant.estado.gt(0),
          tables.sistema_mant.fplanificacion.isNotNull(),
          tables.sistema_mant.fproxrevision.isNotNull(),
        )
      )
      .orderBy(tables.sistema_mant.fplanificacion)
      .orderBy(tables.tactuacion.descripcion)
      .orderBy(tables.periodo_revision.descripcion)
      .exec()
  }
}
