import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { TABLA } from '@/utils/consts'
import _ from '@/utils/lodash'


export default class LoperacioncrmOnlineResource extends BaseOnlineResource {
  title (row) {
    return row.loperacion_desc
  }
  detalles (row) {
    let toReturn = {
      subtitle: '',
      icon: ''
    }
    for (const key in row){
      if (key.startsWith('id') && (!key.endsWith('operacioncrm'))) {
        if (row[key]) {
          let nombreTabla = key.replace('id', '')
          // excepción tabla proyecto_contable
          if (key === 'idproyecto') {
            nombreTabla = 'proyecto_contable'
          }
          if (TABLA.hasOwnProperty(nombreTabla)) {
            toReturn.subtitle = _.upperFirst(TABLA[nombreTabla].titleSingular)
            toReturn.icon = TABLA[nombreTabla].icono
          } else {
            toReturn.subtitle = 'Otros'
            toReturn.icon = ''
          }
        }
      }
    }
    return toReturn
  }
  routeLoperacioncrm (item) {
    let detailRoute = {
      name: null,
      params: {}

    }
    if (item.idcliente) {
      detailRoute.name = 'clientes__cliente-view'
      detailRoute.params = {
        idcliente: item.idcliente
      }
    } else if (item.idcliente_potencial) {
      detailRoute.name = 'clientespotenciales__cliente-potencial-view'
      detailRoute.params = {
        idcliente_potencial: item.idcliente_potencial
      }
    } else if (item.idsistema) {
      detailRoute.name = 'sistemas__sistema-view'
      detailRoute.params = {
        idsistema: item.idsistema,
      }
    } else if (item.idpresupuestocli) {
      detailRoute.name = 'presupuestos__presupuestocli-view'
      detailRoute.params = {
        idpresupuestocli: item.idpresupuestocli,
      }
    } else if (item.idorden_trabajo) {
      detailRoute.name = 'ordenes__orden-trabajo-view'
      detailRoute.params = {
        idorden_trabajo: item.idorden_trabajo,
      }
    } else if (item.idsuboperacioncrm) {
      detailRoute.name = 'operacionescrm__operacioncrm-view'
      detailRoute.params = {
        idoperacioncrm: item.idsuboperacioncrm,
      }
    // De momento no lo pongo porque en la ruta necesito el idparte_novedad y el idlparte_novedad.
    // debería hacer una llamada a la api para obtenerlo
    // } else if (item.idlparte_novedad) {
    //   detailRoute.name = 'partesnovedad__lparte-novedad-view'
    //   detailRoute.params = {
    //     idlparte_novedad: item.idlparte_novedad,
    //   }
    }
    return detailRoute
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('loperacion_desc', value)
      .addILike('idoperacioncrm', value)
      .addILike('idcliente', value)
      .addILike('idcliente_potencial', value)
      .addILike('idsistema', value)
      .addILike('idpresupuestocli', value)
      .addILike('idorden_trabajo', value)
      .addILike('idfacturacli', value)
      .addILike('observaciones', value)
  }
}
