<template>
  <v-dialog
    v-model="internalShowing"
    max-width="400px"
  >
    <v-card>
      <v-card-title
        class="title"
      >
        Colores y leyendas
      </v-card-title>
      <v-card-text>
        <slot name="legend" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click.stop="clickClose"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin],
  methods: {
    clickClose () {
      this.internalShowing = false
    }
  }
}
</script>
