import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class PresupuestocliSubsistOnlineResource extends BaseOnlineResource {
  title (row) {
    return row.descripcion
  }
  subtitle (row) {
    if (row.idsubsis) {
      return 'Subsistema existente'
    } else {
      return 'Nuevo subsistema'
    }
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('descripcion', value)
  }
}
