<template>
  <v-bottom-sheet
    v-model="internalShowing"
  >
    <v-list>
      <v-subheader>{{ title }}</v-subheader>
      <v-list-item
        v-for="option in options"
        v-show="option.visible"
        :key="option.title"
        @click.stop="clickOption(option)"
      >
        <v-list-item-action>
          <v-icon>{{ $vuetify.icons.values[option.icon] }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ option.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-bottom-sheet>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import _ from '@/utils/lodash'

export default {
  mixins: [internalShowingMixin],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      required: true,
      validator: (value) => {
        for (let option in value) {
          if ((!_.has(value[option], 'title')) ||
              (!_.has(value[option], 'visible')) ||
              (!_.has(value[option], 'icon')) ||
              (typeof value[option].title !== 'string') ||
              (typeof value[option].visible !== 'boolean') ||
              (typeof value[option].icon !== 'string')) {
            return false
          }
        }
        return true
      }
    }
  },
  computed: {
    title () {
      if (this.item) {
        return this.item.title
      } else {
        return ''
      }
    }
  },
  methods: {
    clickOption (option) {
      this.internalShowing = false
      this.$emit('click-option', option)
    }
  }
}
</script>
