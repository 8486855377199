import { v4 as uuidv4 } from 'uuid'
import BaseOfflineResource from '@/offline/base'
import { currentDateTime } from '@/utils/date'
import store from '@/store'

export default class SyncOfflineResource extends BaseOfflineResource {
  async insert (data = {}, options = {}) {
    data.idsync = uuidv4()
    data.timestamp = currentDateTime()
    data.pending = true
    data.idusuario = store.get('usuario/idusuario')
    return await super.insert(data, options)
  }
  async syncCountPendiente () {
    const tables = this.db.tables
    const idusuario = store.get('usuario/idusuario')
    return (await this.db
      .select(this.db.fn.count(tables.sync.idsync).as('cant_pendientes'))
      .from(tables.sync)
      .where(
        this.Vue.$offline.db.op.and(
          tables.sync.pending.eq(true),
          this.Vue.$offline.db.op.or(
            tables.sync.idusuario.eq(idusuario),
            // idusuario -> no debería ser nullable pero el upgrade de la BD se podría ejecutar
            // sin un usuario logado
            tables.sync.idusuario.isNull(),
          )
        )
      )
      .orderBy(tables.sync.idsync)
      .exec()
    )[0].cant_pendientes
  }
}
