import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class LpresupuestocliOnlineResource extends BaseOnlineResource {
  title (row) {
    return `${row.descripcion} (${row.codigo})`
  }
  subtitle (row) {
    return row.descripcion
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('codigo', value)
      .addILike('descripcion', value)
      .addILike('inf_comercial', value)
  }
  importe (precio, dto, unidades) {
    const total = precio * (1 - (dto / 100)) * unidades
    if (total) {
      return total.toFixed(2)
    } else {
      return 0
    }
  }
}
