import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      nombre: {
        label: 'Nombre',
        type: 'string',
        value: null,
        field: 'nombre',
      },
      telefono: {
        label: 'Teléfono',
        type: 'string',
        value: null,
        field: 'telefono',
      },
      email: {
        label: 'Correo electrónico',
        type: 'string',
        value: null,
        field: 'email',
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
