import _ from '@/utils/lodash'
import { getTable } from '@/offline/database'

export default class BaseOfflineResource {
  constructor (Vue, db, tablename, pkName) {
    this.db = db
    this.Vue = Vue
    // tabla
    this.table = getTable(db, tablename)
    this.pk = getTable(db, tablename)[pkName]
    this.dummyTable = getTable(db, tablename).as('dummy')
    this.dummyPk = this.dummyTable[pkName]
    this.tablename = tablename
    this.pkName = pkName
    this.columns = this.table.getColumns()
    this.columnNames = _.map(this.table.getColumns(), (column => column.getName()))
  }
  async row (id) {
    // Force results as nested objects: .innerJoin(this.dummyTable, this.pk.eq(id))
    // https://github.com/google/lovefield/blob/master/docs/spec/04_query.md#418-retrieval-of-query-results
    const aRow = await this.db
      .select()
      .from(this.table)
      .innerJoin(this.dummyTable, this.dummyPk.eq(id))
      .where(this.pk.eq(id))
      .exec()
    if (aRow.length > 0) {
      return aRow[0]
    } else {
      return null
    }
  }
  exists (id) {
    return this.db
      .select(this.db.fn.count(this.pk).as('count'))
      .from(this.table)
      .where(this.pk.eq(id))
      .exec()
      .then((rows) => {
        return rows && (rows.length > 0) && (rows[0].count === 1)
      })
  }
  select (options = {}) {
    /*
      options = {
        select: -> lista de campos
        innerJoins: -> lista de inner joins { table, predicate }
        outerJoins: -> lista de left outer joins { table, predicate }
        where: -> condicion where
        orderBy: -> lista de campos
      }
     */
    let toReturn = null
    // select
    if (options && options.select) {
      toReturn = this.db.select(...options.select)
    } else {
      toReturn = this.db.select()
    }
    // from
    toReturn.from(this.table)
    // Force results as nested objects: .innerJoin(this.dummyTable, this.pk.eq(id))
    // https://github.com/google/lovefield/blob/master/docs/spec/04_query.md#418-retrieval-of-query-results
    toReturn.innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
    // inner joins
    if (options && options.innerJoins) {
      for (let join of options.innerJoins) {
        toReturn.innerJoin(join.table, join.predicate)
      }
    }
    // outer joins
    if (options && options.outerJoins) {
      for (let join of options.outerJoins) {
        toReturn.innerJoin(join.table, join.predicate)
      }
    }
    // where
    if (options && options.where) {
      toReturn.where(options.where)
    }
    // order by
    if (options && options.orderBy) {
      for (let order of options.orderBy) {
        toReturn.orderBy(order)
      }
    }
    return toReturn.exec()
  }
  count () {
    return this.db
      .select(this.db.fn.count(this.pk).as('count'))
      .from(this.table)
      .exec()
  }
  delete (options = {}) {
    let toReturn = this.db.delete().from(this.table)
    if (options && options.where) {
      toReturn.where(options.where)
    }
    return toReturn.exec()
  }
  insert (data = {}, options = {}) {
    const defaults = {
      replace: false
    }
    let finalOptions = Object.assign({}, defaults, options)
    let dataArray
    if (Array.isArray(data)) {
      dataArray = data
    } else {
      dataArray = [data]
    }
    const rows = dataArray.map((obj) => { return this.table.createRow(obj) })
    let sentence
    if (finalOptions.replace) {
      sentence = this.db.insertOrReplace()
    } else {
      sentence = this.db.insert()
    }
    return sentence
      .into(this.table)
      .values(rows)
      .exec()
  }
  update (data = {}) {
    let sentence = this.db
      .update(this.table)
      .where(this.pk.eq(data[this.pk.getName()]))
    Object.keys(data).forEach((field) => {
      // no actualizar PK
      // convierte number -> string al venir el PK en el query string y pasar como una propiedad del componente
      if (field !== this.pk.getName()) {
        sentence.set(this.db.tables[this.table.getName()][field], data[field])
      }
    })
    return sentence.exec()
  }
  addSetsToUpdateQuery (query, values) {
    // agrega los .set() segun el objeto values
    // permite hacer updates sin indicar todos los valores de la tabla
    for (const prop in values) {
      // no actualizar PK
      // convierte number -> string al venir el PK en el query string y pasar como una propiedad del componente
      if (this.columnNames.indexOf(prop) >= 0 && prop !== this.pk.getName()) {
        query = query.set(this.table[prop], values[prop])
      }
    }
    return query
  }
}
