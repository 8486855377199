import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import { nonEmpty } from '@/utils/templates'
import { TABLA } from '@/utils/consts'

export default class PresupuestocliOnlineResource extends BaseOnlineResource {
  title (row) {
    if (row.idcliente_potencial) {
      return filters.linebreaksBr(nonEmpty`
        ${filters.shortDate(row.fpresupuesto)} - ${row.presupuestocli_serie_numero} - ${row.nombre} (${row.idcliente_potencial})`
      )
    } else {
      return filters.linebreaksBr(nonEmpty`
        ${filters.shortDate(row.fpresupuesto)} - ${row.presupuestocli_serie_numero} - ${row.nombre} (${row.idcliente})`
      )
    }
  }
  subtitle (row) {
    return filters.linebreaksBr(nonEmpty`
      ${row.tactuacion_descripcion} de ${row.tsistema_descripcion}
      ${row.direccion}
    `)
  }
  searchFilter (value) {
    const apiFilterSearch = new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('nombre', value)
      .addILike('serie_numero', value)
      .addILike('tactuacion_descripcion', value)
      .addILike('tsistema_descripcion', value)
      .addILike('direccion', value)
    if (!isNaN(value)) {
      apiFilterSearch
        .addExact('npresupuestocli', value)
    }
    return apiFilterSearch
  }
  async validacionesAceptar (idpresupuestocli) {
    let validaciones = {
      direccion: {
        mensaje: 'Debes indicar la dirección del presupuesto',
        superada: true,
      },
      formaPago: {
        mensaje: 'Debes indicar la forma de pago del presupuesto',
        superada: true,
      },
      formaPagoCuotas: {
        mensaje: 'Debes indicar la forma de pago para cuotas del presupuesto',
        superada: true,
      },
      cuentaBancaria: {
        mensaje: 'Debes indicar al menos una cuenta bancaria',
        superada: true,
      },
      ubicaciones: {
        mensaje: 'El presupuesto contiene artículos para los que debes indicar ubicación',
        superada: true,
      },
      datosRequeridos: {
        mensaje: 'Debes indicar todos los datos personalizados requeridos del presupuesto',
        superada: true,
      },
    }
    const apiCallsPresupuesto = []
    const apiFilterPresupuesto = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
    apiCallsPresupuesto.push({
      name: 'selectPresupuestocli',
      method: 'presupuestocli.select',
      params: { filter: apiFilterPresupuesto },
    })
    const apiFilterDetallesPresupuesto = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
      .addGT('estado', 0)
    apiCallsPresupuesto.push({
      name: 'selectLpresupuestocli',
      method: 'lpresupuestocli.select',
      params: { filter: apiFilterDetallesPresupuesto, wrapper: 'count' },
    })
    apiCallsPresupuesto.push({
      name: 'selectPresupuestoCuota',
      method: 'presupuestoCuota.select',
      params: { filter: apiFilterDetallesPresupuesto, wrapper: 'count' },
    })
    const respPresupuesto = await this.Vue.$api.batchCall(apiCallsPresupuesto)
    const presupuestocli = respPresupuesto.data.selectPresupuestocli.result.dataset[0]
    // direccion
    validaciones.direccion.superada = !!presupuestocli.idcliente_direccion
    // tiene artículos -> forma/condición de pago asignada
    if (
      respPresupuesto.data.selectLpresupuestocli.result.dataset[0].count > 0 &&
      (!presupuestocli.idfpago_sistema || !presupuestocli.idcondicion_pago)
    ) {
      validaciones.formaPago.superada = false
    }
    // tiene cuotas -> forma/condición de pago de cuotas asignada
    if (
      respPresupuesto.data.selectLpresupuestocli.result.dataset[0].count > 0 &&
      (!presupuestocli.idfpago_cuotas || !presupuestocli.idcondicion_pago_cuota)
    ) {
      validaciones.formaPagoCuotas.superada = false
    }
    const apiCallsDetalles = []
    // forma de pago remesable
    const apiFilterFormaPago = new APIFilter()
      .addIn('idforma_pago', [presupuestocli.idfpago_sistema, presupuestocli.idfpago_cuotas])
      .addExact('remesable', true)
      .addExact('iban_requerido', true)
    apiCallsDetalles.push({
      name: 'selectFormasPagoRemesables',
      method: 'formaPago.select',
      params: { filter: apiFilterFormaPago, wrapper: 'count' },
    })
    // tiene cuenta bancaria
    const apiFilterCuentaBancaria = new APIFilter()
      .addGT('estado', 0)
    if (presupuestocli.idcliente) {
      apiFilterCuentaBancaria.addExact('idcliente', presupuestocli.idcliente)
    } else {
      apiFilterCuentaBancaria.addExact('idcliente_potencial', presupuestocli.idcliente_potencial)
    }
    apiCallsDetalles.push({
      name: 'selectCuentasBancarias',
      method: 'clienteCuenta.select',
      params: { filter: apiFilterCuentaBancaria, wrapper: 'count' },
    })
    // todos los datos están introducidos
    const apiFilterDatosPresupuesto = new APIFilter()
      .addGT('estado', 0)
      .addExact('requerido', true)
      .addExact('id', idpresupuestocli)
      .addExact('idtabla', TABLA.presupuestocli.idtabla)
    const apiFilterValores = new APIFilter()
    apiFilterValores
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIsNull('valor')
      .addIsNull('valor_numerico')
      .addIsNull('valor_fecha')
    apiFilterDatosPresupuesto.addNestedFilter(apiFilterValores)
    apiCallsDetalles.push({
      name: 'selectDatosPresupuesto',
      method: 'dato.select',
      params: { filter: apiFilterDatosPresupuesto, wrapper: 'count' },
    })
    // todas las ubicaciones están introducidas
    const apiFilterUbicaciones = new APIFilter()
      .addIsNull('ubicacion')
      .addGT('estado', 0)
      .addExact('idpresupuestocli', idpresupuestocli)
    apiCallsDetalles.push({
      name: 'selectUbicaciones',
      method: 'ubiLpresupuestocli.select',
      params: { filter: apiFilterUbicaciones, wrapper: 'count' },
    })
    const respValidacionesDetalles = await this.Vue.$api.batchCall(apiCallsDetalles)
    if (
      respValidacionesDetalles.data.selectFormasPagoRemesables.result.dataset[0].count > 0 &&
      respValidacionesDetalles.data.selectCuentasBancarias.result.dataset[0].count === 0
    ) {
      validaciones.cuentaBancaria.superada = false
    }
    validaciones.ubicaciones.superada = respValidacionesDetalles.data.selectUbicaciones.result.dataset[0].count === 0
    validaciones.datosRequeridos.superada = respValidacionesDetalles.data.selectDatosPresupuesto.result.dataset[0].count === 0
    return validaciones
  }
}
