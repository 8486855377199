import BaseOnlineResource from '@/online/base'

export default class AuditoriaOnlineResource extends BaseOnlineResource {
  async _auditarViewRecord (tabla, id) {
    await this.Vue.$api.call('auditoria.auditarViewRecord', { tabla, id })
  }
  async auditarViewRecordSistemaTelefono (id) {
    await this._auditarViewRecord('SISTEMA_TELEFONO', id)
  }
  async auditarViewRecordClienteTelefono (id) {
    await this._auditarViewRecord('CLIENTE_TELEFONO', id)
  }
  async auditarViewRecordClienteCuenta (id) {
    await this._auditarViewRecord('CLIENTE_CUENTA', id)
  }
}
