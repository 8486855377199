import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  {
    path: '',
    name: 'configuracion__configuracion',
    component: load('configuracion/configuracion/Configuracion'),
  },
  {
    path: 'cambiarcontrasena',
    name: 'configuracion__usuario-edit',
    component: load('configuracion/usuarios/usuarioEdit/UsuarioEdit'),
    meta: {
      permission: {
        idobjeto: permissions.sesion.cambiarContrasena,
        access: ACCESS.edit,
      },
    },
  },
]
