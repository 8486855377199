<template>
  <v-sheet
    class="d-flex flex-row"
  >
    <v-sheet
      v-for="(item, index) in items"
      :key="index"
      class="mr-4"
    >
      <v-img
        width="48"
        height="48"
        :class="item.active ? 'icon-enabled' : 'icon-disabled'"
        :src="`data:image/${imageExtension};base64,${item.nivel_satisfaccion.icono}`"
        @click.stop="selectIcon(item)"
      />
    </v-sheet>
  </v-sheet>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    imageExtension: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      items: [],
    }
  },
  async created () {
    this.items = await this.$offline.nivelSatisfaccion.select()
    this.unselectAll()
  },
  methods: {
    unselectAll () {
      this.items = _.map(this.items, (item) => {
        item.active = false
        return item
      })
    },
    selectIcon (item) {
      const active = item.active
      this.unselectAll()
      item.active = !active
      this.$emit('select-icon', item.nivel_satisfaccion.idnivel_satisfaccion)
    }
  }
}
</script>

<style scoped>
.icon-disabled {
  opacity: 0.2;
}
.icon-enabled {
  opacity: 1;
}
</style>
