import BaseOfflineResource from '@/offline/base'

export default class AgrupacionPreguntaOtOfflineResource extends BaseOfflineResource {
  async deleteSync (idAgrupacionPreguntaOt) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'agrupacionPreguntaOt.deleteSync',
      params: {
        idagrupacion_pregunta_ot: idAgrupacionPreguntaOt
      }
    })
    await this.Vue.$offline.agrupacionPreguntaOt.delete({
      where: tables.agrupacion_pregunta_ot.idagrupacion_pregunta_ot.eq(idAgrupacionPreguntaOt)
    })
  }
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'agrupacionPreguntaOt.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
}
