import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { TDATO_ARTICULO } from '@/utils/consts'
import { isValidDate, toDate } from '@/utils/date'


export default class MaterialSistemaOnlineResource extends BaseOnlineResource {
  title (row) {
    return `${row.descripcion}`
  }
  subtitle (row) {
    return `${row.descripcion} (${row.codigo})`
  }
  badge (row) {
    let toReturn = ''
    if (row.ver_mat_nelemento) {
      toReturn = row.nelemento
    } else if (row.ver_mat_ubicacion) {
      toReturn = row.ubicacion
    }
    return toReturn
  }
  codigoIdentificacion (row) {
    let toReturn = row.codigo
    if (row.ver_mat_nelemento && row.nelemento) {
      toReturn = row.nelemento
    } else if (row.ver_mat_netiqueta && row.netiqueta) {
      toReturn = row.netiqueta
    } else if (row.ver_mat_nserie && row.nserie) {
      toReturn = row.nserie
    }
    return toReturn
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('descripcion', value)
      .addILike('observaciones', value)
      .addILike('ndgp', value)
      .addILike('n_orden', value)
      .addILike('codigo', value)
      .addILike('observaciones_internas', value)
      .addILike('nserie', value)
      .addILike('nelemento', value)
      .addILike('ubicacion', value)
      .addILike('netiqueta', value)
      .addILike('num_zona', value)
      .addILike('evento', value)
      .addILike('funcion', value)
      .addILike('particion', value)
      .addILike('volumen', value)
      .addILike('peso_total', value)
      .addILike('peso_agente_extintor', value)
      .addILike('eficacia', value)
      .addILike('presion', value)
  }
  DatosTecnicosMaterial (materialSistemaRow) {
    const resumenFicha = []
    resumenFicha.push({
      label: `Instalación`,
      value: materialSistemaRow.fsistema,
      name: 'fsistema',
      filter: filters.shortDate,
      visible: true,
    })
    resumenFicha.push({
      label: `Unidades`,
      value: materialSistemaRow.unidades,
      name: 'unidades',
      visible: true,
    })
    resumenFicha.push({
      label: `Nº de serie`,
      value: materialSistemaRow.nserie,
      name: 'nserie',
      visible: materialSistemaRow.ver_mat_nserie
    })
    resumenFicha.push({
      label: `Nº de elemento`,
      value: materialSistemaRow.nelemento,
      name: 'nelemento',
      visible: materialSistemaRow.ver_mat_nelemento,
    })
    resumenFicha.push({
      label: `Ubicación`,
      value: materialSistemaRow.ubicacion,
      name: 'ubicacion',
      visible: materialSistemaRow.ver_mat_ubicacion,
    })
    resumenFicha.push({
      label: `Nº de etiqueta`,
      value: materialSistemaRow.netiqueta,
      name: 'netiqueta',
      visible: materialSistemaRow.ver_mat_netiqueta,
    })
    resumenFicha.push({
      label: `Nº de zona`,
      value: materialSistemaRow.num_zona,
      name: 'num_zona',
      visible: materialSistemaRow.ver_mat_num_zona,
    })
    resumenFicha.push({
      label: `Partición`,
      value: materialSistemaRow.particion,
      name: 'particion',
      visible: materialSistemaRow.ver_mat_particion,
    })
    resumenFicha.push({
      label: `Última revisión`,
      value: materialSistemaRow.fultrevision,
      name: 'fultrevision',
      filter: filters.shortDate,
      visible: materialSistemaRow.ver_mat_fult_revision
    })
    resumenFicha.push({
      label: `Próxima revisión`,
      value: materialSistemaRow.fprox_revision,
      name: 'prox_revision',
      filter: filters.shortDate,
      visible: materialSistemaRow.ver_mat_fprox_revision
    })
    resumenFicha.push({
      label: `Último retimbrado`,
      value: materialSistemaRow.fult_retimbrado,
      name: 'fult_retimbrado',
      filter: filters.shortDate,
      visible: materialSistemaRow.ver_mat_fult_retimbrado
    })
    resumenFicha.push({
      label: `Próximo retimbrado`,
      value: materialSistemaRow.fprox_retimbrado,
      name: 'fprox_retimbrado',
      filter: filters.shortDate,
      visible: materialSistemaRow.ver_mat_fprox_retimbrado
    })
    resumenFicha.push({
      label: `Fecha de caducidad`,
      value: materialSistemaRow.fcaducidad,
      name: 'fcaducidad',
      filter: filters.shortDate,
      visible: materialSistemaRow.ver_mat_fcaducidad
    })
    resumenFicha.push({
      label: `Fin de garantía`,
      value: materialSistemaRow.ffin_garantia,
      name: 'ffin_garantia',
      filter: filters.shortDate,
      visible: materialSistemaRow.ver_mat_ffin_garantia
    })
    resumenFicha.push({
      label: `Fecha de desinstalación`,
      value: materialSistemaRow.fdesinstalacion,
      name: 'fdesinstalacion',
      filter: filters.shortDate,
      visible: true,
    })
    return resumenFicha
  }
  resumenFichaTecnica (materialSistemaRow) {
    const FichaTecnica = this.DatosTecnicosMaterial(materialSistemaRow)
    let toReturn = []
    for (const item of FichaTecnica) {
      if (item.visible && item.value) {
        if (item.filter) {
          toReturn.push(`${item.label}: ${item.filter(item.value)}`)
        } else {
          toReturn.push(`${item.label}: ${item.value}`)
        }
      }
    }
    return toReturn
  }
  async getDatoMaterialSistema (idmaterialSistema) {
    let datosMaterialSistema = []
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idmaterial_sistema', idmaterialSistema)
      .addGT('estado', 0)
    const datasetDatoArticulo = await this.Vue.$api.call('datoMaterialSistema.select', { filter: apiFilter })
    let valor = null
    for (const dato of datasetDatoArticulo.data.result.dataset) {
      // fechas
      if (dato.tipo_valor === TDATO_ARTICULO.tipos.fecha && dato.valor) {
        const valorDate = toDate(dato.valor)
        // si es un día válido
        if (isValidDate(valorDate)) {
          valor = filters.shortDate(valorDate)
        } else {
          // si no lo puedo convertir lo dejo como está
          valor = dato.valor
        }
      } else {
        valor = dato.valor
      }
      datosMaterialSistema.push({
        label: dato.nombre,
        value: valor,
        name: dato.nombre,
        visible: true,
        tipoValor: dato.tipo_valor,
      })
    }
    return datosMaterialSistema
  }
  // FichaTecnica devuelve los datos de material_sistema + dato_material_sistema (ficha_tecinca)
  async fichaTecnica (materialSistemaRow) {
    const fichaTecnicaMaterial = this.DatosTecnicosMaterial(materialSistemaRow)
    // Agrego campos que no salen en el resumen
    fichaTecnicaMaterial.push({
      label: 'Observaciones',
      value: materialSistemaRow.observaciones,
      name: 'observaciones',
      visible: true,
    })
    fichaTecnicaMaterial.push({
      label: 'Material con revisión',
      value: materialSistemaRow.material_con_revision,
      name: 'material_con_revision',
      filter: filters.humanizeBoolean,
      visible: true,
    })
    fichaTecnicaMaterial.push({
      label: 'Importancia',
      value: materialSistemaRow.timportancia_mat_desc,
      name: 'timportancia_mat_desc',
      visible: true,
    })
    fichaTecnicaMaterial.push({
      label: 'Agente extintor',
      value: materialSistemaRow.agente_extintor_desc,
      name: 'idagente_extintor',
      visible: materialSistemaRow.ver_mat_idagente_extintor
    })
    fichaTecnicaMaterial.push({
      label: 'Agente propelente',
      value: materialSistemaRow.agente_propelente_desc,
      name: 'idagente_propelente',
      visible: materialSistemaRow.ver_mat_idagente_propelente
    })
    fichaTecnicaMaterial.push({
      label: 'Fabricante',
      value: materialSistemaRow.fabricante,
      name: 'idfabricante',
      visible: materialSistemaRow.ver_mat_idfabricante
    })
    fichaTecnicaMaterial.push({
      label: 'Nº orden',
      value: materialSistemaRow.n_orden,
      name: 'n_orden',
      visible: true,
    })
    fichaTecnicaMaterial.push({
      label: 'Observaciones internas',
      value: materialSistemaRow.observaciones_internas,
      name: 'observaciones_internas',
      visible: true,
    })
    fichaTecnicaMaterial.push({
      label: 'Permite retirar',
      value: materialSistemaRow.permite_retirar,
      name: 'permite_retirar',
      visible: true,
      filter: filters.humanizeBoolean,
    })
    fichaTecnicaMaterial.push({
      label:'Evento',
      value: materialSistemaRow.evento,
      name: 'evento',
      visible: materialSistemaRow.ver_mat_evento,
    })
    fichaTecnicaMaterial.push({
      label:'Función',
      value: materialSistemaRow.funcion,
      name: 'funcion',
      visible: materialSistemaRow.ver_mat_funcion,
    })
    fichaTecnicaMaterial.push({
      label:'Fecha de fabricación',
      value: materialSistemaRow.ffabricacion,
      name: 'ffabricacion',
      visible: materialSistemaRow.ver_mat_ffabricacion,
      filter: filters.shortDate,
    })
    fichaTecnicaMaterial.push({
      label:'Volumen',
      value: materialSistemaRow.volumen,
      name: 'volumen',
      visible: materialSistemaRow.ver_mat_volumen,
    })
    fichaTecnicaMaterial.push({
      label:'Peso total',
      value: materialSistemaRow.peso_total,
      name: 'peso_total',
      visible: materialSistemaRow.ver_mat_peso_total,
    })
    fichaTecnicaMaterial.push({
      label:'Peso agente extintor',
      value: materialSistemaRow.peso_agente_extintor,
      name: 'peso_agente_extintor',
      visible: materialSistemaRow.ver_mat_peso_agente_extintor,
    })
    fichaTecnicaMaterial.push({
      label:'Eficacia',
      value: materialSistemaRow.eficacia,
      name: 'eficacia',
      visible: materialSistemaRow.ver_mat_eficacia,
    })
    fichaTecnicaMaterial.push({
      label:'Presión',
      value: materialSistemaRow.presion,
      name: 'presion',
      visible: materialSistemaRow.ver_mat_presion,
    })
    let datosMaterialSistema = []
    if (materialSistemaRow.idficha_tecnica) {
      datosMaterialSistema = await this.getDatoMaterialSistema(materialSistemaRow.idmaterial_sistema)
    }
    const fichaTecnica = fichaTecnicaMaterial.concat(datosMaterialSistema)
    return fichaTecnica
  }
}
