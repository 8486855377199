// flujo de la actualización basado en: https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    updated(registration) {
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      )
    },
  })
}
