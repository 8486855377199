import BaseOnlineResource from '@/online/base'

export default class ParteTrabajoTecnicoOnlineResource extends BaseOnlineResource {
  async insertParteTrabajoTecnico (Vue, idparteTrabajo, idtecnico, principal=false) {
    const resp = await Vue.$api.call(
      'parteTrabajoTecnico.insert',
      {
        values: {
          idparte_trabajo: idparteTrabajo,
          idtecnico,
          principal: principal,
        },
      }
    )
    return resp.data.result.dataset[0]
  }
}
