import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { load, appendRoutes } from '@/router/utils'
import citasRoutes from '@/router/citas/index'
import clientesRoutes from '@/router/clientes/index'
import clientesPotencialesRoutes from '@/router/clientesPotenciales/index'
import configuracionRoutes from '@/router/configuracion/index'
import cuadrantesRoutes from '@/router/cuadrantes/index'
import contratosRoutes from '@/router/contratos/index'
import cuentasBancariasRoutes from '@/router/cuentasBancarias/index'
import custodiaLlavesRoutes from '@/router/custodiaLlaves/index'
import datosRoutes from '@/router/datos/index'
import direccionesRoutes from '@/router/direcciones/index'
import documentacionEnviarRoutes from '@/router/documentacionEnviar/index'
import estudiosFinancierosRoutes from '@/router/estudiosFinancieros/index'
import facturasRoutes from '@/router/facturas/index'
import ficherosRoutes from '@/router/ficheros/index'
import operacionesRoutes from '@/router/operaciones/index'
import ordenesRoutes from '@/router/ordenes/index'
import partesRoutes from '@/router/partes/index'
import partesNovedadRoutes from '@/router/partesNovedad/index'
import pedidosRoutes from '@/router/pedidos/index'
import planificacionRoutes from '@/router/planificacion/index'
import presenciaRoutes from '@/router/presencia/index'
import presupuestosRoutes from '@/router/presupuestos/index'
import puestosServicioRoutes from '@/router/puestosServicio/index'
import representantesRoutes from '@/router/representantes/index'
import rondasRoutes from '@/router/rondas/index'
import sincronizacionRoutes from '@/router/sincronizacion/index'
import sistemasRoutes from '@/router/sistemas/index'
import sobresRoutes from '@/router/sobres/index'
import telefonosRoutes from '@/router/telefonos/index'
import tiemposRoutes from '@/router/tiempos/index'
import vehiculosRoutes from '@/router/vehiculos/index'
import vigilantesRoutes from '@/router/vigilantes/index'
import offlineRoutes from '@/router/offline/index'
import { ACCESS, hasViewPerm, hasInsertPerm, hasEditPerm, hasDeletePerm } from '@/utils/permissions'

Vue.use(Router)

let routes = [
  {
    path: '/',
    name: 'login',
    component: load('Login'),
    meta: { noRequiresAuth: true }
  },
  {
    path: '/index',
    name: 'index',
    component: load('index/Index'),
  },
]

// offline
appendRoutes(routes, '/offline/', offlineRoutes)
// online
appendRoutes(routes, '/citas/', citasRoutes)
appendRoutes(routes, '/clientes/', clientesRoutes)
appendRoutes(routes, '/configuracion/', configuracionRoutes)
appendRoutes(routes, '/clientes-potenciales/', clientesPotencialesRoutes)
appendRoutes(routes, '/cuadrantes/', cuadrantesRoutes)
appendRoutes(routes, '/contratos/', contratosRoutes)
appendRoutes(routes, '/cuentas-bancarias/', cuentasBancariasRoutes)
appendRoutes(routes, '/custodia-llaves/', custodiaLlavesRoutes)
appendRoutes(routes, '/datos/', datosRoutes)
appendRoutes(routes, '/direcciones/', direccionesRoutes)
appendRoutes(routes, '/documentacion/', documentacionEnviarRoutes)
appendRoutes(routes, '/estudios-financieros/', estudiosFinancierosRoutes)
appendRoutes(routes, '/facturas/', facturasRoutes)
appendRoutes(routes, '/ficheros/', ficherosRoutes)
appendRoutes(routes, '/operaciones/', operacionesRoutes)
appendRoutes(routes, '/ordenes/', ordenesRoutes)
appendRoutes(routes, '/partes/', partesRoutes)
appendRoutes(routes, '/partes-novedad/', partesNovedadRoutes)
appendRoutes(routes, '/pedidos/', pedidosRoutes)
appendRoutes(routes, '/planificacion/', planificacionRoutes)
appendRoutes(routes, '/presencia/', presenciaRoutes)
appendRoutes(routes, '/presupuestos/', presupuestosRoutes)
appendRoutes(routes, '/puestos-servicio/', puestosServicioRoutes)
appendRoutes(routes, '/representantes/', representantesRoutes)
appendRoutes(routes, '/rondas/', rondasRoutes)
appendRoutes(routes, '/sincronizacion/', sincronizacionRoutes)
appendRoutes(routes, '/sistemas/', sistemasRoutes)
appendRoutes(routes, '/sobres/', sobresRoutes)
appendRoutes(routes, '/telefonos/', telefonosRoutes)
appendRoutes(routes, '/tiempos/', tiemposRoutes)
appendRoutes(routes, '/vehiculos/', vehiculosRoutes)
appendRoutes(routes, '/vigilantes/', vigilantesRoutes)

routes.push({
  path: '/404',
  name: '404',
  component: load('404'),
})
routes.push({
  path: '*',
  name: 'notfound',
  component: load('404'),
})

const router = new Router({
  routes
  // scrollBehavior no funciona para recordar la posición de scroll
  // de una lista porque la lista se carga despues de navegar hacia la ruta
})

router.beforeResolve((to, from, next) => {
  // login con usuario autenticado -> redirigir al menú
  if (to.name === 'login' && store.get('usuario/token')) {
    next({ name: 'index' })
  } else {
    // noRequiresAuth
    if (to.matched.some(record => record.meta.noRequiresAuth)) {
      next()
    } else {
      if (store.get('usuario/token')) {
        next()
      } else {
        if (to.name !== 'login') {
          next({ name: 'login' })
        } else {
          next()
        }
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  to.meta.fromBrowserHistory = window.popStateDetected
  window.popStateDetected = false
  if (to.meta.permission) {
    let canNext = true
    let perm = ''
    if (to.meta.permission.access === ACCESS.view) {
      canNext = hasViewPerm(store.get('usuario/permisos'), to.meta.permission.idobjeto)
      perm = 'visualizar'
    } else if (to.meta.permission.access === ACCESS.insert) {
      canNext = hasInsertPerm(store.get('usuario/permisos'), to.meta.permission.idobjeto)
      perm = 'añadir'
    } else if (to.meta.permission.access === ACCESS.edit) {
      canNext = hasEditPerm(store.get('usuario/permisos'), to.meta.permission.idobjeto)
      perm = 'modificar'
    } else if (to.meta.permission.access === ACCESS.delete) {
      canNext = hasDeletePerm(store.get('usuario/permisos'), to.meta.permission.idobjeto)
      perm = 'eliminar'
    }
    if (canNext) {
      next()
    } else {
      if (Vue.$alert) {
        Vue.$alert.showSnackbarError(`No tienes permiso de acceso para ${perm}`)
      }
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  // Scroll to top on route change issue on iOS
  // https://github.com/vuejs/vue-router/issues/173
  window.scrollTo(0, 0)
  // cierra al ir a otra ruta
  // antes que termine la operación el usuario cambió de página
  if (!store.get('loading/manualLoading')) {
    store.dispatch('loading/hide')
  }
  if (Vue.$alert) {
    Vue.$alert.closePersistentDialogs()
  }
})

export default router

export const NextRoute = (name, params = {}, query = {}, replace = false) => {
  return { name, params, query, replace, isBack: false }
}

export const NextRouteBack = (level = -1) => {
  return { level, isBack: true }
}

export const NextRouteNavigate = (nextRoute) => {
  if (nextRoute.isBack) {
    // NextRouteBack
    router.go(nextRoute.level)
  } else {
    // NextRoute
    if (nextRoute.replace) {
      router.replace({
        name: nextRoute.name,
        params: nextRoute.params,
        query: nextRoute.query,
      })
    } else {
      router.push({
        name: nextRoute.name,
        params: nextRoute.params,
        query: nextRoute.query,
      })
    }
  }
}
