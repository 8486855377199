import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      codigo: {
        label: 'Código',
        type: 'string',
        value: null,
        field: 'codigo'
      },
      descripcion: {
        label: 'Descripción',
        type: 'string',
        value: null,
        field: 'descripcion'
      },
      idsubsis: {
        label: 'Subsistema',
        type: 'multiple-select',
        value: null,
        items: [],
        field: 'idsubsis',
        selectItemValue: 'idsubsis',
        selectItemText: 'descripcion',
      },
    },
    verDesinstalado: false,
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
