import { make } from 'vuex-pathify'

const state = {
  // último registro de presencia de entrada
  fentrada: null,
  descripcion: null,
  idparteNovedad: null,
  idpuestoServicio: null,
}

const mutations = {
  ...make.mutations(state),
}

const actions = {
  ...make.actions(state),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
