<template>
  <div>
    <b10-toolbar
      :title="title"
      back
      :options="allToolbarOptions"
      :with-search="withSearch"
      :with-filter="withFilter"
      :with-sorter="withSorter"
      :search.sync="internalSearch"
      :selecting="internalSelecting"
      :selected-count="selectedCount"
      @click-option="clickToolbarOption"
      @submit-search="onSubmitSearch"
      @clear-selection="clearSelection"
      @select-all="selectAll"
      @click-filter="clickFilter"
      @click-sorter="clickSorter"
    >
      <template
        slot="right"
      >
        <slot
          name="selectedOptions"
          :selecting="internalSelecting"
        />
      </template>
      <template
        slot="menuItemsLongPress"
      >
        <slot name="menuItemsLongPress" />
      </template>
    </b10-toolbar>
    <b10-page-content>
      <b10-list-filter
        :fields.sync="tempFilter"
        :showing.sync="internalShowingFilter"
        @click-submit="clickSubmitFilter"
      />
      <b10-list-filter-description
        :list-filter="filter"
        @clear-filter="clickResetFilter"
        @click-filter="clickFilter"
      />
      <b10-list-sorter
        :fields="sorterDesc"
        :current="tempSorter"
        :showing.sync="internalShowingSorter"
        @click-submit="clickSubmitSorter"
      />
      <div
        v-show="!loading && items.length === 0"
        class="pa-2"
      >
        <v-alert
          dense
          type="info"
        >
          <slot name="descripcion">
            {{ emptyMessage }}
          </slot>
        </v-alert>
      </div>
      <component
        :is="wrapperComponent"
        v-bind="wrapperComponentProps"
      >
        <div
          v-for="(item, index) in items"
          :key="pkName ? `_listitem_${pkValue(item)}` : index"
          v-longpress:[{item,index}]="onLongPressItem"
          @click.stop="clickItem(item, index)"
        >
          <slot
            name="listItem"
            :item="item"
            :index="index"
            :selecting="internalSelecting"
          />
          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          />
        </div>
      </component>
      <b10-load-more
        v-show="showLoadMore && items.length > 0"
        :disabled="loading"
        :page-size="$api.ITEMS_PER_PAGE"
        @load-more="clickLoadMore"
      />
    </b10-page-content>
  </div>
</template>

<script>
import _ from '@/utils/lodash'
import store from '@/store'
import { VList } from 'vuetify/lib'
import { gotoScrollPosition } from '@/utils/ui'

export default {
  components: { VList },
  props: {
    title: {
      type: String,
      default: '',
    },
    toolbarOptions: {
      type: Object,
      default: () => {},
    },
    withSearch: {
      type: Boolean,
      default: true,
    },
    pageStoreName: {
      type: String,
      validator: async value => {
        store.get(`${value}/filter`)
        store.get(`${value}/sorter`)
        store.get(`${value}/sorterDesc`)
        store.get(`${value}/search`)
        store.get(`${value}/items`)
        store.get(`${value}/showLoadMore`)
        store.get(`${value}/scrollPosition`)
        return true
      },
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emptyMessage: {
      type: String,
      default: 'No hay datos',
    },
    wrapperComponent: {
      type: [String, Object],
      default: 'div',
    },
    wrapperComponentProps: {
      type: Object,
      default: () => {},
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    selectedFieldname: {
      type: String,
      default: '_selected',
    },
    showingFilter: {
      type: Boolean,
      default: true,
      required: true,
    },
    showingSorter: {
      type: Boolean,
      default: true,
      required: true,
    },
    selecting: {
      type: Boolean,
      default: true,
    },
    pageLoaded: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: () => {},
    },
    search: {
      type: String,
      default: '',
    },
    sorter: {
      type: Array,
      default: () => [],
    },
    sorterDesc: {
      type: Array,
      default: () => [],
    },
    showLoadMore: {
      type: Boolean,
      default: true,
    },
    scrollPosition: {
      type: Number,
      default: 0,
    },
    // https://stackoverflow.com/questions/66583752/vue-async-await-with-emit#comment117707676_66585473
    initFilter: {
      type: Function,
      default: () => {},
    },
    load: {
      type: Function,
      required: true,
    },
    reloadDirtyItems: {
      type: Function,
      default: () => {},
    },
    pkName: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      defaultToolbarOptions: {
        _refresh: {
          name: '_refresh',
          title: 'Actualizar',
          visible: true,
          icon: 'refresh'
        }
      },
      internalShowingFilter: false,
      internalShowingSorter: false,
      internalSelecting: false,
      tempFilter: {},
      tempSorter: [],
    }
  },
  computed: {
    selectedCount () {
      return _.countBy(this.items, (item) => { return item[this.selectedFieldname] }).true || 0
    },
    allToolbarOptions () {
      let defaultToolbarOptions = _.cloneDeep(this.defaultToolbarOptions)
      return { ...defaultToolbarOptions, ...this.toolbarOptions }
    },
    internalSearch: {
      // OJO: debe definirse como `function` no una función arrow: https://stackoverflow.com/a/50260405
      get: function () {
        return this.search
      },
      set: async function (newValue) {
        await this.setStoreProperty('search', newValue)
      },
    },
    withFilter () {
      return !_.isEmpty(this.filter)
    },
    withSorter () {
      return !_.isEmpty(this.sorter)
    },
  },
  watch: {
    filter: {
      // OJO: debe definirse como `function` no una función arrow: https://stackoverflow.com/a/42242803
      handler: function () {
        this.tempFilter = this.$store.copy(`${this.pageStoreName}/filter`)
      },
      deep: true,
    },
    sorter: {
      // OJO: debe definirse como `function` no una función arrow: https://stackoverflow.com/a/42242803
      handler: function () {
        this.tempSorter = this.$store.copy(`${this.pageStoreName}/sorter`)
      },
      deep: true,
    },
    internalShowingFilter (newValue) {
      this.$emit('update:showingFilter', newValue)
      if (newValue) {
        this.$emit('open-filter')
      }
    },
    internalShowingSorter (newValue) {
      this.$emit('update:showingSorter', newValue)
    },
    showingFilter (newValue) {
      this.internalShowingFilter = newValue
    },
    internalSelecting (newValue) {
      this.$emit('update:selecting', newValue)
    },
    selecting (newValue) {
      this.internalSelecting = newValue
    },
    pageLoaded (newValue) {
      if (newValue) {
        this.initList()
      }
    },
  },
  created () {
    if (this.pageLoaded) {
      this.initList()
    }
  },
  methods: {
    pkValue (item) {
      if (this.pkName) {
        return _.resolveProperty(this.pkName, item)
      } else {
        return null
      }
    },
    async setStoreProperty (property, value) {
      await this.$store.set(`${this.pageStoreName}/${property}`, value)
    },
    dispatchStore (actionName, payload) {
      return this.$store.dispatch(`${this.pageStoreName}/${actionName}`, payload)
    },
    async initList () {
      if (this.items.length === 0) {
        if (this.initFilter) {
          await this.initFilter()
        }
        await this.load()
        if (this.scrollPosition > 0) {
          gotoScrollPosition(this.scrollPosition)
        }
      } else if (this.reloadDirtyItems) {
        await this.reloadDirtyItems()
      }
    },
    clickLoadMore () {
      this.load()
    },
    toggleSelectItem (item) {
      if (item[this.selectedFieldname]) {
        this.$set(item, this.selectedFieldname, false)
      } else {
        this.$set(item, this.selectedFieldname, true)
      }
      this.internalSelecting = this.selectedCount > 0
    },
    multiselectItemDone (item) {
      this.toggleSelectItem(item)
    },
    clickItem (item, index) {
      if (this.internalSelecting) {
        if (item[this.selectedFieldname]) {
          this.multiselectItemDone(item)
        } else {
          this.$emit('multiselect-item', { item, index, multiselectItemDone: this.multiselectItemDone})
        }
      } else {
        this.$emit('click-item', { data: item, index })
      }
    },
    onLongPressItem (data) {
      if (this.multiselect) {
        if (!this.internalSelecting) {
          this.$emit('multiselect-item', { item: data.item, index: data.index, multiselectItemDone: this.multiselectItemDone})
        }
      }
    },
    async clickToolbarOption (option) {
      if (option.name === this.defaultToolbarOptions._refresh.name) {
        await this.dispatchStore('resetList')
        await this.dispatchStore('resetScroll')
        await this.load()
      } else {
        this.$emit('click-toolbar-option', option)
      }
    },
    async resetFilter () {
      for (const field in this.filter) {
        if (!this.filter[field].options?.fixed) {
          await this.setStoreProperty(`filter@${field}.value`, null)
        }
      }
      if (this.initFilter) {
        await this.initFilter()
      }
    },
    async onSubmitSearch () {
      await this.dispatchStore('search')
      await this.load()
    },
    async clickSubmitFilter () {
      // para evitar que al dispararse el watch de filter se cambie tempFilter
      const tempFilter = _.cloneDeep(this.tempFilter)
      for (const fieldname in tempFilter) {
        await this.setStoreProperty(`filter@${fieldname}.value`, tempFilter[fieldname].value)
      }
      await this.dispatchStore('filter')
      await this.load()
    },
    async clickResetFilter () {
      await this.resetFilter()
      await this.dispatchStore('filter')
      await this.load()
    },
    async clickSubmitSorter (sorterFields) {
      const newSorter = []
      for (const fieldIndex in sorterFields) {
        newSorter.push({
          field: sorterFields[fieldIndex].field,
          asc: sorterFields[fieldIndex].asc,
        })
      }
      await this.setStoreProperty('sorter', newSorter)
      await this.dispatchStore('resetList')
      await this.load()
    },
    clearSelection () {
      this.internalSelecting = false
      this.dispatchStore('clearSelection', { selectedFieldname: this.selectedFieldname })
    },
    selectAll () {
      this.items.forEach(
        (item, index) => {
          if (!item[this.selectedFieldname]) {
            this.$emit('multiselect-item', { item, index, multiselectItemDone: this.multiselectItemDone})
          }
        }
      )
    },
    clickFilter () {
      this.tempFilter = _.cloneDeep(this.filter)
      this.internalShowingFilter = true
    },
    clickSorter () {
      this.tempSorter = _.cloneDeep(this.sorter)
      this.internalShowingSorter = true
    },
  }
}
</script>
