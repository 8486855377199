import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      clienteNombre: {
        label: 'Nombre',
        type: 'string',
        value: null,
        field: 'nombre'
      },
      razonSocial: {
        label: 'Razón social',
        type: 'string',
        value: null,
        field: 'razon_social'
      },
      cif: {
        label: 'NIF',
        type: 'string',
        value: null,
        field: 'cif'
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
