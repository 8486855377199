import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class PresupuestoCuotaOnlineResource extends BaseOnlineResource {
  title (row) {
    return row.descripcion_cuota
  }
  subtitle (row) {
    return row.periodo_descripcion
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('descripcion_cuota', value)
      .addILike('periodo_descripcion', value)
  }
  importeTotal (precioMes, meses, dto, unidades) {
    const total = precioMes * meses * (1 - (dto / 100)) * unidades
    if (total) {
      return total.toFixed(2)
    } else {
      return 0
    }
  }
}
