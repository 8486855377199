import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      clienteNombre: {
        label: 'Nombre',
        type: 'string',
        value: null,
        field: 'nombre'
      },
      razonSocial: {
        label: 'Razón social',
        type: 'string',
        value: null,
        field: 'razon_social'
      },
      cif: {
        label: 'NIF',
        type: 'string',
        value: null,
        field: 'cif'
      },
      grupoCliente: {
        label: 'Grupo de clientes',
        type: 'select',
        value: null,
        items: [],
        field: 'idgrupo_cliente',
        selectItemValue: 'idgrupo_cliente',
        selectItemText: 'nombre',
      },
    },
  },
  getters: {},
  mutations: {
    RESET_STORE_CUSTOM: (state) => {
      state.filter.grupoCliente.items = []
    },
  },
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
