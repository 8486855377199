<template>
  <v-textarea
    v-bind="$props"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { VTextarea } from 'vuetify/lib'

// https://stackoverflow.com/questions/59767835/vuetify-custom-v-text-field-component-is-not-updating-the-v-model
export default {
  extends: VTextarea,
  props: {
    clearable: {
      type: Boolean,
      default: true,
    },
    autoGrow:  {
      type: Boolean,
      default: true,
    },
    flat:  {
      type: Boolean,
      default: true,
    },
  },
}
</script>
