import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class CitaOnlineResource extends BaseOnlineResource {
  title (row) {
    return row.titulo
  }
  subtitle (row) {
    let toReturn
    if (row.ffin) {
      if (filters.shortDate(row.fcita) === filters.shortDate(row.ffin)) {
        toReturn = `${filters.shortDateTime(row.fcita)} - ${filters.shortTime(row.ffin)}`
      } else {
        toReturn = `${filters.shortDateTime(row.fcita)} - ${filters.shortDateTime(row.ffin)}`
      }
    } else {
      toReturn = `${filters.shortDateTime(row.fcita)}`
    }
    return toReturn
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('titulo', value)
      .addILike('cita', value)
  }
  async delete (idcita) {
    await this.Vue.$api.call('cita.delete', { idcita: idcita })
  }
}
