<template>
  <v-list>
    <v-list-item
      v-for="option in options"
      v-show="option.visible"
      :key="option.title"
      @click.stop="clickOption(option)"
    >
      <v-list-item-avatar>
        <v-icon
          v-if="option.icon"
          :color="option.color"
        >
          {{ $vuetify.icons.values[option.icon] }}
        </v-icon>
        <v-icon
          v-else
        >
          {{ $vuetify.icons.values.next }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-title>
        {{ option.title }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    options: {
      type: Object,
      required: true,
      validator: (value) => {
        for (let option in value) {
          if ((!_.has(value[option], 'title')) ||
              (!_.has(value[option], 'visible')) ||
              (typeof value[option].title !== 'string') ||
              (typeof value[option].visible !== 'boolean')) {
            return false
          }
        }
        return true
      }
    }
  },
  methods: {
    clickOption (option) {
      this.$emit('click-option', option)
    }
  }
}
</script>

<style scoped>
.v-list__tile__avatar {
  min-width: 48px;
}
</style>
