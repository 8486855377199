import BaseOfflineResource from '@/offline/base'
import { PARAMETRO, TINCLUSION } from '@/utils/consts'

function ParametroNotFound (message) {
  const error = new Error(message)
  return error
}

ParametroNotFound.prototype = Object.create(Error.prototype)

export default class ParametroOfflineResource extends BaseOfflineResource {
  async tipoInclusionDefecto () {
    const parametroTipoInclusionDefecto = await this.Vue.$offline.parametro.row(
      PARAMETRO.parametros.TIPO_INCLUSION_DEFECTO
    )
    if (parametroTipoInclusionDefecto.parametro.valor === '1') {
      return TINCLUSION.instalacion
    } else if (parametroTipoInclusionDefecto.parametro.valor === '3') {
      return TINCLUSION.preguntar
    } else {
      return TINCLUSION.suministro
    }
  }
  async valorParametro (idparametro, throwException = true) {
    const tables = this.db.tables
    const parametro = await this.select({
      where: tables.parametro.idparametro.eq(idparametro)
    })
    if (parametro.length === 0) {
      if (throwException) {
        throw new ParametroNotFound(`Parámatro ${idparametro} no encontrado`)
      } else {
        return undefined
      }
    } else {
      return parametro[0].parametro.valor
    }
  }
}
