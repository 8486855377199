import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import pathify from 'vuex-pathify'
import almacen from '@/store/almacen'
import cronometro from '@/store/cronometro'
import devices from '@/store/devices'
import dirty from '@/store/dirty'
import loading from '@/store/loading'
import network from '@/store/network'
import presencia from '@/store/presencia'
import sync from '@/store/sync'
import tips from '@/store/tips'
import usuario from '@/store/usuario'
import vigilancia from '@/store/vigilancia'
// pages online
import pagesCitaList from '@/pages/citas/citaList/store'
import pagesClienteConPotencialesList from '@/pages/clientes/clienteConPotencialesList/store'
import pagesClienteCuentaList from '@/pages/cuentasBancarias/clienteCuentaList/store'
import pagesClienteDireccionList from '@/pages/direcciones/clienteDireccionList/store'
import pagesClienteList from '@/pages/clientes/clienteList/store'
import pagesClientePotencialList from '@/pages/clientesPotenciales/clientePotencialList/store'
import pagesContactoClienteList from '@/pages/contactosCliente/contactoClienteList/store'
import pagesContratoList from '@/pages/contratos/contratoList/store'
import pagesContratoCuotaList from '@/pages/contratos/cuotas/contratoCuotaList/store'
import pagesContratoMaterialList from '@/pages/contratos/materiales/contratoMaterialList/store'
import pagesDatoList from '@/pages/datos/datoList/store'
import pagesDatoSistemaList from '@/pages/sistemas/datoSistemaList/store'
import pagesDocumentoList from '@/pages/documentacionEnviar/documentoList/store'
import pagesEstudioFinancieroList from '@/pages/estudiosFinancieros/estudioFinancieroList/store'
import pagesFacturacliFacturacliList from '@/pages/facturas/facturacliList/store'
import pagesFicheroList from '@/pages/ficheros/ficheroList/store'
import pagesLlaveroList from '@/pages/custodiaLlaves/llaveroList/store'
import pagesLpresupuestocliList from '@/pages/presupuestos/lineas/lpresupuestocliList/store'
import pagesOperacioncrmList from '@/pages/operacionescrm/operacioncrmList/store'
import pagesLoperacioncrmList from '@/pages/loperacionescrm/loperacioncrmList/store'
import pagesOrdenTrabajoList from '@/pages/ordenes/ordenTrabajoList/store'
import pagesOrdenTrabajoSubsisList from '@/pages/ordenes/ordenTrabajoSubsisList/store'
import pagesOrdenTrabajoMatsistList from '@/pages/ordenes/ordenTrabajoMatsistList/store'
import pagesOrdenTrabajoMatsistSubsisList from '@/pages/ordenes/ordenTrabajoMatsistSubsisList/store'
import pagesLordenTrabajoList from '@/pages/ordenes/lordenTrabajoList/store'
import pagesAccionAsignadoFechaFijadaList from '@/pages/partes/accionAsignadoFechaFijadaList/store'
import pagesLparteNovedadList from '@/pages/partesNovedad/lparteNovedadList/store'
import pagesParteNovedadList from '@/pages/partesNovedad/parteNovedadList/store'
import pagesParteAsignadoFechaFijada from '@/pages/partes/parteAsignadoFechaFijada/store'
import pagesParteAsignadosList from '@/pages/partes/parteAsignadosList/store'
import pagesParteTrabajoTecnicoList from '@/pages/partes/tecnicosAsignados/parteTrabajoTecnicoList/store'
import pagesParteTrabajoList from '@/pages/partes/parteTrabajoList/store'
import pagesParteTrabajoMatsistSubsisList from '@/pages/partes/parteTrabajoMatsistSubsisList/store'
import pagesParteTrabajoMatsistList from '@/pages/partes/parteTrabajoMatsistList/store'
import pagesPedidoClienteList from '@/pages/pedidos/pedidoClienteList/store'
import pagesAgenda from '@/pages/planificacion/agenda/store'
import pagesPlanificadorTecnicos from '@/pages/planificacion/planificadorTecnicos/store'
import pagesPresenciaList from '@/pages/presencia/presenciaList/store'
import pagesPresupuestocliList from '@/pages/presupuestos/presupuestocliList/store'
import pagesPresupuestocliMantList from '@/pages/presupuestos/revisiones/presupuestocliMantList/store'
import pagesPresupuestoCuotaList from '@/pages/presupuestos/cuotas/presupuestoCuotaList/store'
import pagesPuestoServicioList from '@/pages/puestosServicio/puestoServicioList/store'
import pagesServicioList from '@/pages/puestosServicio/servicioList/store'
import pagesTurnoServicioList from '@/pages/puestosServicio/turnoServicioList/store'
import pagesRepresentanteList from '@/pages/representantes/representantesList/store'
import pagesMrondaList from '@/pages/rondas/mrondaList/store'
import pagesMaterialSistemaList from '@/pages/materialesSistema/materialSistemaList/store'
import pagesMaterialSistemaPlano from '@/pages/materialesSistema/materialSistemaPlano/store'
import pagesMrondaPuntoList from '@/pages/rondas/mrondaPuntoList/store'
import pagesRondasRondaList from '@/pages/rondas/rondaList/store'
import pagesSyncUploadList from '@/pages/sincronizacion/syncUploadList/store'
import pagesServicioContratadoList from '@/pages/contratos/serviciosContratados/servicioContratadoList/store'
import pagesSistemaCuotaList from '@/pages/sistemas/sistemaCuota/sistemaCuotaList/store'
import pagesSistemaList from '@/pages/sistemas/sistemaList/store'
import pagesSistemaMantList from '@/pages/sistemas/sistemaMantList/store'
import pagesSistemaTviacomunicacionList from '@/pages/sistemas/sistemaTviacomunicacionList/store'
import pagesSobreList from '@/pages/sobres/sobreList/store'
import pagesSobreDestinatarioList from '@/pages/sobres/destinatarios/sobreDestinatarioList/store'
import pagesSobreDestinatarioFaltanList from '@/pages/sobres/destinatarios/sobreDestinatarioFaltanList/store'
import pagesSobreDocumentoList from '@/pages/sobres/documentos/sobreDocumentoList/store'
import pagesSobreEnvioList from '@/pages/sobres/envios/sobreEnvioList/store'
import pagesSubsisList from '@/pages/subsis/subsisList/store'
import pagesClienteTelefonoList from '@/pages/telefonos/clienteTelefonoList/store'
import pagesTiempoAgenda from '@/pages/tiempos/tiempoAgenda/store'
import pagesTiempoList from '@/pages/tiempos/tiempoTrabajadoList/store'
import pagesMaterialVehiculoList from '@/pages/vehiculos/materialVehiculoList/store'
import pagesStockList from '@/pages/vehiculos/stockList/store'
import pagesVigilanteList from '@/pages/vigilantes/vigilanteList/store'
import pagesUbiLpresupuestocliList from '@/pages/presupuestos/ubicaciones/ubiLpresupuestocliList/store'
import pagesPresupuestocliSubsisList from '@/pages/presupuestos/subsistemas/presupuestocliSubsisList/store'

// pages offline
import pagesOfflineAccionCorrectoraList from '@/pages/offline/accionCorrectoraList/store'
import pagesOfflineAnomaliaAdd from '@/pages/offline/anomaliaAdd/store'
import pagesOfflineAnomaliaList from '@/pages/offline/anomaliaList/store'
import pagesOfflineAnomaliaMaterialAddList from '@/pages/offline/anomaliaMaterialAddList/store'
import pagesOfflineArticuloList from '@/pages/offline/articuloList/store'
import pagesOfflineClienteTelefonoList from '@/pages/offline/clienteTelefonoList/store'
import pagesOfflineDatoSistemaList from '@/pages/offline/datoSistemaList/store'
import pagesOfflineFicheroList from '@/pages/offline/ficheroList/store'
import pagesOfflineFicheroView from '@/pages/offline/ficheroView/store'
import pagesOfflineLordenTrabajoList from '@/pages/offline/lordenTrabajoList/store'
import pagesOfflineLordenTrabajoDetalleList from '@/pages/offline/lordenTrabajoDetalleList/store'
import pagesOfflineLparteTrabajoList from '@/pages/offline/lparteTrabajoList/store'
import pagesOfflineLparteTrabajoMaterialAfectadoList from '@/pages/offline/lparteTrabajoMaterialAfectadoList/store'
import pagesOfflineLparteTrabajoMaterialAfectadoSubsisList from '@/pages/offline/lparteTrabajoMaterialAfectadoSubsisList/store'
import pagesOfflineLparteTrabajoSubsisList from '@/pages/offline/lparteTrabajoSubsisList/store'
import pagesOfflineMaterialSistemaList from '@/pages/offline/materialSistemaList/store'
import pagesOfflineMaterialSistemaSubsisList from '@/pages/offline/materialSistemaSubsisList/store'
import pagesOfflineOrdenTrabajoSubsisList from '@/pages/offline/ordenTrabajoSubsisList/store'
import pagesOfflineParteDescargadosList from '@/pages/offline/parteDescargadosList/store'
import pagesOfflineParteTrabajoTecnicoList from '@/pages/offline/parteTrabajoTecnicoList/store'
import pagesOfflinePlantillaArticuloList from '@/pages/offline/plantillaArticuloList/store'
import pagesOfflinePreguntaList from '@/pages/offline/preguntaList/store'
import pagesOfflineSistemaCuotaList from '@/pages/offline/sistemaCuotaList/store'
import pagesOfflineSistemaTviacomunicacion from '@/pages/offline/sistemaTviacomunicacionList/store'
import pagesOfflineSistemaMantList from '@/pages/offline/sistemaMantList/store'
import pagesOfflineSubsisList from '@/pages/offline/subsisList/store'
import pagesOfflineSyncList from '@/pages/offline/syncList/store'
import pagesOfflineSyncUploadList from '@/pages/offline/syncUploadList/store'
import pagesOfflineTiempoTrabajadoList from '@/pages/offline/tiempoTrabajadoList/store'

Vue.use(Vuex)

const vuexLocalAlmacen = new VuexPersistence({
  key: 'almacen',
  modules: ['almacen'],
})

const vuexLocalCronometro = new VuexPersistence({
  key: 'cronometro',
  modules: ['cronometro'],
})

const vuexLocalDevices = new VuexPersistence({
  key: 'devices',
  modules: ['devices'],
})

const vuexLocalPresencia = new VuexPersistence({
  key: 'presencia',
  modules: ['presencia'],
})

const vuexLocalTips = new VuexPersistence({
  key: 'tips',
  modules: ['tips'],
})

const vuexLocalUsuario = new VuexPersistence({
  key: 'usuario',
  modules: ['usuario'],
})

const vuexLocalVigilancia = new VuexPersistence({
  key: 'vigilancia',
  modules: ['vigilancia'],
})

const vuexLocalPages = new VuexPersistence({
  key: 'pages',
  reducer: (state) => ({
    // listas -> sorter persistente
    pagesCitaList: {
      sorter: state.pagesCitaList?.sorter,
    },
    pagesClienteList: {
      sorter: state.pagesClienteList?.sorter,
    },
    pagesClienteConPotencialesList: {
      sorter: state.pagesClienteConPotencialesList?.sorter,
    },
    pagesClientePotencialList: {
      sorter: state.pagesClientePotencialList?.sorter,
    },
    pagesContactoClienteList: {
      sorter: state.pagesContactoClienteList?.sorter,
    },
    pagesContratoList: {
      sorter: state.pagesContratoList?.sorter,
    },
    pagesContratoCuotaList: {
      sorter: state.pagesContratoCuotaList?.sorter,
    },
    pagesContratoMaterialList: {
      sorter: state.pagesContratoMaterialList?.sorter,
    },
    pagesClienteCuentaList: {
      sorter: state.pagesClienteCuentaList?.sorter,
    },
    pagesClienteDireccionList: {
      sorter: state.pagesClienteDireccionList?.sorter,
    },
    pagesDatoList: {
      sorter: state.pagesDatoList?.sorter,
    },
    pagesDatoSistemaList: {
      sorter: state.pagesDatoSistemaList?.sorter,
    },
    pagesDocumentoList: {
      sorter: state.pagesDocumentoList?.sorter,
    },
    pagesEstudioFinancieroList: {
      sorter: state.pagesEstudioFinancieroList?.sorter,
    },
    pagesLlaveroList:{
      sorter: state.pagesLlaveroList?.sorter,
    },
    pagesFicheroList:{
      sorter: state.pagesFicheroList?.sorter,
    },
    pagesLpresupuestocliList:{
      sorter: state.pagesLpresupuestocliList?.sorter,
    },
    pagesOfflineAccionCorrectoraList: {
      sorter: state.pagesOfflineAccionCorrectoraList?.sorter,
    },
    pagesOfflineAnomaliaList: {
      sorter: state.pagesOfflineAnomaliaList?.sorter,
    },
    pagesOfflineAnomaliaMaterialAddList: {
      sorter: state.pagesOfflineAnomaliaMaterialAddList?.sorter,
    },
    pagesOfflineArticuloList: {
      sorter: state.pagesOfflineArticuloList?.sorter,
      mostrarConImpuestos: state.pagesOfflineArticuloList?.mostrarConImpuestos,
    },
    pagesOfflineClienteTelefonoList: {
      sorter: state.pagesOfflineClienteTelefonoList?.sorter,
    },
    pagesOfflineDatoSistemaList: {
      sorter: state.pagesOfflineDatoSistemaList?.sorter,
    },
    pagesOfflineFicheroList: {
      sorter: state.pagesOfflineFicheroList?.sorter,
    },
    pagesOfflineLordenTrabajoList: {
      sorter: state.pagesOfflineLordenTrabajoList?.sorter,
    },
    pagesOfflineLordenTrabajoDetalleList: {
      sorter: state.pagesOfflineLordenTrabajoDetalleList?.sorter,
    },
    pagesOfflineLparteTrabajoList: {
      sorter: state.pagesOfflineLparteTrabajoList?.sorter,
    },
    pagesOfflineLparteTrabajoMaterialAfectadoList: {
      sorter: state.pagesOfflineLparteTrabajoMaterialAfectadoList?.sorter,
    },
    pagesOfflineLparteTrabajoMaterialAfectadoSubsisList: {
      sorter: state.pagesOfflineLparteTrabajoMaterialAfectadoSubsisList?.sorter,
    },
    pagesOfflineLparteTrabajoSubsisList: {
      sorter: state.pagesOfflineLparteTrabajoSubsisList?.sorter,
    },
    pagesOfflineMaterialSistemaList: {
      sorter: state.pagesOfflineMaterialSistemaList?.sorter,
    },
    pagesOfflineMaterialSistemaSubsisList: {
      sorter: state.pagesOfflineMaterialSistemaSubsisList?.sorter,
    },
    pagesOfflineOrdenTrabajoSubsisList: {
      sorter: state.pagesOfflineOrdenTrabajoSubsisList?.sorter,
    },
    pagesOfflineParteDescargadosList: {
      sorter: state.pagesOfflineParteDescargadosList?.sorter,
    },
    pagesOfflineParteTrabajoTecnicoList: {
      sorter: state.pagesOfflineParteTrabajoTecnicoList?.sorter,
    },
    pagesOfflinePreguntaList: {
      sorter: state.pagesOfflinePreguntaList?.sorter,
    },
    pagesOfflineSistemaCuotaList: {
      sorter: state.pagesOfflineSistemaCuotaList?.sorter,
    },
    pagesOfflineSistemaTviacomunicacion: {
      sorter: state.pagesOfflineSistemaTviacomunicacion?.sorter,
    },
    pagesOfflineSistemaMantList: {
      sorter: state.pagesOfflineSistemaMantList?.sorter,
    },
    pagesOfflineSubsisList: {
      sorter: state.pagesOfflineSubsisList?.sorter,
    },
    pagesOfflineSyncList: {
      sorter: state.pagesOfflineSyncList?.sorter,
    },
    pagesOfflineSyncUploadList: {
      sorter: state.pagesOfflineSyncUploadList?.sorter,
    },
    pagesOfflineTiempoTrabajadoList: {
      sorter: state.pagesOfflineTiempoTrabajadoList?.sorter,
    },
    pagesOperacioncrmList: {
      sorter: state.pagesOperacioncrmList?.sorter,
    },
    pagesLoperacioncrmList: {
      sorter: state.pagesLoperacioncrmList?.sorter,
    },
    pagesOrdenTrabajoList: {
      sorter: state.pagesOrdenTrabajoList?.sorter,
    },
    pagesOrdenTrabajoSubsisList: {
      sorter: state.pagesOrdenTrabajoSubsisList?.sorter,
    },
    pagesLordenTrabajoList: {
      sorter: state.pagesLordenTrabajoList?.sorter,
    },
    pagesOrdenTrabajoMatsistList: {
      sorter: state.pagesOrdenTrabajoMatsistList?.sorter,
    },
    pagesOrdenTrabajoMatsistSubsisList: {
      sorter: state.pagesOrdenTrabajoMatsistSubsisList?.sorter,
    },
    pagesAccionAsignadoFechaFijadaList:{
      sorter: state.pagesAccionAsignadoFechaFijadaList?.sorter,
    },
    pagesParteAsignadosList:{
      sorter: state.pagesParteAsignadosList?.sorter,
    },
    pagesParteTrabajoList:{
      sorter: state.pagesParteTrabajoList?.sorter,
    },
    pagesParteTrabajoTecnicoList:{
      sorter: state.pagesParteTrabajoTecnicoList?.sorter,
    },
    pagesParteTrabajoMatsistList: {
      sorter: state.pagesParteTrabajoMatsistList?.sorter,
    },
    pagesParteTrabajoMatsisSubsisList: {
      sorter: state.pagesParteTrabajoMatsisSubsisList?.sorter,
    },
    pagesLparteNovedadList:{
      sorter: state.pagesLparteNovedadList?.sorter,
    },
    pagesParteNovedadList:{
      sorter: state.pagesParteNovedadList?.sorter,
    },
    pagesPedidoClienteList:{
      sorter: state.pagesPedidoClienteList?.sorter,
    },
    pagesPresenciaList:{
      sorter: state.pagesPresenciaList?.sorter,
    },
    pagesPresupuestocliList:{
      sorter: state.pagesPresupuestocliList?.sorter,
    },
    pagesPresupuestocliMantList:{
      sorter: state.pagesPresupuestocliMantList?.sorter,
    },
    pagesPresupuestoCuotaList:{
      sorter: state.pagesPresupuestoCuotaList?.sorter,
    },
    pagesPuestoServicioList:{
      sorter: state.pagesPuestoServicioList?.sorter,
    },
    pagesServicioList:{
      sorter: state.pagesServicioList?.sorter,
    },
    pagesTurnoServicioList:{
      sorter: state.pagesTurnoServicioList?.sorter,
    },
    pagesRepresentanteList: {
      sorter: state.pagesRepresentanteList?.sorter,
    },
    pagesMrondaList:{
      sorter: state.pagesMrondaList?.sorter,
    },
    pagesMrondaPuntoList:{
      sorter: state.pagesMrondaPuntoList?.sorter,
    },
    pagesRondasRondaList:{
      sorter: state.pagesRondasRondaList?.sorter,
    },
    pagesSyncUploadList:{
      sorter: state.pagesSyncUploadList?.sorter,
    },
    pagesServicioContratadoList: {
      sorter: state.pagesServicioContratadoList?.sorter,
    },
    pagesSistemaCuotaList:{
      sorter: state.pagesSistemaCuotaList?.sorter,
    },
    pagesSistemaList:{
      sorter: state.pagesSistemaList?.sorter,
    },
    pagesMaterialSistemaList: {
      sorter: state.pagesMaterialSistemaList?.sorter,
    },
    pagesSistemaTviacomunicacionList:{
      sorter: state.pagesSistemaTviacomunicacionList?.sorter,
    },
    pagesSistemaMantList: {
      sorter: state.pagesSistemaMantList?.sorter,
    },
    pagesSobreList: {
      sorter: state.pagesSobreList?.sorter,
    },
    pagesSobreDestinatarioList: {
      sorter: state.pagesSobreDestinatarioList?.sorter,
    },
    pagesSobreDestinatarioFaltanList: {
      sorter: state.pagesSobreDestinatarioFaltanList?.sorter,
    },
    pagesSobreDocumentoList: {
      sorter: state.pagesSobreDocumentoList?.sorter,
    },
    pagesSobreEnvioList: {
      sorter: state.pagesSobreEnvioList?.sorter,
    },
    pagesSubsisList: {
      sorter: state.pagesSubsisList?.sorter,
    },
    pagesClienteTelefonoList:{
      sorter: state.pagesClienteTelefonoList?.sorter,
    },
    pagesMaterialVehiculoList:{
      sorter: state.pagesMaterialVehiculoList?.sorter,
    },
    pagesStockList:{
      sorter: state.pagesStockList?.sorter,
    },
    pagesVigilanteList:{
      sorter: state.pagesVigilanteList?.sorter,
    },
    pagesTiempoList: {
      sorter: state.pagesTiempoList?.sorter,
    },
    pagesFacturacliFacturacliList: {
      sorter: state.pagesFacturacliFacturacliList?.sorter,
    },
    pagesUbiLpresupuestocliList: {
      sorter: state.pagesUbiLpresupuestocliList?.sorter,
    },
    pagesPresupuestocliSubsisList: {
      sorter: state.pagesPresupuestocliSubsisList?.sorter,
    },
  })
})

const store = new Vuex.Store({
  plugins: [
    pathify.plugin,
    vuexLocalAlmacen.plugin,
    vuexLocalCronometro.plugin,
    vuexLocalDevices.plugin,
    vuexLocalPages.plugin,
    vuexLocalPresencia.plugin,
    vuexLocalTips.plugin,
    vuexLocalUsuario.plugin,
    vuexLocalVigilancia.plugin,
  ],
  modules: {
    almacen,
    cronometro,
    devices,
    dirty,
    loading,
    network,
    presencia,
    sync,
    tips,
    usuario,
    vigilancia,
    // pages online
    pagesCitaList,
    pagesClienteConPotencialesList,
    pagesClienteCuentaList,
    pagesClienteDireccionList,
    pagesClienteList,
    pagesClientePotencialList,
    pagesContactoClienteList,
    pagesContratoList,
    pagesContratoCuotaList,
    pagesContratoMaterialList,
    pagesDatoList,
    pagesDatoSistemaList,
    pagesDocumentoList,
    pagesEstudioFinancieroList,
    pagesFacturacliFacturacliList,
    pagesFicheroList,
    pagesLlaveroList,
    pagesLpresupuestocliList,
    pagesOperacioncrmList,
    pagesLoperacioncrmList,
    pagesOrdenTrabajoList,
    pagesOrdenTrabajoSubsisList,
    pagesOrdenTrabajoMatsistList,
    pagesOrdenTrabajoMatsistSubsisList,
    pagesLordenTrabajoList,
    pagesAccionAsignadoFechaFijadaList,
    pagesLparteNovedadList,
    pagesParteNovedadList,
    pagesParteAsignadoFechaFijada,
    pagesParteAsignadosList,
    pagesParteTrabajoList,
    pagesParteTrabajoTecnicoList,
    pagesParteTrabajoMatsistList,
    pagesParteTrabajoMatsistSubsisList,
    pagesPedidoClienteList,
    pagesAgenda,
    pagesPlanificadorTecnicos,
    pagesPresenciaList,
    pagesPresupuestocliList,
    pagesPresupuestocliMantList,
    pagesPresupuestoCuotaList,
    pagesPuestoServicioList,
    pagesServicioList,
    pagesTurnoServicioList,
    pagesRepresentanteList,
    pagesMrondaList,
    pagesMrondaPuntoList,
    pagesRondasRondaList,
    pagesSyncUploadList,
    pagesServicioContratadoList,
    pagesSistemaCuotaList,
    pagesSistemaList,
    pagesMaterialSistemaList,
    pagesMaterialSistemaPlano,
    pagesSubsisList,
    pagesSistemaMantList,
    pagesSistemaTviacomunicacionList,
    pagesSobreDestinatarioList,
    pagesSobreDestinatarioFaltanList,
    pagesSobreDocumentoList,
    pagesSobreEnvioList,
    pagesSobreList,
    pagesClienteTelefonoList,
    pagesTiempoAgenda,
    pagesTiempoList,
    pagesMaterialVehiculoList,
    pagesStockList,
    pagesVigilanteList,
    pagesUbiLpresupuestocliList,
    pagesPresupuestocliSubsisList,
    // pages offline
    pagesOfflineAccionCorrectoraList,
    pagesOfflineAnomaliaAdd,
    pagesOfflineAnomaliaList,
    pagesOfflineAnomaliaMaterialAddList,
    pagesOfflineArticuloList,
    pagesOfflineClienteTelefonoList,
    pagesOfflineDatoSistemaList,
    pagesOfflineFicheroList,
    pagesOfflineFicheroView,
    pagesOfflineLordenTrabajoDetalleList,
    pagesOfflineLordenTrabajoList,
    pagesOfflineLparteTrabajoList,
    pagesOfflineLparteTrabajoMaterialAfectadoList,
    pagesOfflineLparteTrabajoMaterialAfectadoSubsisList,
    pagesOfflineLparteTrabajoSubsisList,
    pagesOfflineMaterialSistemaList,
    pagesOfflineMaterialSistemaSubsisList,
    pagesOfflineOrdenTrabajoSubsisList,
    pagesOfflineParteDescargadosList,
    pagesOfflineParteTrabajoTecnicoList,
    pagesOfflinePlantillaArticuloList,
    pagesOfflinePreguntaList,
    pagesOfflineSistemaCuotaList,
    pagesOfflineSistemaTviacomunicacion,
    pagesOfflineSistemaMantList,
    pagesOfflineSubsisList,
    pagesOfflineSyncList,
    pagesOfflineSyncUploadList,
    pagesOfflineTiempoTrabajadoList,
  },
})

export default store
