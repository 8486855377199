<template>
  <v-fab-transition>
    <v-btn
      v-show="!hideOnScroll || scrollingUp"
      fab
      bottom
      :dark="dark"
      :right="right"
      :left="left"
      :fixed="fixed"
      :small="small"
      :color="color"
      :x-large="xLarge"
      @click="$emit('click')"
    >
      <v-icon
        :small="small"
      >
        {{ icon || $vuetify.icons.values.add }}
      </v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    right: {
      type: Boolean,
      default: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'secondary',
    },
    dark: {
      type: Boolean,
      default: true,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    hideOnScroll: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      scrollingUp: true,
      scrollTop: window.scrollY,
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      this.scrollingUp = (window.scrollY < this.scrollTop) || window.scrollY === 0
      this.scrollTop = window.scrollY
    }
  },
}
</script>
