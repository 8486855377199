import { make } from 'vuex-pathify'

const state = {
  // último inicio de cronómetro
  finicio: null,
  idparte: null,
  latitud: null,
  longitud: null,
  para: null,
  esDesplazamiento: null,
}

const mutations = {
  ...make.mutations(state),
}

const actions = {
  ...make.actions(state),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
