import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      descripcion: {
        label: 'Descripción',
        type: 'string',
        value: null,
        field: 'descripcion'
      },
      fdesde: {
        label: 'Fecha desde',
        type: 'date',
        value: null,
        field: 'fproximarevision',
      },
      fhasta: {
        label: 'Fecha hasta',
        type: 'date',
        value: null,
        field: 'fproximarevision',
      },
      toperacioncrm: {
        label: 'Tipo',
        type: 'select',
        value: null,
        items: [],
        field: 'idtoperacioncrm',
        selectItemValue: 'idtoperacioncrm',
        selectItemText: 'descripcion',
      },
      informador: {
        label: 'Informador',
        type: 'select',
        value: null,
        items: [],
        field: 'idinformador',
        selectItemValue: 'idempleado',
        selectItemText: 'nombre_y_apellido',
      },
      responsable: {
        label: 'Responsable',
        type: 'select',
        value: null,
        items: [],
        field: 'idresponsable',
        selectItemValue: 'idempleado',
        selectItemText: 'nombre_y_apellido',
      },
      abierta: {
        label: 'Abierta',
        type: 'check',
        value: null,
        field: 'abierta'
      },
      asignada: {
        label: 'Asignada a mí',
        type: 'check',
        value: null,
        field: 'idresponsable'
      },
      informada: {
        label: 'Informada por mí',
        type: 'check',
        value: null,
        field: 'idinformador'
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
