<template>
  <v-dialog
    v-model="internalShowing"
    max-width="400px"
  >
    <v-card>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="(field, index) in sorterFields"
            :key="index"
          >
            <v-list-item-title>{{ index+1 }}º {{ field.label }} (<span v-if="field.asc">ascendente</span><span v-else>descendente</span>)</v-list-item-title>
            <v-list-item-action
              v-if="editing"
            >
              <v-btn
                icon
                @click="deleteField(index)"
              >
                <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-form
          v-if="editing"
          ref="form"
          lazy-validation
          @submit.prevent="submitAddField"
        >
          <v-row align="center">
            <v-col>
              <b10-autocomplete
                v-model="form.field"
                :rules="formRules.field"
                :items="fields"
                item-text="label"
                item-value="name"
                label="Campo"
              />
            </v-col>
            <v-col>
              <b10-autocomplete
                v-model="form.asc"
                :items="ascValues"
                item-text="label"
                item-value="value"
                label="Orden"
              />
            </v-col>
            <v-col class="shrink">
              <v-btn
                icon
                color="primary"
                type="submit"
                @click="submitAddField"
              >
                <v-icon>{{ $vuetify.icons.values.add }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          icon
          color="primary"
          @click.stop="clickEdit"
        >
          <v-icon
            left
          >
            {{ $vuetify.icons.values.edit }}
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          text
          @click.stop="clickCancel"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click.stop="submit"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import _ from '@/utils/lodash'

export default {
  mixins: [internalShowingMixin],
  props: {
    fields: {
      type: Array,
      required: true,
      validator: (value) => {
        for (let field in value) {
          if ((!_.has(value[field], 'label')) ||
              (!_.has(value[field], 'name')) ||
              (typeof value[field].label !== 'string') ||
              (typeof value[field].name !== 'string')
          ) {
            return false
          }
        }
        return true
      }
    },
    current: {
      type: Array,
      required: true,
      validator: (value) => {
        for (let field in value) {
          if ((!_.has(value[field], 'field')) ||
              (!_.has(value[field], 'asc')) ||
              (typeof value[field].field !== 'string') ||
              (typeof value[field].asc !== 'boolean')
          ) {
            return false
          }
        }
        return true
      }
    },
  },
  data () {
    return {
      form: {
        field: '',
        asc: true,
      },
      formRules: {
        field: [
          v => !!v || 'Campo requerido',
          v => this.currentFieldnames.indexOf(v) === -1 || 'No puedes ordenar dos veces por el mismo campo',
        ],
      },
      editing: false,
      ascValues: [
        {
          label: 'Ascendente',
          value: true,
        },
        {
          label: 'Descendente',
          value: false,
        },
      ],
      sorterFields: [],
    }
  },
  computed: {
    currentFieldnames () {
      return _.map(this.sorterFields, 'field')
    },
  },
  watch: {
    internalShowing (newInternalShowing) {
      if (newInternalShowing) {
        this.sorterFields = _.cloneDeep(this.current)
        for (const field in this.sorterFields) {
          this.sorterFields[field].label = this.getLabel(this.sorterFields[field].field)
        }
        this.editing = false
        this.resetForm()
      }
    },
  },
  methods: {
    getFieldIndexByName (fieldname) {
      return _.findIndex(this.fields, { name: fieldname })
    },
    getLabel (fieldname) {
      const fieldIndex = this.getFieldIndexByName(fieldname)
      if (fieldIndex === -1) {
        return ''
      } else {
        return this.fields[fieldIndex].label
      }
    },
    deleteField (index) {
      this.sorterFields.splice(index, 1)
    },
    clickEdit () {
      this.editing = !this.editing
    },
    resetForm () {
      this.$set(this.form, 'field', '')
      this.$set(this.form, 'asc', true)
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    submitAddField () {
      if (this.$refs.form.validate()) {
        this.sorterFields.push({
          field: this.form.field,
          asc: this.form.asc,
          label: this.getLabel(this.form.field),
        })
        this.resetForm()
      }
    },
    clickCancel () {
      this.internalShowing = false
    },
    submit () {
      // por si se le olvida darle al + para agregar el campo
      if (this.form.field && this.$refs.form.validate()) {
        this.submitAddField()
      }
      this.$emit('click-submit', this.sorterFields)
      this.internalShowing = false
    },
  }
}
</script>
