import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class PuestoServicioOnlineResource extends BaseOnlineResource {
  async selectPorVigilante (Vue, idvigilante) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idvigilante', idvigilante)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('puestoServicio.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
