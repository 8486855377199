<template>
  <div>
    <v-card
      v-for="(image, index) in formattedImages"
      :key="index"
      class="mb-2 py-2 mx-1 text-center"
    >
      <template
        v-if="image.isImage"
      >
        <template
          v-if="image.url"
        >
          <v-img
            :src="image.absoluteMediaURL"
            height="200px"
            @click.stop="clickDownload(image)"
          >
            <template
              #placeholder
            >
              <v-row
                class="fill-height ma-1 grey lighten-4"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                />
              </v-row>
            </template>
          </v-img>
        </template>
        <template
          v-else-if="image.data"
        >
          <img
            :src="image.data"
            style="max-width: 100%; min-height: 100px; object-fit: cover;"
            @click.stop="clickDownload(image)"
          >
        </template>
      </template>
      <v-card-actions>
        <v-chip
          v-if="image.extension"
          :color="image.extensionColor"
          dark
          label
          small
        >
          {{ image.extension|uppercase }}
        </v-chip>
        <div
          class="text-truncate mx-2"
        >
          {{ image.filename }}
        </div>
        <v-spacer />
        <v-btn
          v-if="canDownload"
          icon
          color="primary"
          @click.stop="clickDownload(image)"
        >
          <v-icon>{{ $vuetify.icons.values.download }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { downloadDataURL, extractFileExtension, extractFileName } from '@/utils/files'
import { stringToHslColor } from '@/utils/ui'
import _ from '@/utils/lodash'

export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    relativeURLField: {
      type: String,
      default: 'url',
    },
    filenameField: {
      type: String,
      default: 'ruta_fichero',
    },
    dataField: {
      type: String,
      default: 'data',
    },
    typeField: {
      type: String,
      default: 'type',
    },
    canDownload: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formattedImages () {
      const images = _.cloneDeep(this.images)
      for (const image of images) {
        const filename = _.resolveProperty(this.filenameField, image)
        if (filename) {
          this.$set(image, 'filename', extractFileName(filename))
          this.$set(image, 'extension', extractFileExtension(filename))
          this.$set(image, 'extensionColor', stringToHslColor(extractFileExtension(filename)))
        }
        const type = _.resolveProperty(this.typeField, image) || 'application/octet-stream'
        if (type) {
          this.$set(image, 'isImage', type.startsWith('image'))
        } else {
          this.$set(image, 'isImage', false)
        }
        if (this.relativeURLField in image) {
          this.$set(image, 'absoluteMediaURL', this.$api.getMediaFileURL(image[this.relativeURLField]))
        } else {
          const data = _.resolveProperty(this.dataField, image)
          if (data && type) {
            this.$set(image, 'data', `data:${type};base64,${data}`)
          }
        }
      }
      return images
    },
  },
  methods: {
    clickDownload (image) {
      if (this.canDownload) {
        if (image.absoluteMediaURL) {
          window.open(image.absoluteMediaURL, '_blank')
        } else if (image.data) {
          downloadDataURL(
            this,
            _.resolveProperty(this.typeField, image),
            _.resolveProperty(this.dataField, image),
            _.resolveProperty(this.filenameField, image)
          )
        }
      }
    },
  },
}
</script>