<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
  >
    <template #activator="{ on: menu }">
      <v-text-field
        :value="valueFormatted"
        :label="title"
        clearable
        :rules="rules"
        :disabled="disabled"
        readonly
        :error-messages="errorMessages"
        @click:clear="clearValue"
        v-on="{ ...menu }"
      />
    </template>
    <v-date-picker
      v-model="computedValue"
      locale="es"
      type="date"
      :first-day-of-week="1"
      :disabled="disabled"
      @change="changeValue"
    />
  </v-menu>
</template>

<script>
import { toDate, format as formatDate } from '@/utils/date'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      // ISO 8601 o Date
      type: [String, Date],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      showMenu: false
    }
  },
  computed: {
    computedValue: {
      get () {
        if (this.value) {
          return formatDate(toDate(this.value), 'yyyy-MM-dd')
        } else {
          return ''
        }
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    valueFormatted () {
      if (this.computedValue) {
        return this.$options.filters.shortDate(toDate(this.computedValue))
      } else {
        return ''
      }
    }
  },
  methods: {
    clearValue () {
      this.computedValue = null
      this.$emit('change', null)
    },
    changeValue (value) {
      this.showMenu = false
      this.$emit('change', value)
    },
  }
}
</script>
