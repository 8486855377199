import _ from '@/utils/lodash'
import BaseOfflineResource from '@/offline/base'
import { PARTE_TRABAJO, ACCION_EJEC } from '@/utils/consts'
import filters from '@/utils/filters'
import {  nonEmpty } from '@/utils/templates'

export default class ParteTrabajoOfflineResource extends BaseOfflineResource {
  title (row) {
    let nabonado = ''
    if (row.sistema.nabonado1) {
      nabonado = `(Abonado Nº${row.sistema.nabonado1})`
    }
    if (row.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente) {
      let fecha = row.parte_trabajo.fparte
      if (row.parte_trabajo.ffijada) {
        fecha = row.parte_trabajo.ffijada
      }
      return filters.linebreaksBr(nonEmpty`
        ${filters.shortDate(fecha)} - ${row.orden_trabajo.orden_trabajo_serie_numero}>${row.parte_trabajo.idparte_trabajo} - ${row.cliente.nombre} (${row.cliente.idcliente}) ${nabonado}`
      )
    } else {
      return filters.linebreaksBr(nonEmpty`
      ${filters.shortDate(row.parte_trabajo.ffinparte)} - ${row.orden_trabajo.orden_trabajo_serie_numero}>${row.parte_trabajo.idparte_trabajo} - ${row.cliente.nombre} (${row.cliente.idcliente}) ${nabonado}`
      )
    }
  }
  subtitle (row) {
    return filters.linebreaksBr(nonEmpty`
      ${row.tactuacion.descripcion} de ${row.tsistema.descripcion} de sistema ${row.sistema.descripcion} (${row.sistema.codigo})
      ${row.direccion}
      ${row.cliente_direccion.observacion}
      ${row.parte_trabajo.observacion}
    `)
  }
  async updateParteTrabajoSubidoSync (idparteTrabajoTecnico) {
    await this.Vue.$offline.sync.insert({
      method: 'parteTrabajo.setSubidoSync',
      params: {
        idparte_trabajo_tecnico: idparteTrabajoTecnico,
      },
    })
  }
  selectCantAccionesPendientes (idparteTrabajo) {
    const tables = this.db.tables
    return this.db
      .select(
        this.db.fn.count(
          tables.accion_ejec.idaccion_ejec
        ).as('cant_acciones_pendientes')
      )
      .from(tables.accion_ejec)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.parte_trabajo,
        tables.orden_trabajo_matsist.idorden_trabajo.eq(tables.parte_trabajo.idorden_trabajo)
      )
      .where(
        this.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.accion_ejec.idest_accion_ejec.eq(ACCION_EJEC.estados.pendiente),
          tables.material_sistema.fdesinstalacion.isNull()
        )
      )
      .exec()
  }
  selectCantAccionesRealizadas (idparteTrabajo) {
    const tables = this.db.tables
    return this.db
      .select(
        this.db.fn.count(
          tables.accion_ejec.idaccion_ejec
        ).as('cant_acciones_realizadas')
      )
      .from(tables.accion_ejec)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.parte_trabajo,
        tables.orden_trabajo_matsist.idorden_trabajo.eq(tables.parte_trabajo.idorden_trabajo)
      )
      .where(
        this.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.accion_ejec.idest_accion_ejec.eq(ACCION_EJEC.estados.realizada)
        )
      )
      .exec()
  }
  selectCantAccionesTotal (idparteTrabajo) {
    const tables = this.db.tables
    const estados = [
      ACCION_EJEC.estados.realizada,
      ACCION_EJEC.estados.pendiente
    ]
    return this.db
      .select(
        this.db.fn.count(
          tables.accion_ejec.idaccion_ejec
        ).as('cant_acciones_total')
      )
      .from(tables.accion_ejec)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.parte_trabajo,
        tables.orden_trabajo_matsist.idorden_trabajo.eq(tables.parte_trabajo.idorden_trabajo)
      )
      .where(
        this.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.accion_ejec.idest_accion_ejec.in(estados)
        )
      )
      .exec()
  }
  async finParteTrabajo (
    idparteTrabajo, idordenTrabajo, comentarioFinalTecnico, notasInternas,
    idtcierreParte, copiarComentarioAAlbaran, idtecnico, estado, comentarioPresupuesto,
    dejarAccionesPendientes, fechaFin
  ) {
    const tables = this.db.tables
    await this.Vue.$offline.tiempoTrabajado.borrarManoObraFacturable(idparteTrabajo)
    await this.Vue.$offline.tiempoTrabajado.borrarDesplazamientoFacturable(idparteTrabajo)
    await this.Vue.$offline.tiempoTrabajado.generarManoObraFacturable(idparteTrabajo)
    await this.Vue.$offline.tiempoTrabajado.generarDesplazamientoFacturable(idparteTrabajo)
    let idestAccionEjec = ACCION_EJEC.estados.pendiente
    if (!dejarAccionesPendientes) {
      idestAccionEjec = ACCION_EJEC.estados.no_realizada
    }
    await this.Vue.$offline.sync.insert({
      method: 'parteTrabajo.finSync',
      params:
        {
          idparte_trabajo: idparteTrabajo,
          comentario_final_tecnico: comentarioFinalTecnico,
          notas_internas: notasInternas,
          idtcierre_parte: idtcierreParte,
          copiar_comentario_aalbaran: copiarComentarioAAlbaran,
          idtecnico: idtecnico,
          estado: estado,
          comentario_final_presupuesto: comentarioPresupuesto,
          idest_accion_ejec: idestAccionEjec,
          ffinmontaje: fechaFin
        }
    })
    if (!dejarAccionesPendientes) {
      const accionesPendientes = await this.db
        .select(
          this.db.fn.distinct(tables.accion_ejec.idaccion_ejec).as('idaccion_ejec')
        )
        .from(tables.accion_ejec)
        .innerJoin(
          tables.orden_trabajo_matsist,
          tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
        )
        .innerJoin(
          tables.material_sistema,
          tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
        )
        .innerJoin(
          tables.parte_trabajo,
          tables.orden_trabajo_matsist.idorden_trabajo.eq(tables.parte_trabajo.idorden_trabajo)
        )
        .where(
          this.db.op.and(
            tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
            tables.accion_ejec.idest_accion_ejec.eq(ACCION_EJEC.estados.pendiente),
            tables.material_sistema.fdesinstalacion.isNull()
          )
        )
        .exec()
      const inAccionesPendientes = _.map(accionesPendientes, 'idaccion_ejec')
      await this.db
        .update(tables.accion_ejec)
        .set(tables.accion_ejec.idest_accion_ejec, ACCION_EJEC.estados.no_realizada)
        .where(tables.accion_ejec.idaccion_ejec.in(inAccionesPendientes))
        .exec()
    }
    await this.db
      .update(tables.parte_trabajo)
      .set(tables.parte_trabajo.comentario_final_tecnico, comentarioFinalTecnico)
      .set(tables.parte_trabajo.idtcierre_parte, idtcierreParte)
      .set(tables.parte_trabajo.copiar_comentario_aalbaran, copiarComentarioAAlbaran)
      .set(tables.parte_trabajo.estado, estado)
      .set(tables.parte_trabajo.comentario_final_presupuesto, comentarioPresupuesto)
      .where(tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo))
      .exec()
    await this.db
      .update(tables.orden_trabajo)
      .set(tables.orden_trabajo.notas_internas, notasInternas)
      .where(tables.orden_trabajo.idorden_trabajo.eq(idordenTrabajo))
      .exec()
  }
  selectParteTrabajoTotal (idparteTrabajo) {
    const tables = this.db.tables
    return this.db
      .select(
        tables.lorden_trabajo.precio,
        tables.lorden_trabajo.dto,
        tables.lorden_trabajo.iva,
        tables.lorden_trabajo.recargo,
        tables.lparte_trabajo.unidades
      )
      .from(tables.lparte_trabajo)
      .innerJoin(
        tables.lorden_trabajo,
        tables.lparte_trabajo.idlorden_trabajo.eq(tables.lorden_trabajo.idlorden_trabajo)
      )
      .where(
        this.db.op.and(
          tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.lparte_trabajo.facturar.eq(true)
        )
      )
      .exec()
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'parteTrabajo.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async inIdsPartesOffline () {
    const tables = this.db.tables
    return _.map(
      await this.select({
        select: [tables.parte_trabajo.idparte_trabajo]
      }),
      'parte_trabajo.idparte_trabajo'
    )
  }
  async selectAnomaliasResumen (idparteTrabajo, idchecklistOt) {
    const tables = this.db.tables
    const inPreguntasGeneral = await this.Vue.$offline.preguntaChecklistOt.inPreguntasGeneral(idchecklistOt)
    const inPreguntasSubsistema = await this.Vue.$offline.preguntaChecklistOt.inPreguntasSubsistema(idparteTrabajo, idchecklistOt)
    const inPreguntasMaterial = await this.Vue.$offline.preguntaChecklistOt.inPreguntasMaterial(idparteTrabajo, idchecklistOt)
    return await this.Vue.$offline.db
      .select(
        tables.grado_anomalia.idgrado_anomalia,
        tables.grado_anomalia.alias,
        tables.grado_anomalia.descripcion,
        tables.grado_anomalia.orden,
        this.Vue.$offline.db.fn.count(tables.tanomalia_checklist_ot.idtanomalia_checklist_ot).as('count'),
      )
      .from(tables.tanomalia_checklist_ot)
      .innerJoin(
        tables.grado_anomalia,
        tables.tanomalia_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
      )
      .where(
        this.Vue.$offline.db.op.and(
          this.Vue.$offline.db.op.or(
            tables.tanomalia_checklist_ot.idpregunta_checklist_ot.in(inPreguntasGeneral),
            tables.tanomalia_checklist_ot.idpregunta_checklist_ot.in(inPreguntasSubsistema),
            tables.tanomalia_checklist_ot.idpregunta_checklist_ot.in(inPreguntasMaterial)
          ),
          tables.tanomalia_checklist_ot.estado.gt(0)
        )
      )
      .groupBy(
        tables.grado_anomalia.idgrado_anomalia,
        tables.grado_anomalia.alias,
        tables.grado_anomalia.descripcion,
        tables.grado_anomalia.orden,
      )
      .orderBy(tables.grado_anomalia.orden, this.Vue.$offline.db.order.DESC)
      .exec()
  }
  async selectAccionesCorrectorasResumen (idparteTrabajo, idchecklistOt) {
    const tables = this.Vue.$offline.db.tables
    const inPreguntasGeneral = await this.Vue.$offline.preguntaChecklistOt.inPreguntasGeneral(idchecklistOt)
    const inPreguntasSubsistema = await this.Vue.$offline.preguntaChecklistOt.inPreguntasSubsistema(idparteTrabajo, idchecklistOt)
    const inPreguntasMaterial = await this.Vue.$offline.preguntaChecklistOt.inPreguntasMaterial(idparteTrabajo, idchecklistOt)
    return (await this.Vue.$offline.db
      .select(
        this.Vue.$offline.db.fn.count(tables.accion_correctora_ot.idaccion_correctora_ot).as('count'),
      )
      .from(tables.accion_correctora_ot)
      .innerJoin(
        tables.tanomalia_checklist_ot,
        tables.accion_correctora_ot.idtanomalia_checklist_ot.eq(tables.tanomalia_checklist_ot.idtanomalia_checklist_ot)
      )
      .where(
        this.Vue.$offline.db.op.and(
          this.Vue.$offline.db.op.or(
            tables.tanomalia_checklist_ot.idpregunta_checklist_ot.in(inPreguntasGeneral),
            tables.tanomalia_checklist_ot.idpregunta_checklist_ot.in(inPreguntasSubsistema),
            tables.tanomalia_checklist_ot.idpregunta_checklist_ot.in(inPreguntasMaterial)
          ),
          tables.tanomalia_checklist_ot.estado.gt(0),
          tables.accion_correctora_ot.estado.gt(0)
        )
      )
      .exec())[0]
  }
  async subsisPrincipal (idparteTrabajo) {
    const tables = this.Vue.$offline.db.tables
    const subsis = (await this.Vue.$offline.db
      .select(
        this.Vue.$offline.db.fn.min(tables.orden_trabajo_subsis.idsubsis).as('idsubsis'),
      )
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo_subsis,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo_subsis.idorden_trabajo)
      )
      .where(
        this.Vue.$offline.db.op.and(
          tables.orden_trabajo_subsis.estado.gt(0),
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo)
        )
      )
      .exec()
    )
    if (subsis.length > 0) {
      return this.Vue.$offline.subsis.row(subsis[0].idsubsis)
    }
  }
  async reabrirParte (idparteTrabajo) {
    const tables = this.Vue.$offline.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'parteTrabajo.reabrirSync',
      params: { idparte_trabajo: idparteTrabajo },
    })
    await this.db
      .update(tables.parte_trabajo)
      .set(tables.parte_trabajo.ffirma, null)
      .set(tables.parte_trabajo.firma, null)
      .set(tables.parte_trabajo.firmante, null)
      .set(tables.parte_trabajo.dni_firmante, null)
      .set(tables.parte_trabajo.estado, PARTE_TRABAJO.estados.pendiente)
      .where(tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo))
      .exec()
    await this.Vue.$offline.tiempoTrabajado.borrarManoObraFacturable(idparteTrabajo)
    await this.Vue.$offline.tiempoTrabajado.borrarDesplazamientoFacturable(idparteTrabajo)
  }
  async faltaIndicarNserieEnMaterial (idsubsis, idparteTrabajo) {
    const tables = this.db.tables
    const materiales = (await this.Vue.$offline.db
      .select(
        this.Vue.$offline.db.fn.count(tables.lparte_trabajo.idlparte_trabajo).as('count'),
      )
      .from(tables.lparte_trabajo)
      .innerJoin(
        tables.articulo,
        tables.lparte_trabajo.idarticulo.eq(tables.articulo.idarticulo)
      )
      .where(
        this.db.op.and(
          tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.lparte_trabajo.idsubsis.eq(idsubsis),
          tables.lparte_trabajo.nserie.isNull(),
          tables.articulo.nserie_obligado.eq(true),
        )
      )
      .exec()
    )[0]
    return materiales.count > 0
  }
}
