import _ from '@/utils/lodash'
import BaseOfflineResource from '@/offline/base'
import { TINCLUSION, LORDEN_TRABAJO } from '@/utils/consts'
import { v4 as uuidv4 } from 'uuid'

export default class LordenTrabajoOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    if (!_.has(values, 'unidades_pend_instalar')) {
      values.unidades_pend_instalar = values.unidades
    }
    await this.Vue.$offline.sync.insert({
      method: 'lordenTrabajo.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  async deleteSync (idLordenTrabajo) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'lordenTrabajo.deleteSync',
      params: {
        idlorden_trabajo: idLordenTrabajo
      }
    })
    await this.Vue.$offline.lordenTrabajo.delete({
      where: tables.lorden_trabajo.idlorden_trabajo.eq(idLordenTrabajo)
    })
  }
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'lordenTrabajo.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async insertLordenYDetalleSync (
    idordenTrabajo,
    codigoArticulo,
    unidades,
    idsubsis,
    tinclusion = TINCLUSION.instalacion,
    facturar = true,
    idtmotivoNofacturable = null,
    descripcionArticulo = null,
    kilometraje = 0,
    observaciones = null,
  ) {
    const tables = this.db.tables
    const ot = (await this.db
      .select()
      .from(tables.orden_trabajo)
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .innerJoin(
        tables.regimen_fiscal,
        tables.cliente.idregimen_fiscal.eq(tables.regimen_fiscal.idregimen_fiscal)
      )
      .where(tables.orden_trabajo.idorden_trabajo.eq(idordenTrabajo))
      .exec())[0]
    const articulo = await this.Vue.$offline.articulo.buscarArticulo(
      codigoArticulo,
      ot.orden_trabajo.idttarifa,
      ot.orden_trabajo.idbanco_precio,
      ot.cliente.ididioma,
      ot.orden_trabajo.idtoperacion_impuesto,
      tinclusion
    )
    let precioLinea = 0
    let dtoLinea = 0
    if (tinclusion === TINCLUSION.suministro) {
      precioLinea = articulo.precio
    } else {
      precioLinea = articulo.precioIns
    }
    dtoLinea = articulo.dto
    const otSubsis = await this.db
      .select()
      .from(tables.orden_trabajo)
      .innerJoin(
        tables.orden_trabajo_subsis,
        tables.orden_trabajo.idorden_trabajo.eq(tables.orden_trabajo_subsis.idorden_trabajo)
      )
      .where(
        this.db.op.and(
          tables.orden_trabajo_subsis.idorden_trabajo.eq(idordenTrabajo),
          tables.orden_trabajo_subsis.idsubsis.eq(idsubsis)
        )
      )
      .exec()
    const idlordenTrabajo = uuidv4()
    await this.Vue.$offline.lordenTrabajo.insertSync({
      idlorden_trabajo: idlordenTrabajo,
      idorden_trabajo: ot.orden_trabajo.idorden_trabajo,
      idarticulo: articulo.idarticulo,
      idlest_orden_trabajo: LORDEN_TRABAJO.estados.en_instalacion,
      codigo: codigoArticulo,
      descripcion: descripcionArticulo || articulo.descripcion,
      unidades,
      iva: ot.regimen_fiscal.con_iva ? articulo.valorIVA : 0,
      recargo: ot.regimen_fiscal.con_recargo ? articulo.valorRecargo : 0,
      dto: dtoLinea,
      estado: 1,
      facturar,
      unidades_orig: unidades,
      precio: precioLinea,
      idsubsis,
      kilometraje,
      costo: articulo.coste,
      idtmotivo_nofacturable: idtmotivoNofacturable,
      idfabricante: articulo.data.articulo.idfabricante,
      idagente_extintor: articulo.data.articulo.idagente_extintor,
      idagente_propelente: articulo.data.articulo.idagente_propelente,
      volumen: articulo.data.articulo.volumen,
      peso_total: articulo.data.articulo.peso_total,
      peso_agente_extintor: articulo.data.articulo.peso_agente_extintor,
      eficacia: articulo.data.articulo.eficacia,
      presion: articulo.data.articulo.presion,
      observacion: observaciones
    })
    for (let escandallo of articulo.escandallo) {
      await this.Vue.$offline.lordenTrabajoDetalle.insertSync({
        idlorden_trabajo_detalle: uuidv4(),
        idlorden_trabajo: idlordenTrabajo,
        idarticulo: escandallo.idarticulo,
        descripcion: escandallo.descripcion,
        unidades: escandallo.unidades * unidades,
        idlest_orden_trabajo: LORDEN_TRABAJO.estados.en_instalacion,
        estado: 1,
        costo: escandallo.coste,
      })
    }
    if (otSubsis.length === 0) {
      await this.Vue.$offline.ordenTrabajoSubsis.insertSync({
        idorden_trabajo_subsis: uuidv4(),
        estado: 1,
        idorden_trabajo: idordenTrabajo,
        idsubsis,
      })
    }
    return idlordenTrabajo
  }
}
