import { make } from 'vuex-pathify'

const state = {
  visible: false,
  disableUI: false,
  manualLoading: false,
  message: '',
}

const mutations = {
  ...make.mutations(state),
}

const actions = {
  ...make.actions(state),
  show ({ commit }, payload) {
    commit('SET_VISIBLE', true)
    commit('SET_DISABLE_UI', ((payload) && (payload.disableUI)))
    commit('SET_MANUAL_LOADING', ((payload) && (payload.manualLoading)))
    commit('SET_MESSAGE', ((payload) && (payload.message)))
  },
  hide ({ commit }) {
    commit('SET_VISIBLE', false)
    commit('SET_DISABLE_UI', false)
    commit('SET_MANUAL_LOADING', false)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
