<template>
  <v-dialog
    v-model="internalShowing"
    max-width="400px"
  >
    <v-card tile>
      <v-toolbar
        light
        dense
        flat
      >
        <v-toolbar-title>
          Confirmación
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        class="body-1 py-2"
      >
        <slot name="cardText" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <slot name="cardActions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin],
}
</script>
