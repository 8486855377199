<template>
  <v-dialog
    v-model="internalShowing"
    max-width="400px"
  >
    <v-card>
      <v-card-title
        v-html="title"
      />
      <v-card-text>
        <span
          v-html="subtitle"
        />
        <slot />
      </v-card-text>
      <v-card-actions
        class="button-container"
      >
        <v-btn
          v-show="lat && lng"
          color="green"
          dark
          icon
          @click.stop="clickOpenLatLng(lat, lng)"
        >
          <v-icon>
            {{ $vuetify.icons.values.route }}
          </v-icon>
        </v-btn>
        <slot name="cardExtraButtons" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { getDirectionsURL } from '@/utils/maps'

export default {
  mixins: [internalShowingMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    lat: {
      type: Number,
      default: 0,
    },
    lng: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    clickClose () {
      this.internalShowing = false
    },
    clickOpenLatLng (lat, lng) {
      window.open(getDirectionsURL(lat, lng), '_blank')
    },
  },
}
</script>

<style scoped>
.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.button {
  flex-basis: calc(50% - 10px); /* 50% para 2 botones, -10px para el espacio entre botones */
  margin-bottom: 10px; /* Espacio entre filas de botones */
}
</style>