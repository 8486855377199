import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    verVacios: false,
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
