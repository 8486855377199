import { load } from '@/router/utils'

export default [
  {
    path: 'llaveros',
    name: 'custodiallaves__llavero-list',
    component: load('custodiaLlaves/llaveroList/LlaveroList'),
  },
  {
    path: 'llaveros/:idllavero',
    name: 'custodiallaves__llavero-view',
    component: load('custodiaLlaves/llaveroView/LlaveroView'),
  },
  {
    path: 'llaveros/:idllavero/entrada',
    name: 'custodiallaves__llavero-entrada',
    component: load('custodiaLlaves/llaveroEntrada/LlaveroEntrada'),
  },
  {
    path: 'llaveros/:idllavero/salida',
    name: 'custodiallaves__llavero-salida',
    component: load('custodiaLlaves/llaveroSalida/LlaveroSalida'),
  },
]
