<template>
  <v-list-item
    :color="item.color"
  >
    <slot />
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    }
  }
}
</script>
