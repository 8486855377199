import _ from '@/utils/lodash'
import { currentDateTime } from '@/utils/date'
import BaseOfflineResource from '@/offline/base'

export default class FicheroOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    const newValues = _.cloneDeep(values)
    newValues.fcreacion = currentDateTime()
    const tfichero = await this.Vue.$offline.tfichero.row(newValues.idtfichero)
    // para qué manda esto si la magia lo hace el pkg_metadata_mov
    newValues.visible_movilidad_sat = tfichero.tfichero.visible_movilidad_sat
    newValues.visible_movilidad_comercial = tfichero.tfichero.visible_movilidad_comercial
    newValues.visible_movilidad_vigilancia = tfichero.tfichero.visible_movilidad_vigilancia
    await this.Vue.$offline.sync.insert({
      method: 'fichero.insertSync',
      params: { values: newValues },
    })
    return await super.insert(newValues, options)
  }
  async updateSync (values) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'fichero.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    const tfichero = await this.Vue.$offline.tfichero.row(values.idtfichero)
    query
      .set(tables.fichero.visible_movilidad_sat, tfichero.tfichero.visible_movilidad_sat)
      .set(tables.fichero.visible_movilidad_comercial, tfichero.tfichero.visible_movilidad_comercial)
      .set(tables.fichero.visible_movilidad_vigilancia, tfichero.tfichero.visible_movilidad_vigilancia)
    return await query.exec()
  }
  async deleteSync (idfichero) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'fichero.deleteSync',
      params: { idfichero: idfichero },
    })
    await this.Vue.$offline.fichero.delete({
      where: tables.fichero.idfichero.eq(idfichero)
    })
  }
  selectFicheroTabla (idtabla, id) {
    const tables = this.db.tables
    return this.db
      .select(
        tables.fichero.idfichero,
        tables.fichero.nombre_fichero,
        tables.tfichero.descripcion.as('descripcion_tfichero'),
        tables.fichero.url,
        tables.fichero.data,
        tables.fichero.type
      )
      .from(tables.fichero)
      .innerJoin(
        tables.tfichero,
        tables.fichero.idtfichero.eq(tables.tfichero.idtfichero)
      )
      .where(
        this.db.op.and(
          tables.fichero.idtabla.eq(idtabla),
          tables.fichero.id.eq(id)
        )
      )
      .exec()
  }
  selectFicheroTablaYNombre (idtabla, id, nombreFichero) {
    const tables = this.db.tables
    return this.db
      .select(
        tables.fichero.idfichero,
        tables.fichero.nombre_fichero,
        tables.tfichero.descripcion.as('descripcion_tfichero'),
        tables.fichero.url
      )
      .from(tables.fichero)
      .innerJoin(
        tables.tfichero,
        tables.fichero.idtfichero.eq(tables.tfichero.idtfichero)
      )
      .where(
        this.db.op.and(
          tables.fichero.idtabla.eq(idtabla),
          tables.fichero.id.eq(id),
          tables.fichero.nombre_fichero.eq(nombreFichero)
        )
      )
      .exec()
  }
  rowFichero (idfichero) {
    const tables = this.db.tables
    return this.db
      .select()
      .from(tables.fichero)
      .innerJoin(
        tables.tfichero,
        tables.fichero.idtfichero.eq(tables.tfichero.idtfichero)
      )
      .where(tables.fichero.idfichero.eq(idfichero))
      .exec()
  }
  selectCarpetasDisponibles(idtabla, id) {
    const tables = this.db.tables
    return this.db
      .select(
        this.db.fn.distinct(tables.fichero.carpeta).as('carpeta')
      )
      .from(tables.fichero)
      .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
      .where(
        this.db.op.and(
          tables.fichero.idtabla.eq(idtabla),
          tables.fichero.id.eq(id),
          tables.fichero.carpeta.isNotNull()
        )
      )
      .exec()
  }
}
