import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

// misma estructura que estudiosFinancieros, cuentasBancarias, etc.
export default [
  // presupuestos
  {
    path: 'presupuestos/:idpresupuestocli',
    name: 'documentacionenviar__presupuestos-documento-list',
    component: load('documentacionEnviar/documentoList/DocumentoList'),
    props: {
      viewRoute: 'documentacionenviar__presupuestos-documento-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliDocumento.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'presupuestos/:idpresupuestocli/:iddocumento',
    name: 'documentacionenviar__presupuestos-documento-view',
    component: load('documentacionEnviar/documentoView/DocumentoView'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliDocumento.id,
        access: ACCESS.view,
      },
    },
  },
]
