/**
 https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier
 */
export default {
  props: {
    value: {},
  },
  data () {
    return {
      internalValue: this.value
    }
  },
  watch: {
    internalValue (newInternalValue) {
      this.$emit('update:value', newInternalValue)
    },
    value (newValue) {
      this.internalValue = newValue
    }
  },
}
