import { load } from '@/router/utils'

export default [
  {
    path: '',
    name: 'sincronizacion__sincronizacion',
    component: load('sincronizacion/sincronizacion/Sincronizacion'),
  },
  {
    path: 'sync-uploads',
    name: 'sincronizacion__sync-upload-list',
    component: load('sincronizacion/syncUploadList/SyncUploadList'),
  },
  {
    path: 'sync-uploads/:idsync_upload',
    name: 'sincronizacion__sync-upload-view',
    component: load('sincronizacion/syncUploadView/SyncUploadView'),
  },
]
