import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      codigo: {
        label: 'Código',
        type: 'string',
        value: null,
        field: 'codigo',
      },
      descripcion: {
        label: 'Descripción',
        type: 'string',
        value: null,
        field: 'descripcion',
      },
      frecuente: {
        label: 'Ver solo frecuentes',
        type: 'check',
        value: null,
        field: 'frecuente',
      },
      stockMiAlmacen: {
        label: 'Ver solo con stock en mi almacén',
        type: 'check',
        value: null,
        field: 'stock_mi_almacen',
      },
      marca: {
        label: 'Marca',
        type: 'select',
        value: null,
        items: [],
        field: 'idmarca',
        selectItemValue: 'marca.idmarca',
        selectItemText: 'marca.descripcion',
      },
      subfamilia: {
        label: 'Subfamilia',
        type: 'select',
        value: null,
        items: [],
        field: 'idsubfamilia',
        selectItemValue: 'subfamilia.idsubfamilia',
        selectItemText: 'subfamilia.descripcion',
      },
    },
    mostrarConImpuestos: true,
  },
  getters: {},
  mutations: {
    RESET_STORE_CUSTOM: (state) => {
      state.filter.marca.items = [],
      state.filter.subfamilia.items = []
    },
  },
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
