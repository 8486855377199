<template>
  <v-app>
    <v-system-bar
      v-if="false"
      app
      color="warning"
      dark
    >
      <v-icon>{{ $vuetify.icons.values.debug }}</v-icon> Modo depuración activado
    </v-system-bar>
    <v-system-bar
      v-if="!networkOnline || offlineURL"
      app
      color="error"
      dark
    >
      <v-icon>{{ $vuetify.icons.values.offline }}</v-icon>
      <span
        v-show="!networkOnline"
      >No tienes conexión</span>
      <span
        v-show="networkOnline && offlineURL"
      >Apartado sin conexión</span>
    </v-system-bar>
    <b10-loading
      :showing="loading.visible"
      :message="loading.message"
      :disable-u-i="loading.disableUI"
    />
    <!--
      Al navegar a la misma ruta con diferentes parámetros no reutilice el componente
      (LparteTrabajoMaterialAfectadoView siguiente/anterior)
      https://github.com/vuejs/vue-router/issues/1490#issuecomment-306029174
    -->
    <router-view
      :key="$route.fullPath"
    />
    <v-snackbar
      v-model="updateExists"
      multi-line
      color="info"
      :timeout="-1"
    >
      Hay una nueva versión disponible
      <v-spacer />
      <v-btn
        dark
        text
        @click.stop="refreshApp"
      >
        Actualizar ahora
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { call, get } from 'vuex-pathify'

export default {
  name: 'App',
  data () {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  computed: {
    loading: get('loading'),
    networkOnline: get('network/online'),
    offlineURL () {
      return this.$route.name && this.$route.name.startsWith('offline__')
    },
  },
  created () {
    // once -> allows the listener to be called only once AND removes the listener once invoked
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    // para prevenir múltiples refresh
    navigator.serviceWorker.addEventListener('controllerchange', this.pageReload)
  },
  methods: {
    ...call('usuario', {
      usuarioCerrarSesion: 'cerrarSesion',
    }),
    updateAvailable (event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false
      // enviar un mensaje 'skip waiting' solo si el service worker esta esperando
      if (this.registration && this.registration.waiting) {
        // enviar el mensaje al service worker para que deje de esperar y active el nuevo service worker
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    },
    pageReload () {
      if (!this.refreshing) {
        this.refreshing = true
        this.$loading.showManual('Actualizando a la nueva versión...')
        window.location.reload()
      }
    },
  }
}
</script>
