<template>
  <div>
    <v-subheader
      class="pl-0 font-weight-medium"
    >
      <v-icon
        small
        class="mr-2"
      >
        {{ icon }}
      </v-icon>
      {{ label }}
    </v-subheader>
    <v-divider
      class="mb-3"
    />
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'info',
    },
  },
}
</script>
