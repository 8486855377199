import camelCase from 'lodash.camelcase'
import chunk from 'lodash.chunk'
import cloneDeep from 'lodash.clonedeep'
import countBy from 'lodash.countby'
import filter from 'lodash.filter'
import find from 'lodash.find'
import findIndex from 'lodash.findindex'
import has from 'lodash.has'
import isEmpty from 'lodash.isempty'
import map from 'lodash.map'
import pickBy from 'lodash.pickby'
import pullAllWith from 'lodash.pullallwith'
import snakeCase from 'lodash.snakecase'
import sortBy from 'lodash.sortby'
import upperFirst from 'lodash.upperfirst'
import remove from 'lodash.remove'
import sumBy from 'lodash.sumby'
import intersection from 'lodash.intersection'
import uniqBy from 'lodash.uniqby'
import deburr from 'lodash.deburr'
import concat from 'lodash.concat'
import mapvalues from 'lodash.mapvalues'
import unionBy from 'lodash.unionby'
import merge from 'lodash.merge'

export default {
  camelCase () {
    return camelCase.apply(null, arguments)
  },
  chunk () {
    return chunk.apply(null, arguments)
  },
  cloneDeep () {
    return cloneDeep.apply(null, arguments)
  },
  countBy () {
    return countBy.apply(null, arguments)
  },
  filter () {
    return filter.apply(null, arguments)
  },
  find () {
    return find.apply(null, arguments)
  },
  findIndex () {
    return findIndex.apply(null, arguments)
  },
  has () {
    return has.apply(null, arguments)
  },
  isEmpty () {
    return isEmpty.apply(null, arguments)
  },
  map () {
    return map.apply(null, arguments)
  },
  pickBy () {
    return pickBy.apply(null, arguments)
  },
  pullAllWith () {
    return pullAllWith.apply(null, arguments)
  },
  sortBy () {
    return sortBy.apply(null, arguments)
  },
  snakeCase () {
    return snakeCase.apply(null, arguments)
  },
  upperFirst () {
    return upperFirst.apply(null, arguments)
  },
  remove () {
    return remove.apply(null, arguments)
  },
  sumBy () {
    return sumBy.apply(null, arguments)
  },
  intersection () {
    return intersection.apply(null, arguments)
  },
  uniqBy () {
    return uniqBy.apply(null, arguments)
  },
  deburr () {
    return deburr.apply(null, arguments)
  },
  resolveProperty (path, object) {
    return path.split('.').reduce(
      (prev, curr) => {
        return prev ? prev[curr] : null
      }, object || self
    )
  },
  concat () {
    return concat.apply(null, arguments)
  },
  mapvalues () {
    return mapvalues.apply(null, arguments)
  },
  unionBy () {
    return unionBy.apply(null, arguments)
  },
  merge () {
    return merge.apply(null, arguments)
  },
}
