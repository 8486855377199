import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'

export default class InformecolageneracionOnlineResource extends BaseOnlineResource {
  async enviarEmail (id, destinatarios, asunto, body, idobjeto) {
    const listaDestinatarios = destinatarios.join(';')
    await this.Vue.$api.call('informecolageneracion.enviarEmail', {
      id,
      destinatarios: listaDestinatarios,
      asunto,
      body: filters.linebreaksBr(body),
      idobjeto
    })
  }
}
