<template>
  <div
    :class="{ 'px-2': padding, 'pt-1': padding }"
  >
    <v-card
      flat
    >
      <v-list-item
        two-line
      >
        <v-list-item-content>
          <v-list-item-title
            v-if="item.title"
          >
            <slot
              name="title"
            >
              <span
                class="break-word"
                v-html="item.title"
              />
            </slot>
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="formattedSubtitle"
          >
            <slot
              name="subtitle"
            >
              <span
                class="break-word"
                v-html="formattedSubtitle"
              />
            </slot>
          </v-list-item-subtitle>
        </v-list-item-content>
        <b10-list-item-right-avatar
          v-if="visibleBadge"
          :color="item.badgeColor"
        >
          <slot
            name="subtitle"
          >
            {{ item.badge }}
          </slot>
        </b10-list-item-right-avatar>
      </v-list-item>
      <v-card-subtitle
        v-show="!loadingData"
        class="py-1 px-2"
      >
        <template
          v-if="$slots.extraSubtitle"
        >
          <slot name="extraSubtitle" />
        </template>
      </v-card-subtitle>
      <!-- más información sin dialog -->
      <v-card-text
        v-if="item.moreInfo && !moreInfoInDialog"
        class="px-0"
      >
        <v-divider />
        <v-list
          two-line
        >
          <template
            v-for="(group, key) of formattedMoreInfo"
          >
            <div
              :key="key"
            >
              <v-subheader
                v-if="group.title"
                v-show="showEmptyValues || group.hasRowsWithValue"
              >
                {{ group.title }}
              </v-subheader>
              <v-list-item
                v-for="row in group.rows"
                v-show="showEmptyValues || row.hasValue"
                :key="row.name"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ row.label }}</v-list-item-title>
                  <v-list-item-subtitle
                    v-if="!row.hasValue"
                  >
                    -
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-else
                  >
                    <span
                      v-if="row.password"
                    >
                      {{ ''.padStart(row.value.length, '*') }}
                    </span>
                    <span
                      v-else
                      class="text-wrap"
                      v-html="row.value"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action
                  v-if="row.clickable"
                >
                  <v-btn
                    icon
                    @click.stop="clickValue(row)"
                  >
                    <v-icon>{{ row.icon }}</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </div>
          </template>
        </v-list>
        <v-btn
          text
          color="primary"
          small
          block
          @click="showEmptyValues = !showEmptyValues"
        >
          <v-icon
            left
          >
            {{ iconEmptyValues }}
          </v-icon>
          <span
            v-if="showEmptyValues"
          >
            Ocultar campos sin datos
          </span>
          <span
            v-else
          >
            Ver campos sin datos
          </span>
        </v-btn>
      </v-card-text>
      <!-- más información activator -->
      <v-card-actions
        v-if="item.moreInfo && moreInfoInDialog"
      >
        <slot name="cardActionsLeft" />
        <v-spacer />
        <slot name="cardActionsRight" />
        <v-btn
          text
          color="primary"
          @click.stop="clickMoreInfo"
        >
          Más información...
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- más información dialog -->
    <v-dialog
      v-if="moreInfoInDialog"
      v-model="internalShowing"
    >
      <v-card>
        <v-card-text
          class="pt-2"
        >
          <div
            v-for="(group, key, index) of formattedMoreInfo"
            :key="key"
          >
            <h3
              v-if="group.title"
              v-show="showEmptyValues || group.hasRowsWithValue"
              class="title text-truncate mt-3 mb-2"
            >
              {{ group.title }}
            </h3>
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr
                    v-for="row in group.rows"
                    v-show="showEmptyValues || row.hasValue"
                    :key="row.name"
                  >
                    <td><strong>{{ row.label }}</strong></td>
                    <td
                      v-if="!row.hasValue"
                    >
                      -
                    </td>
                    <td
                      v-else
                    >
                      <span
                        v-if="row.password"
                      >
                        {{ ''.padStart(row.value.length, '*') }}
                      </span>
                      <span
                        v-else
                        v-html="row.value"
                      />
                      <v-btn
                        v-if="row.clickable"
                        class="ml-1 float-right"
                        x-small
                        text
                        @click.stop="clickValue(row)"
                      >
                        <v-icon>
                          {{ row.icon }}
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr
                    v-if="index === 0"
                  >
                    <td><strong>ID</strong></td>
                    <td>{{ item.id }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            small
            @click="showEmptyValues = !showEmptyValues"
          >
            <v-icon
              left
            >
              {{ iconEmptyValues }}
            </v-icon>
            <span
              v-if="showEmptyValues"
            >
              Ocultar campos sin datos
            </span>
            <span
              v-else
            >
              Ver campos sin datos
            </span>
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click.stop="clickCloseMoreInfo"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import _ from '@/utils/lodash'

export default {
  mixins: [internalShowingMixin],
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      required: false,
      default: true,
    },
    showAlerts: {
      type: Boolean,
      default: true,
    },
    moreInfoInDialog: {
      type: Boolean,
      default: true,
    }
  },
  data () {
    return {
      showEmptyValues: false,
      displayedAlerts: [],
    }
  },
  computed: {
    formattedSubtitle () {
      if (Array.isArray(this.item.subtitle)) {
        return this.item.subtitle.join('<br/>')
      } else {
        return this.item.subtitle
      }
    },
    formattedMoreInfo () {
      const moreInfo = _.cloneDeep(this.item.moreInfo)
      if (Array.isArray(moreInfo)) {
        const formattedRows = this.formatRows(moreInfo)
        return {
          main: {
            title: '',
            rows: formattedRows,
            hasRowsWithValue: (_.filter(formattedRows, 'hasValue')).length > 0
          }
        }
      } else {
        for (const group in moreInfo) {
          const formattedRows = this.formatRows(moreInfo[group].rows)
          moreInfo[group].rows = formattedRows
          moreInfo[group].hasRowsWithValue = (_.filter(formattedRows, 'hasValue')).length > 0
        }
        return moreInfo
      }
    },
    visibleBadge () {
      return !!this.item.badge
    },
    iconEmptyValues () {
      if (this.showEmptyValues) {
        return this.$vuetify.icons.values.hide
      } else {
        return this.$vuetify.icons.values.show
      }
    },
  },
  watch: {
    item: {
      handler: function (newValue) {
        if (this.showAlerts && newValue.alerts) {
          for (const alert of newValue.alerts) {
            if (this.displayedAlerts.indexOf(alert.value) === -1) {
              this.$alert.showSnackbar(alert.value, alert.type, alert.options)
              this.displayedAlerts.push(alert.value)
            }
          }
        }
      },
      deep: true
    },
  },
  methods: {
    clickMoreInfo () {
      this.internalShowing = true
    },
    formatRows (rows) {
      for (const row of rows) {
        let value = null
        if ('value' in row) {
          value = row.value
        } else {
          value = _.resolveProperty(row.name, this.item)
        }
        if (row.filter) {
          row.value = row.filter(value)
        } else {
          row.value = value
        }
        row.hasValue = row.value !== null && row.value !== '' && typeof(row.value) !== 'undefined'
      }
      return _.filter(rows, 'visible')
    },
    clickValue (row) {
      this.$emit('click-row', row)
    },
    clickCloseMoreInfo () {
      this.internalShowing = false
    }
  }
}
</script>

<style scoped>
.break-word {
  word-break: break-word;
  white-space: normal;
}
</style>
