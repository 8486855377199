import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

// misma estructura que documentacionEnviar, sobres, cuentasBancarias, etc.
export default [
  // presupuestos
  {
    path: 'presupuestos/:id/:idtabla',
    name: 'sobres__presupuestos-sobre-list',
    component: load('sobres/sobreList/SobreList'),
    props: {
      viewRoute: 'sobres__presupuestos-sobre-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliSobre.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'presupuestos/:id/:idtabla/:idsobre/enviar',
    name: 'sobres__presupuestos-sobre-enviar',
    component: load('sobres/sobreEnviar/SobreEnviar'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliSobre.id,
        access: ACCESS.view,
      },
    },
    props: {
      viewRoute: 'sobres__presupuestos-sobre-view',
    },
  },
  {
    path: 'presupuestos/:id/:idtabla/:idsobre',
    name: 'sobres__presupuestos-sobre-view',
    component: load('sobres/sobreView/SobreView'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliSobre.id,
        access: ACCESS.view,
      },
    },
  },
  // presupuestos - destinatarios
  {
    path: 'presupuestos/:id/:idtabla/:idsobre/destinatarios',
    name: 'sobres__destinatarios__sobre-destinatario-list',
    component: load('sobres/destinatarios/sobreDestinatarioList/SobreDestinatarioList'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliSobre.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'sobres__destinatarios__sobre-destinatario-add',
      editRoute: 'sobres__destinatarios__sobre-destinatario-edit',
    },
  },
  {
    path: 'presupuestos/:id/:idtabla/:idsobre/destinatariosFaltan',
    name: 'sobres__destinatarios__sobre-destinatario-faltan-list',
    component: load('sobres/destinatarios/sobreDestinatarioFaltanList/SobreDestinatarioFaltanList'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliSobre.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'presupuestos/:id/:idtabla/:idsobre/destinatarios/add',
    name: 'sobres__destinatarios__sobre-destinatario-add',
    component: load('sobres/destinatarios/sobreDestinatarioAdd/SobreDestinatarioAdd'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliSobre.id,
        access: ACCESS.add,
      },
    },
  },
  {
    path: 'presupuestos/:id/:idtabla/:idsobre/destinatarios/:idsobre_destinatario',
    name: 'sobres__destinatarios__sobre-destinatario-edit',
    component: load('sobres/destinatarios/sobreDestinatarioEdit/SobreDestinatarioEdit'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliSobre.id,
        access: ACCESS.edit,
      },
    },
  },
  // presupuestos - documentos
  {
    path: 'presupuestos/:id/:idtabla/:idsobre/documentos',
    name: 'sobres__documentos__sobre-documento-list',
    component: load('sobres/documentos/sobreDocumentoList/SobreDocumentoList'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliSobre.id,
        access: ACCESS.view,
      },
    },
  },
  // presupuestos - envios
  {
    path: 'presupuestos/:id/:idtabla/:idsobre/envios',
    name: 'sobres__envios__sobre-envio-list',
    component: load('sobres/envios/sobreEnvioList/SobreEnvioList'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliSobre.id,
        access: ACCESS.view,
      },
    },
  },
]
