import { encodeExtraQuery } from '@/utils/router'

export const beforeSend = async (Vue, store, event, hint) => {
  if (
    hint.originalException.message.match('NavigationDuplicated') ||
    hint.originalException.message.match('APISilentError')
  ) {
    return null
  } else if (hint.originalException.message.match('Network Error')) {
    Vue.$alert.showSnackbarError(hint.originalException.message)
    return null
  } else {
    if (Vue.$alert) {
      if (hint.originalException.message.match('APIVersionError')) {
        Vue.$alert.showDialogError(
          `La aplicación se encuentra desactualizada.
          Recarga la página para descargar la nueva versión disponible
          (versión del dispositivo ${__BETA10_VERSION__} / versión del servidor ${hint.originalException.version})`
        )
        return null
      } else if (hint.originalException.name.match('APIApplicationError')) {
        // error application error de Oracle (20001)
        Vue.$alert.showSnackbarError(hint.originalException.message)
        return null
      } else if (hint.originalException.message.match('APISessionTerminatedError')) {
        // error 403 -> sesión terminada / token eliminado
        Vue.$alert.showSnackbarError('La sesión se ha invalidado desde el servidor')
        await store.dispatch('usuario/cerrarSesion')
        Vue.$appRouter.replace({ name: 'login' })
      } else if (hint.originalException.name === 'ItemNotFoundError') {
        if (hint.originalException.extra) {
          Vue.$appRouter.replace({
            name: '404',
            query: {
              extra: encodeExtraQuery(hint.originalException.extra)
            }
          })
        } else {
          Vue.$appRouter.replace({ name: '404' })
        }
        return null
      } else if (hint.originalException.name.match('SyncError')) {
        return event
      } else {
        // error 500
        Vue.$alert.showDialogError(hint.originalException.message, `Error (${__BETA10_VERSION__})`)
        if (process.env.NODE_ENV === 'production') {
          return event
        } else {
          return null
        }
      }
    } else {
      return null
    }
  }
}
