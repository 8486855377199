import { PARAMETRO } from '@/utils/consts'
import { Loader } from '@googlemaps/js-api-loader';

export const markerIcons = {
  agenteComercial: 'agente_comercial.png',
  cliente: 'cliente.png',
  clientePotencial: 'cliente_potencial.png',
  facturacli: 'facturacli.png',
  operacionCRM: 'operacion_crm.png',
  ordenTrabajo: 'orden_trabajo.png',
  otros: 'otros.png',
  parteTrabajo: 'parte_trabajo.png',
  presupuestocli: 'presupuestocli.png',
  prospecto: 'prospecto.png',
  sistema: 'sistema.png',
}

export const getLatLongURL = (lat, lng) => {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
}

export const getDirectionsURL = (lat, lng) => {
  return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&travelmode=driving`
}

export class GeolocationRejectedError extends Error {
  constructor() {
    super('GeolocationRejectedError')
    this.name = 'GeolocationRejectedError'
  }
}

export class NoBrowserSupportError extends Error {
  constructor() {
    super('NoBrowserSupportError')
    this.name = 'NoBrowserSupportError'
  }
}

export const getGeolocation = (options = {}) => {
  return new Promise((resolve, reject) => {
    if ('geolocation' in window.navigator) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            altitude: position.coords.altitude,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            accuracy: position.coords.accuracy
          })
        },
        () => {
          reject(new GeolocationRejectedError())
        },
        options
      )
    } else {
      reject(new NoBrowserSupportError())
    }
  })
}

export const initGoogleMaps = async (Vue) => {
  if (!window.google || !window.google.maps || !window.google.maps.version) {
    const apiKey = await Vue.$online.parametro.valorParametro(
      PARAMETRO.parametros.GOOGLE_MAPS_API_KEY
    )
    const loader = new Loader({ apiKey, libraries: [] })
    await loader.load()
  }
}

export const captureGeolocation = async (Vue) => {
  try {
    const geolocation = await getGeolocation()
    if (!geolocation) {
      Vue.$alert.showSnackbarError('Es necesario activar la geolocalización del dispositivo')
    } else {
      return { lat: geolocation.lat, lng: geolocation.lng }
    }
  } catch (e) {
    if (e instanceof GeolocationRejectedError || e instanceof NoBrowserSupportError) {
      Vue.$alert.showSnackbarError('No se puede obtener la geolocalización del dispositivo. Asegúrate que has permitido el acceso a tu localización')
    } else {
      throw(e)
    }
  }
}
