/**
 * Mecanismo showing / internalShowing:
 https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier
 */
export default {
  props: {
    showing: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data () {
    return {
      internalShowing: false
    }
  },
  watch: {
    internalShowing (newInternalShowing) {
      this.$emit('update:showing', newInternalShowing)
    },
    showing (newShowing) {
      this.internalShowing = newShowing
    }
  }
}
