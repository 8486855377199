import BaseOnlineResource from '@/online/base'
import { TDATO_TDATO_SISTEMA } from '@/utils/consts'
import { isValidDate } from '@/utils/date'
import filters from '@/utils/filters'

export default class DatoSistemaOnlineResource extends BaseOnlineResource {
  subtitle (row) {
    if (row.valor) {
      if (row.tipo === TDATO_TDATO_SISTEMA.tipos.decimal) {
        return filters.decimal(row.valor)
      } else if (row.tipo === TDATO_TDATO_SISTEMA.tipos.fecha) {
        if (isValidDate(row.valor)) {
          return filters.shortDate(row.valor)
        } else {
          return row.valor
        }
      } else {
        return row.valor
      }
    }
  }
}