import { v4 as uuidv4 } from 'uuid'
import { GRADO_ANOMALIA } from '@/utils/consts'
import _ from '@/utils/lodash'
import BaseOfflineResource from '@/offline/base'

export default class ParteTrabajoMatsistOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'parteTrabajoMatsist.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  async deleteSync (idparteTrabajoMatsist) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'parteTrabajoMatsist.deleteSync',
      params: {
        idparte_trabajo_matsist: idparteTrabajoMatsist
      }
    })
    await this.Vue.$offline.parteTrabajoMatsist.delete({
      where: tables.parte_trabajo_matsist.idparte_trabajo_matsist.eq(idparteTrabajoMatsist)
    })
  }
  async insertMaterialParteOtYSistema (idarticulo, unidades, idparteTrabajo, idsubsis) {
    const parteTrabajo = await this.Vue.$offline.parteTrabajo.row(idparteTrabajo)
    const ordenTrabajo = await this.Vue.$offline.ordenTrabajo.row(parteTrabajo.parte_trabajo.idorden_trabajo)
    const idmaterialSistema = await this.Vue.$offline.materialSistema.insertarMaterialSistema(
      idarticulo=idarticulo,
      unidades=unidades,
      idsubsis=idsubsis
    )
    const idordenTrabajoMatsist = uuidv4()
    await this.Vue.$offline.ordenTrabajoMatsist.insertSync({
      idorden_trabajo_matsist: idordenTrabajoMatsist,
      estado: 1,
      idmaterial_sistema: idmaterialSistema,
      idorden_trabajo: ordenTrabajo.orden_trabajo.idorden_trabajo,
    })
    const idparteTrabajoMatsist = uuidv4()
    await this.Vue.$offline.parteTrabajoMatsist.insertSync({
      idparte_trabajo_matsist: idparteTrabajoMatsist,
      idparte_trabajo: idparteTrabajo,
      idorden_trabajo_matsist: idordenTrabajoMatsist,
      estado: 1,
    })
    return {
      idmaterialSistema,
      idordenTrabajoMatsist,
      idparteTrabajoMatsist,
    }
  }
  selectSubsisAfectados (idparteTrabajo) {
    const tables = this.db.tables
    return this.db
      .select(
        this.db.fn.distinct(tables.material_sistema.idsubsis).as('idsubsis')
      )
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .where(
        this.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo),
          tables.orden_trabajo_matsist.estado.gt(0)
        )
      )
      .exec()
  }
  async buscarMaterialPorCodigo (idparteTrabajo, q, idsubsis, idgrupoChecklistOt) {
    const tables = this.db.tables
    const query = this.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
    const whereFilter = []
    if (idsubsis) {
      whereFilter.push(
        tables.material_sistema.idsubsis.eq(idsubsis),
      )
    }
    if (idgrupoChecklistOt) {
      const materialesGrupo = await this.db
        .select(
          this.db.fn.distinct(
            tables.parte_trabajo_matsist.idparte_trabajo_matsist
          ).as('idparte_trabajo_matsist')
        )
        .from(tables.parte_trabajo_matsist)
        .innerJoin(
          tables.pregunta_checklist_ot,
          tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.pregunta_checklist_ot.idorden_trabajo_matsist)
        )
        .where(
          this.db.op.and(
            tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
          )
        )
        .exec()
      whereFilter.push(
        tables.parte_trabajo_matsist.idparte_trabajo_matsist.in(
          _.map(materialesGrupo, 'idparte_trabajo_matsist')
        )
      )
    }
    return await query
      .where(
        this.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo),
          tables.orden_trabajo_matsist.estado.gt(0),
          this.db.op.or(
            tables.material_sistema.nelemento.eq(q),
            tables.material_sistema.nserie.eq(q),
            tables.material_sistema.netiqueta.eq(q)
          ),
          ...whereFilter,
        )
      )
      .exec()
  }
  async selectGrupoChecklistOt (idparteTrabajoMatsist) {
    const tables = this.db.tables
    return (await this.db
      .select(
        this.db.fn.max(tables.grupo_checklist_ot.idgrupo_checklist_ot).as('idgrupo_checklist_ot'),
        this.db.fn.max(tables.grupo_checklist_ot.idchecklist_ot).as('idchecklist_ot')
      )
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.pregunta_checklist_ot.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
      )
      .where(
        this.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo_matsist.eq(idparteTrabajoMatsist),
          tables.checklist_ot.estado.gt(0),
        )
      )
      .exec()
    )[0]
  }
  async selectChecklistOt (idparteTrabajoMatsist) {
    const tables = this.db.tables
    return (await this.db
      .select(
        this.db.fn.max(tables.grupo_checklist_ot.idchecklist_ot).as('idchecklist_ot')
      )
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.pregunta_checklist_ot.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
      )
      .where(
        this.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo_matsist.eq(idparteTrabajoMatsist),
          tables.checklist_ot.estado.gt(0),
        )
      )
      .exec()
    )[0]
  }
  async selectParteTrabajoMatsistOrdenTrabajoMatsist(idordenTrabajoMatsist) {
    const tables = this.db.tables
    return (await this.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(this.dummyTable, this.pk.eq(this.dummyPk))
      .where(
        this.db.op.and(
          tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.parte_trabajo_matsist.estado.gt(0)
        )
      )
      .exec())[0]
  }
  async selectChecklistCount (idparteTrabajoMatsist, idchecklistOt) {
    const tables = this.db.tables
    const rows = await this.db
      .select(
        tables.grado_anomalia.clasificacion,
        this.db.fn.count(
          tables.pregunta_checklist_ot.idpregunta_checklist_ot
        ).as('cant_preguntas')
      )
      .from(tables.checklist_ot)
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.checklist_ot.idchecklist_ot.eq(tables.grupo_checklist_ot.idchecklist_ot)
      )
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(tables.pregunta_checklist_ot.idgrupo_checklist_ot)
      )
      .leftOuterJoin(
        tables.grado_anomalia,
        tables.pregunta_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
      )
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.parte_trabajo_matsist,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.parte_trabajo_matsist.idorden_trabajo_matsist)
      )
      .where(
        this.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo_matsist.eq(idparteTrabajoMatsist),
          tables.checklist_ot.idchecklist_ot.eq(idchecklistOt),
        )
      )
      .groupBy(
        tables.grado_anomalia.clasificacion,
      )
      .exec()
    let totalPreguntas = 0
    let totalPreguntasContestadas = 0
    let totalPreguntasConAnomalias = 0
    for (const row of rows) {
      totalPreguntas += row.cant_preguntas
      if (row.grado_anomalia && row.grado_anomalia.clasificacion !== null) {
        totalPreguntasContestadas += row.cant_preguntas
        if (
          row.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.incorrecto ||
          row.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.desconocido
        ) {
          totalPreguntasConAnomalias += row.cant_preguntas
        }
      }
    }
    return {
      totalPreguntas,
      totalPreguntasContestadas,
      totalPreguntasConAnomalias,
    }
  }
}
