import store from '@/store'

export default {
  install: (Vue) => {
    Vue.$loading = Vue.prototype.$loading = {
      async show (options = {}) {
        const defaults = {
          disableUI: true,
          manualLoading: false,
        }
        let finalOptions = Object.assign({}, defaults, options)
        await store.dispatch('loading/show', {
          disableUI: finalOptions.disableUI,
          manualLoading: finalOptions.manualLoading,
        })
      },
      async showManual (message) {
        await store.dispatch('loading/show', {
          disableUI: true,
          manualLoading: true,
          message: message,
        })
      },
      async hide () {
        await store.dispatch('loading/hide')
      },
      setMessage (message) {
        store.set('loading/message', message)
      }
    }
  }
}
