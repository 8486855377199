import BaseOnlineResource from '@/online/base'

export default class LplantillaMantOnlineResource extends BaseOnlineResource {
  title (row) {
    return `${row.tactuacion_desc} ${row.periodo_revision_desc} - ${row.descripcion}`
  }
  subtitle (row) {
    return `${row.periodo_revision_desc} - ${row.descripcion}`
  }
}
