import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'
import { ORDEN_TRABAJO } from '@/utils/consts'

export default [
  // sistemas
  {
    path: '',
    name: 'sistemas__sistema-list',
    component: load('sistemas/sistemaList/SistemaList'),
    meta: {
      permission: {
        idobjeto: permissions.sistema.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idsistema',
    name: 'sistemas__sistema-view',
    component: load('sistemas/sistemaView/SistemaView'),
  },
  // ordenes
  {
    path: ':idsistema/ordenes',
    name: 'sistemas__ordenes-list',
    component: load('ordenes/ordenTrabajoList/OrdenTrabajoList'),
    props: {
      estado: [ORDEN_TRABAJO.estados.finalizada, ORDEN_TRABAJO.estados.pendiente],
      soloMisOT: false,
    },
  },
  // dato_sistema
  {
    path: ':idsistema/datos',
    name: 'sistemas__dato-sistema-list',
    component: load('sistemas/datoSistemaList/DatoSistemaList'),
    props: {
      editRoute: 'sistemas__dato-sistema-edit',
      addRoute: 'sistemas__dato-sistema-add'
    },
    meta: {
      permission: {
        idobjeto: permissions.sistemaDato.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idsistema/datos/add',
    name: 'sistemas__dato-sistema-add',
    component: load('sistemas/datoSistemaAdd/DatoSistemaAdd'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaDato.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: ':idsistema/datos/:iddato_sistema/edit',
    name: 'sistemas__dato-sistema-edit',
    component: load('sistemas/datoSistemaEdit/DatoSistemaEdit'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaDato.id,
        access: ACCESS.edit,
      },
    },
  },
  // revisiones
  {
    path: ':idsistema/revisiones',
    name: 'sistemas__sistema-mant-list',
    component: load('sistemas/sistemaMantList/SistemaMantList'),
  },
  // vías de comunicación
  {
    path: ':idsistema/vias-comunicacion',
    name: 'sistemas__sistema-tviacomunicacion-list',
    component: load('sistemas/sistemaTviacomunicacionList/SistemaTviacomunicacionList'),
  },
  {
    path: ':idsistema/vias-comunicacion/add',
    name: 'sistemas__sistema-tviacomunicacion-add',
    component: load('sistemas/sistemaTviacomunicacionAdd/SistemaTviacomunicacionAdd'),
  },
  {
    path: ':idsistema/vias-comunicacion/:idsistema_tviacomunicacion',
    name: 'sistemas__sistema-tviacomunicacion-view',
    component: load('sistemas/sistemaTviacomunicacionView/SistemaTviacomunicacionView'),
  },
  {
    path: ':idsistema/vias-comunicacion/:idsistema_tviacomunicacion/edit',
    name: 'sistemas__sistema-tviacomunicacion-edit',
    component: load('sistemas/sistemaTviacomunicacionEdit/SistemaTviacomunicacionEdit'),
  },
  // sistema cuota
  {
    path: ':idsistema/cuotas',
    name: 'sistemas__sistema-cuota-list',
    component: load('sistemas/sistemaCuota/sistemaCuotaList/SistemaCuotaList'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaCuota.id,
        access: ACCESS.view,
      },
    },
  },
  // subsis
  {
    path: ':idsistema/subsis',
    name: 'sistemas__subsis-list',
    component: load('subsis/subsisList/SubsisList'),
    props: {
      viewRoute: 'sistemas__material-sistema-list',
    },
    meta: {
      permission: {
        idobjeto: permissions.materialSistema.id,
        access: ACCESS.view,
      },
    },
  },
  // Material por subsis
  {
    path: ':idsistema/subsis/:idsubsis',
    name: 'sistemas__material-sistema-list',
    component: load('materialesSistema/materialSistemaList/MaterialSistemaList'),
    props: {
      viewRoute: 'sistemas__material-sistema-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.materialSistema.id,
        access: ACCESS.view,
      },
    },
  },
  // Material de sistema
  {
    path: ':idsistema/subsis/:idsubsis/:idmaterial_sistema',
    name: 'sistemas__material-sistema-view',
    component: load('materialesSistema/materialSistemaView/MaterialSistemaView'),
    props: {
      editRoute: 'sistemas__material-sistema-edit',
    },
    meta: {
      permission: {
        idobjeto: permissions.materialSistema.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idsistema/subsis/:idsubsis/:idmaterial_sistema/edit',
    name: 'sistemas__material-sistema-edit',
    component: load('materialesSistema/materialSistemaEdit/MaterialSistemaEdit'),
    meta: {
      permission: {
        idobjeto: permissions.materialSistema.id,
        access: ACCESS.edit,
      },
    },
  },
  {
    path: ':idsistema/plano/:idfichero',
    name: 'sistemas__material-sistema-plano-view',
    component: load('materialesSistema/materialSistemaPlano/MaterialSistemaPlano'),
    props: {
      readOnly: true,
      editRoute: 'sistemas__material-sistema-plano-edit'
    },
  },
  {
    path: ':idsistema/plano/:idfichero/edit',
    name: 'sistemas__material-sistema-plano-edit',
    component: load('materialesSistema/materialSistemaPlano/MaterialSistemaPlano'),
    props: {
      readOnly: false,
    },
  },
]
