import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class UbiLpresupuestocliOnlineResource extends BaseOnlineResource {
  title (row) {
    return `${row.descripcion} (${row.codigo})`
  }
  subtitle (row) {
    return row.ubicacion
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('ubicacion', value)
      .addILike('tipo_ubicacion_descripcion', value)
  }
}
