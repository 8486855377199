import { ListStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    filter: {
      fdesde: {
        label: 'Fecha desde',
        type: 'date',
        value: null,
        field: 'fpresupuesto',
      },
      fhasta: {
        label: 'Fecha hasta',
        type: 'date',
        value: null,
        field: 'fpresupuesto',
      },
      tactuacion: {
        label: 'Tipo de actuación',
        type: 'select',
        value: null,
        items: [],
        field: 'idtactuacion',
        selectItemValue: 'idtactuacion',
        selectItemText: 'descripcion',
      },
      tsistema: {
        label: 'Tipo de sistema',
        type: 'select',
        value: null,
        items: [],
        field: 'idtsistema',
        selectItemValue: 'idtsistema',
        selectItemText: 'descripcion',
      },
      idcliente: {
        label: 'Nº de cliente',
        type: 'number',
        value: null,
        field: 'idcliente'
      },
      idclientePotencial: {
        label: 'Nº de cliente potencial',
        type: 'number',
        value: null,
        field: 'idcliente_potencial'
      },
    },
  },
  getters: {},
  mutations: {
    RESET_STORE_CUSTOM: (state) => {
      state.filter.tactuacion.items = []
      state.filter.tsistema.items = []
    },
  },
  actions: {},
}

export default CreateStore(new ListStoreBase(), pageStore)
