import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'
import { PRESUPUESTOCLI } from '@/utils/consts'

export default [
  // clientes
  {
    path: 'clientes/:idcliente',
    name: 'presupuestos__clientes-presupuestocli-list',
    component: load('presupuestos/presupuestocliList/PresupuestocliList'),
    props: {
      estados: [
        PRESUPUESTOCLI.estados.pendienteEstudioTecnico,
        PRESUPUESTOCLI.estados.enEstudioTecnico,
        PRESUPUESTOCLI.estados.pendienteRevisionComercial,
        PRESUPUESTOCLI.estados.pendienteAceptacion,
        PRESUPUESTOCLI.estados.aceptado,
        PRESUPUESTOCLI.estados.rechazado,
      ],
    },
    meta: {
      permission: {
        idobjeto: permissions.presupuestocli.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/add/:idcliente',
    name: 'presupuestos__clientes-presupuestocli-add',
    component: load('presupuestos/presupuestocliAdd/PresupuestocliAdd'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocli.id,
        access: ACCESS.insert,
      },
    },
  },
  // clientes potenciales
  {
    path: 'clientes-potenciales/:idcliente_potencial',
    name: 'presupuestos__clientes-potenciales-presupuestocli-list',
    component: load('presupuestos/presupuestocliList/PresupuestocliList'),
    props: {
      estados: [
        PRESUPUESTOCLI.estados.pendienteEstudioTecnico,
        PRESUPUESTOCLI.estados.enEstudioTecnico,
        PRESUPUESTOCLI.estados.pendienteRevisionComercial,
        PRESUPUESTOCLI.estados.pendienteAceptacion,
        PRESUPUESTOCLI.estados.aceptado,
        PRESUPUESTOCLI.estados.rechazado,
      ],
    },
    meta: {
      permission: {
        idobjeto: permissions.presupuestocli.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes-potenciales/add/:idcliente_potencial',
    name: 'presupuestos__clientes-potenciales-presupuestocli-add',
    component: load('presupuestos/presupuestocliAdd/PresupuestocliAdd'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocli.id,
        access: ACCESS.insert,
      },
    },
  },
  // presupuestos
  {
    path: 'pendientes',
    name: 'presupuestos__pendientes-presupuestocli-list',
    component: load('presupuestos/presupuestocliList/PresupuestocliList'),
    props: {
      estados: [
        PRESUPUESTOCLI.estados.pendienteEstudioTecnico,
        PRESUPUESTOCLI.estados.enEstudioTecnico,
        PRESUPUESTOCLI.estados.pendienteRevisionComercial,
        PRESUPUESTOCLI.estados.pendienteAceptacion,
      ],
    },
    meta: {
      permission: {
        idobjeto: permissions.presupuestocli.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'aceptados',
    name: 'presupuestos__aceptados-presupuestocli-list',
    component: load('presupuestos/presupuestocliList/PresupuestocliList'),
    props: {
      estados: [PRESUPUESTOCLI.estados.aceptado],
    },
    meta: {
      permission: {
        idobjeto: permissions.presupuestocli.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'rechazados',
    name: 'presupuestos__rechazados-presupuestocli-list',
    component: load('presupuestos/presupuestocliList/PresupuestocliList'),
    props: {
      estados: [PRESUPUESTOCLI.estados.rechazado],
    },
    meta: {
      permission: {
        idobjeto: permissions.presupuestocli.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpresupuestocli',
    name: 'presupuestos__presupuestocli-view',
    component: load('presupuestos/presupuestocliView/PresupuestocliView'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocli.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpresupuestocli/aceptar',
    name: 'presupuestos__presupuestocli-aceptar',
    component: load('presupuestos/presupuestocliAceptar/PresupuestocliAceptar'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocli.id,
        access: ACCESS.edit,
      },
    },
  },
  {
    path: ':idpresupuestocli/contratos/add',
    name: 'presupuestos__presupuestocli-contrato-add',
    component: load('presupuestos/presupuestocliContratoAdd/PresupuestocliContratoAdd'),
    meta: {
      permission: {
        idobjeto: permissions.contrato.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: ':idpresupuestocli/edit',
    name: 'presupuestos__presupuestocli-edit',
    component: load('presupuestos/presupuestocliEdit/PresupuestocliEdit'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocli.id,
        access: ACCESS.edit,
      },
    },
  },
  {
    path: ':idpresupuestocli/add-plantilla',
    name: 'presupuestos__plantilla-articulo-add',
    component: load('presupuestos/plantillaArticuloAdd/PlantillaArticuloAdd'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocli.id,
        access: ACCESS.edit,
      },
    },
  },
  // ubicaciones
  {
    path: ':idpresupuestocli/ubicaciones',
    name: 'presupuestos__ubicaciones__ubi-lpresupuestocli-list',
    component: load('presupuestos/ubicaciones/ubiLpresupuestocliList/UbiLpresupuestocliList'),
    meta: {
      permission: {
        idobjeto: permissions.lpresupuestocli.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpresupuestocli/ubicaciones/edit',
    name: 'presupuestos__ubicaciones__ubi-lpresupuestocli-edit',
    component: load('presupuestos/ubicaciones/ubiLpresupuestocliEdit/UbiLpresupuestocliEdit'),
    meta: {
      permission: {
        idobjeto: permissions.lpresupuestocli.id,
        access: ACCESS.edit,
      },
    },
  },
  // subsistemas
  {
    path: ':idpresupuestocli/subsistemas',
    name: 'presupuestos__subsistemas__presupuestocli-subsis-list',
    component: load('presupuestos/subsistemas/presupuestocliSubsisList/PresupuestocliSubsisList'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestocliSubsis.id,
        access: ACCESS.view,
      },
    },
  },

  // lineas
  {
    path: ':idpresupuestocli/lineas',
    name: 'presupuestos__lineas__lpresupuestocli-list',
    component: load('presupuestos/lineas/lpresupuestocliList/LpresupuestocliList'),
    meta: {
      permission: {
        idobjeto: permissions.lpresupuestocli.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpresupuestocli/lineas/add',
    name: 'presupuestos__lineas__lpresupuestocli-add',
    component: load('presupuestos/lineas/lpresupuestocliAdd/LpresupuestocliAdd'),
    meta: {
      permission: {
        idobjeto: permissions.lpresupuestocli.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: ':idpresupuestocli/lineas/:idlpresupuestocli',
    name: 'presupuestos__lineas__lpresupuestocli-view',
    component: load('presupuestos/lineas/lpresupuestocliView/LpresupuestocliView'),
    meta: {
      permission: {
        idobjeto: permissions.lpresupuestocli.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpresupuestocli/lineas/:idlpresupuestocli/edit',
    name: 'presupuestos__lineas__lpresupuestocli-edit',
    component: load('presupuestos/lineas/lpresupuestocliEdit/LpresupuestocliEdit'),
    meta: {
      permission: {
        idobjeto: permissions.lpresupuestocli.id,
        access: ACCESS.edit,
      },
    },
  },
  // cuotas
  {
    path: ':idpresupuestocli/cuotas',
    name: 'presupuestos__cuotas__presupuestocuota-list',
    component: load('presupuestos/cuotas/presupuestoCuotaList/PresupuestoCuotaList'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestoCuota.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpresupuestocli/cuotas/add',
    name: 'presupuestos__cuotas__presupuestocuota-add',
    component: load('presupuestos/cuotas/presupuestoCuotaAdd/PresupuestoCuotaAdd'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestoCuota.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: ':idpresupuestocli/cuotas/:idpresupuesto_cuota',
    name: 'presupuestos__cuotas__presupuestocuota-view',
    component: load('presupuestos/cuotas/presupuestoCuotaView/PresupuestoCuotaView'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestoCuota.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idpresupuestocli/cuotas/:idpresupuesto_cuota/edit',
    name: 'presupuestos__cuotas__presupuestocuota-edit',
    component: load('presupuestos/cuotas/presupuestoCuotaEdit/PresupuestoCuotaEdit'),
    meta: {
      permission: {
        idobjeto: permissions.presupuestoCuota.id,
        access: ACCESS.edit,
      },
    },
  },
  // revisiones
  {
    path: ':idpresupuestocli/revisiones',
    name: 'presupuestos__revisiones__presupuestocli-mant-list',
    component: load('presupuestos/revisiones/presupuestocliMantList/PresupuestocliMantList'),
    meta: {
      permission: {
        idobjeto: permissions.lpresupuestocli.id,
        access: ACCESS.view,
      },
    },
  },
]
