import { load } from '@/router/utils'

export default [
  {
    path: 'material-en-vehiculo',
    name: 'vehiculos__material-vehiculo-list',
    component: load('vehiculos/materialVehiculoList/MaterialVehiculoList'),
  },
  {
    path: 'stock',
    name: 'vehiculos__stock-list',
    component: load('vehiculos/stockList/StockList'),
  },
  {
    path: 'descargar',
    name: 'vehiculos__descargar-vehiculo',
    component: load('vehiculos/descargaVehiculo/DescargaVehiculo'),
  },
  {
    path: 'descargar-parte/:idparte_trabajo',
    name: 'vehiculos__parte-descargar-vehiculo',
    component: load('vehiculos/descargaVehiculo/DescargaVehiculo'),
  },
]
