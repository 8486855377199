import BaseOfflineResource from '@/offline/base'
import { TCLASIFICACION_SERVICIO_CONTRATADO } from '@/utils/consts'
import _ from '@/utils/lodash'

export default class ConceptoCuotaTservicioOfflineResource extends BaseOfflineResource {
  async selectConceptoCuotaTservicioMantenimiento () {
    const tables = this.db.tables
    return await this.db
      .select()
      .from(tables.concepto_cuota_tservicio)
      .innerJoin(
        tables.tservicio_contratado,
        tables.concepto_cuota_tservicio.idtservicio_contratado.eq(tables.tservicio_contratado.idtservicio_contratado))
      .where(
        this.db.op.and(
          tables.tservicio_contratado.idtclasificacion_servicio.eq(TCLASIFICACION_SERVICIO_CONTRATADO.clasificacion.mantenimiento),
          tables.tservicio_contratado.estado.gt(0),
          tables.concepto_cuota_tservicio.estado.gt(0),
        )
      )
      .exec()
  }
  async idConceptoCuotaDeMantenimiento () {
    const conceptoCuotaTservicio = _.map(
      await this.selectConceptoCuotaTservicioMantenimiento(),
      'concepto_cuota_tservicio'
    )
    return _.map(conceptoCuotaTservicio, 'idconcepto_cuota')
  }
}
