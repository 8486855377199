import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'
import { ORDEN_TRABAJO } from '@/utils/consts'

export default [
  // clientes
  {
    path: '',
    name: 'clientes__cliente-list',
    component: load('clientes/clienteList/ClienteList'),
    meta: {
      permission: {
        idobjeto: permissions.cliente.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'cartera',
    name: 'clientes__cliente-con-potenciales-list',
    component: load('clientes/clienteConPotencialesList/ClienteConPotencialesList'),
    meta: {
      permission: {
        idobjeto: permissions.cliente.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idcliente',
    name: 'clientes__cliente-view',
    component: load('clientes/clienteView/ClienteView'),
    meta: {
      permission: {
        idobjeto: permissions.cliente.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: ':idcliente/edit',
    name: 'clientes__cliente-edit',
    component: load('clientes/clienteEdit/ClienteEdit'),
    meta: {
      permission: {
        idobjeto: permissions.cliente.id,
        access: ACCESS.edit,
      },
    },
  },
  // ordenes
  {
    path: ':idcliente/ordenes',
    name: 'clientes__ordenes-list',
    component: load('ordenes/ordenTrabajoList/OrdenTrabajoList'),
    props: {
      estado: [ORDEN_TRABAJO.estados.finalizada, ORDEN_TRABAJO.estados.pendiente],
      soloMisOT: false,
    },
  },
]
