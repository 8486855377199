<script>
import { VAutocomplete } from 'vuetify/lib'
import _ from '@/utils/lodash'

export default {
  extends: VAutocomplete,
  props: {
    filter: {
      type: Function,
      default: (item, queryText, itemText) => {
        const hasValue = val => val != null ? val : ''
        let text = _.deburr(hasValue(itemText).toString().toLowerCase())
        let query = _.deburr(hasValue(queryText).toString().toLowerCase())
        return text.indexOf(query) > -1
      },
    },
  },
}
</script>
