import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  // clientes
  {
    path: 'clientes/:idcliente',
    name: 'representantes__clientes-representante-list',
    component: load('representantes/representantesList/RepresentanteList'),
    props: {
      tipo: 'cliente',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteRepresentante.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/:idcliente/add',
    name: 'representantes__clientes-representante-add',
    component: load('representantes/representantesAdd/RepresentanteAdd'),
    props: {
      tipo: 'cliente',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteRepresentante.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'clientes/:idcliente/:idrepresentante',
    name: 'representantes__clientes-representante-view',
    component: load('representantes/representantesView/RepresentanteView'),
    props: {
      tipo: 'cliente',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteRepresentante.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/:idcliente/:idrepresentante/edit',
    name: 'representantes__clientes-representante-edit',
    component: load('representantes/representantesEdit/RepresentanteEdit'),
    props: {
      tipo: 'cliente',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteRepresentante.id,
        access: ACCESS.edit,
      },
    },
  },
  // clientes potenciales
  {
    path: 'clientes-potenciales/:idcliente_potencial',
    name: 'representantes__clientes-potenciales-representante-list',
    component: load('representantes/representantesList/RepresentanteList'),
    props: {
      tipo: 'cliente_potencial',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialRepresentante.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/add',
    name: 'representantes__clientes-potenciales-representante-add',
    component: load('representantes/representantesAdd/RepresentanteAdd'),
    props: {
      tipo: 'cliente_potencial',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialRepresentante.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/:idrepresentante',
    name: 'representantes__clientes-potenciales-representante-view',
    component: load('representantes/representantesView/RepresentanteView'),
    props: {
      tipo: 'cliente_potencial',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialRepresentante.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/:idrepresentante/edit',
    name: 'representantes__clientes-potenciales-representante-edit',
    component: load('representantes/representantesEdit/RepresentanteEdit'),
    props: {
      tipo: 'cliente_potencial',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialRepresentante.id,
        access: ACCESS.edit,
      },
    },
  },
]
