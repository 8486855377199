<template>
  <v-jsf
    v-model="internalValue"
    :schema="schema"
    :options="options"
  />
</template>

<script>
import vmodelMixin from '@/mixins/vmodelMixin'
import VJsf from '@koumoul/vjsf/lib/VJsf.js'

export default {
  components: {
    VJsf,
  },
  mixins: [vmodelMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    schema: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
