import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class ContratoCuotaOnlineResource extends BaseOnlineResource {
  title (row) {
    return row.tservicio_contratado_desc
  }
  subtitle (row) {
    return row.mtservicio_contratado_desc
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('tservicio_contratado_desc', value)
      .addILike('mtservicio_contratado_desc', value)
  }
  badge (row) {
    if (row.contratado){
      return {
        badge: 'Contratado',
        badgeColor: 'success',
      }
    } else {
      return {
        badge: 'No contratado',
        badgeColor: 'error',
      }
    }
  }
}
