import BaseOnlineResource from '@/online/base'
import { APIFilter } from '@/utils/api'

function ParametroNotFound (message) {
  const error = new Error(message)
  return error
}

ParametroNotFound.prototype = Object.create(Error.prototype)

export default class ParametroOnlineResource extends BaseOnlineResource {
  async valorParametro (idparametro, throwException = true) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idparametro', idparametro)
    const resp = await this.Vue.$api.call('parametro.select', { filter: apiFilter })

    if (resp.data.result.dataset.length === 0) {
      if (throwException) {
        throw new ParametroNotFound(`Parámatro ${idparametro} no encontrado`)
      } else {
        return undefined
      }
    } else {
      return resp.data.result.dataset[0].valor
    }
  }
}
