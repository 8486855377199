import { load } from '@/router/utils'

export default [
  {
    path: 'agenda',
    name: 'planificacion__agenda',
    component: load('planificacion/agenda/Agenda'),
  },
  {
    path: 'planificador-tecnicos',
    name: 'planificacion__planificador-tecnicos',
    component: load('planificacion/planificadorTecnicos/PlanificadorTecnicos'),
  },
]
