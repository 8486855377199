import BaseOfflineResource from '@/offline/base'


export default class FormaPagoOfflineResource extends BaseOfflineResource {
  async FormaDePagoDeParte (idparteTrabajo) {
    const tables = this.db.tables
    return (await this.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.forma_pago,
        tables.orden_trabajo.idforma_pago.eq(tables.forma_pago.idforma_pago)
      )
      .where(tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo))
      .exec())[0]
  }
}