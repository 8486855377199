import BaseOfflineResource from '@/offline/base'

export default class ValorColumnaOtOfflineResource extends BaseOfflineResource {
  async updateSync (values) {
    await this.Vue.$offline.sync.insert({
      method: 'valorColumnaOt.updateSync',
      params: { values },
    })
    let query = this.db.update(this.table).where(this.pk.eq(values[this.pkName]))
    query = this.addSetsToUpdateQuery(query, values)
    return await query.exec()
  }
  async deleteSync (idvalorColumnaOt) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'valorColumnaOt.deleteSync',
      params: {
        idvalor_columna_ot: idvalorColumnaOt,
      }
    })
    await this.Vue.$offline.valorColumnaOt.delete({
      where: tables.valor_columna_ot.idvalor_columna_ot.eq(idvalorColumnaOt)
    })
  }
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'valorColumnaOt.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
}
