import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class SobreEnvioOnlineResource extends BaseOnlineResource {
  title (row) {
    return `${filters.shortDateTime(row.fenvio)} - ${row.mensaje_asunto}`
  }
  subtitle (row) {
    return row.destinatarios
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('asunto', value)
  }
}
