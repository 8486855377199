import Vue from 'vue'
import colors from 'vuetify/lib/util/colors'

export const gotoScrollPosition = (scrollPosition) => {
  if (scrollPosition) {
    Vue.nextTick(() => {
      window.scrollTo(0, scrollPosition)
    })
  }
}

export const currentScrollPosition = (element = document.documentElement) => {
  return element.scrollTop
}

export const stringToHslColor = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  var h = hash * 104729 % 360
  return `hsl(${h}, 50%, 50%)`
}

const colorIsLight = (color) => {
  const hex = color.replace('#', '')
  const c_r = parseInt(hex.substr(0, 2), 16)
  const c_g = parseInt(hex.substr(2, 2), 16)
  const c_b = parseInt(hex.substr(4, 2), 16)
  const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000
  return brightness > 155
}

export const fontColor = (backgroundColor) => {
  if (colorIsLight(backgroundColor)) {
    return colors.shades.black
  } else {
    return colors.shades.white
  }
}

export const delphiColorToHexValue = (value) => {
  return Number(value).toString(16).padStart(6, '0')
}

export const delphiColorToHex = (value) => {
  const hexValue = delphiColorToHexValue(value)
  return hexValue.slice(4, 6) + hexValue.slice(2, 4) + hexValue.slice(0, 2)
}

export const delphiColorToRgba = (value, opacity) => {
  const hexValue = delphiColorToHexValue(value)
  const red = parseInt(hexValue.slice(4, 6), 16)
  const blue = parseInt(hexValue.slice(0, 2), 16)
  const green = parseInt(hexValue.slice(2, 4), 16)
  return `rgba(${red}, ${green}, ${blue}, ${opacity})`
}