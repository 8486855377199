<template>
  <v-dialog
    v-model="internalShowing"
    transition="false"
    fullscreen
    scrollable
  >
    <v-sheet>
      <v-app-bar
        dark
        fixed
        color="primary"
      >
        <v-btn
          icon
          @click="clickClose"
        >
          <v-icon>{{ $vuetify.icons.values.prev }}</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-app-bar>
      <v-container
        pa-0
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <qrcode-stream
            v-if="internalShowing"
            camera="auto"
            @init="onInit"
            @decode="onDecode"
          />
        </v-layout>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream
  },
  mixins: [internalShowingMixin],
  props: {
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    onDecode (data) {
      this.$emit('decode', data)
      this.internalShowing = false
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.$alert.showSnackbarError('Debes dar permiso para acceder a la cámara')
        } else if (error.name === 'NotFoundError') {
          this.$alert.showSnackbarError('No se ha detectado cámara en tu dispositivo')
        } else if (error.name === 'NotSupportedError') {
          this.$alert.showSnackbarError('La web debe ser servida de forma segura (HTTPS)')
        } else if (error.name === 'NotReadableError') {
          this.$alert.showSnackbarError('No es posible acceder a la cámara')
        } else if (error.name === 'OverconstrainedError') {
          this.$alert.showSnackbarError('La configuración de vídeo no es válida para ninguna cámara en el dispositivo')
        } else {
          throw new Error(error)
        }
      }
    },
    clickClose () {
      this.internalShowing = false
    }
  }
}
</script>
