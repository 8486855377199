import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class EstudioFinancieroOnlineResource extends BaseOnlineResource {
  title (row) {
    return row.descripcion
  }
  subtitle (row) {
    return `${filters.shortDate(row.fsolicitud)} - ${row.nombre_empleado}`
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('descripcion', value)
  }
}
