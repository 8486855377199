import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  // articulos
  {
    path: 'articulos',
    name: 'offline__articulo-list',
    component: load('offline/articuloList/ArticuloList'),
  },
  // ficheros
  {
    path: 'ficheros/:idfichero',
    name: 'offline__fichero-view',
    component: load('offline/ficheroView/FicheroView'),
  },
  {
    path: 'ficheros/:idfichero/edit',
    name: 'offline__fichero-edit',
    component: load('offline/ficheroEdit/FicheroEdit'),
  },
  {
    path: 'ficheros/add/:idtabla/:id',
    name: 'offline__fichero-add',
    component: load('offline/ficheroAdd/FicheroAdd'),
  },
  {
    path: 'ficheros/:idtabla/:id',
    name: 'offline__fichero-list',
    component: load('offline/ficheroList/FicheroList'),
  },
  // material sistema
  {
    path: 'material-sistema/:idmaterial_sistema/edit',
    name: 'offline__material-sistema-edit',
    component: load('offline/materialSistemaEdit/MaterialSistemaEdit'),
  },
  // material a instalar
  {
    path: 'partes/:idparte_trabajo/material-instalar',
    name: 'offline__lparte-trabajo-subsis-list',
    component: load('offline/lparteTrabajoSubsisList/LparteTrabajoSubsisList'),
  },
  {
    path: 'partes/:idparte_trabajo/material-instalar/subsis/:idsubsis',
    name: 'offline__lparte-trabajo-list',
    component: load('offline/lparteTrabajoList/LparteTrabajoList'),
  },
  {
    path: 'partes/:idparte_trabajo/material-instalar/:idlparte_trabajo',
    name: 'offline__lparte-trabajo-view',
    component: load('offline/lparteTrabajoView/LparteTrabajoView'),
  },
  {
    path: 'partes/:idparte_trabajo/material-instalar/:idlparte_trabajo/edit',
    name: 'offline__lparte-trabajo-edit',
    component: load('offline/lparteTrabajoEdit/LparteTrabajoEdit'),
  },
  {
    path: 'partes/:idparte_trabajo/material-instalar/add/:idarticulo',
    name: 'offline__lparte-trabajo-add',
    component: load('offline/lparteTrabajoAdd/LparteTrabajoAdd'),
  },
  {
    path: 'partes/:idparte_trabajo/material-instalar/add-lorden-trabajo/:idlorden_trabajo',
    name: 'offline__lparte-trabajo-lorden-trabajo-add',
    component: load('offline/lparteTrabajoAdd/LparteTrabajoAdd'),
  },
  // material afectado
  {
    path: `partes/:idparte_trabajo/plantillas-articulos/:idplantilla_articulo/aplicar`,
    name: 'offline__plantilla-articulo-aplicar',
    component: load('offline/plantillaArticuloAplicar/PlantillaArticuloAplicar'),
  },
  {
    path: 'partes/:idparte_trabajo/material-afectado',
    name: 'offline__lparte-trabajo-material-afectado-list',
    component: load('offline/lparteTrabajoMaterialAfectadoList/LparteTrabajoMaterialAfectadoList'),
  },
  {
    path: 'partes/:idparte_trabajo/material-afectado-subsis',
    name: 'offline__lparte-trabajo-material-afectado-subsis-list',
    component: load('offline/lparteTrabajoMaterialAfectadoSubsisList/LparteTrabajoMaterialAfectadoSubsisList'),
  },
  {
    path: 'partes/:idparte_trabajo/material-afectado/add/:idarticulo',
    name: 'offline__lparte-trabajo-material-afectado-add',
    component: load('offline/lparteTrabajoMaterialAfectadoAdd/LparteTrabajoMaterialAfectadoAdd'),
  },
  {
    path: 'partes/:idparte_trabajo/material-afectado/add-sistema/:idmaterial_sistema',
    name: 'offline__lparte-trabajo-material-afectado-sistema-add',
    component: load('offline/lparteTrabajoMaterialAfectadoAdd/LparteTrabajoMaterialAfectadoAdd'),
  },
  {
    path: 'partes/:idparte_trabajo/material-afectado/:idparte_trabajo_matsist/sustitucion/:idaccion_ejec/:idarticulo',
    name: 'offline__material-afectado-sustitucion',
    component: load('offline/lparteTrabajoMaterialAfectadoSustitucion/LparteTrabajoMaterialAfectadoSustitucion'),
  },
  {
    path: 'partes/:idparte_trabajo/material-afectado/:idparte_trabajo_matsist/acciones/add',
    name: 'offline__accion-ejec-add',
    component: load('offline/accionEjecAdd/AccionEjecAdd'),
  },
  {
    path: 'partes/:idparte_trabajo/material-afectado/:idparte_trabajo_matsist/acciones/:idaccion_ejec/edit',
    name: 'offline__accion-ejec-edit',
    component: load('offline/accionEjecEdit/AccionEjecEdit'),
  },
  {
    path: 'partes/:idparte_trabajo/material-afectado/:idparte_trabajo_matsist/acciones/:idaccion_ejec',
    name: 'offline__accion-ejec-view',
    component: load('offline/accionEjecView/AccionEjecView'),
  },
  // Añadir anomalía desde el material afectado
  {
    path: 'partes/:idparte_trabajo/material-afectado/:idparte_trabajo_matsist/anomalia/addlist',
    name: 'offline__anomalia-material-add-list',
    component: load('offline/anomaliaMaterialAddList/AnomaliaMaterialAddList'),
  },

  {
    path: 'partes/:idparte_trabajo/acciones/add',
    name: 'offline__accion-ejec-masiva-add',
    component: load('offline/accionEjecMasivaAdd/AccionEjecMasivaAdd'),
  },
  {
    path: 'partes/:idparte_trabajo/acciones/realizada',
    name: 'offline__accion-ejec-masiva-realizada',
    component: load('offline/accionEjecMasivaRealizada/AccionEjecMasivaRealizada'),
  },
  {
    path: 'partes/:idparte_trabajo/material-afectado/:idparte_trabajo_matsist',
    name: 'offline__material-afectado-view',
    component: load('offline/lparteTrabajoMaterialAfectadoView/LparteTrabajoMaterialAfectadoView'),
  },
  {
    path: 'partes/:idparte_trabajo/material-afectado/:idparte_trabajo_matsist/desinstalar',
    name: 'offline__material-afectado-desinstalar',
    component: load('offline/lparteTrabajoMaterialAfectadoDesinstalar/LparteTrabajoMaterialAfectadoDesinstalar'),
  },
  // en ficheros dejo el :id solo para que funcione correctamente
  {
    path: 'partes/:id/:idtabla/ficheros',
    name: 'offline__parte-fichero-list',
    component: load('offline/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.parteTrabajoAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'offline__parte-fichero-add',
      viewRoute: 'offline__parte-fichero-view',
    },
  },
  {
    path: 'partes/:id/:idtabla/ficheros/add',
    name: 'offline__parte-fichero-add',
    component: load('offline/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.parteTrabajoAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'partes/:id/:idtabla/ficheros/:idfichero',
    name: 'offline__parte-fichero-view',
    component: load('offline/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.parteTrabajoAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      editRoute: 'offline__parte-fichero-edit'
    },
  },
  {
    path: 'partes/:id/:idtabla/ficheros/:idfichero/edit',
    name: 'offline__parte-fichero-edit',
    component: load('offline/ficheroEdit/FicheroEdit'),
    meta: {
      permission: {
        idobjeto: permissions.parteTrabajoAdjunto.id,
        access: ACCESS.edit,
      },
    },
  },
  // ordenes
  {
    path: 'ordenes/:idorden_trabajo/parte/:idparte_trabajo/subsis/add',
    name: 'offline__orden-trabajo-subsis-add',
    component: load('offline/ordenTrabajoSubsisAdd/OrdenTrabajoSubsisAdd'),
  },
  {
    path: 'ordenes/:idorden_trabajo/parte/:idparte_trabajo/subsis',
    name: 'offline__orden-trabajo-subsis-list',
    component: load('offline/ordenTrabajoSubsisList/OrdenTrabajoSubsisList'),
  },
  {
    path: 'ordenes/:idorden_trabajo/lineas',
    name: 'offline__lorden-trabajo-list',
    component: load('offline/lordenTrabajoList/LordenTrabajoList'),
  },
  {
    path: 'ordenes/:idorden_trabajo/lineas/:idlorden_trabajo/escandallo',
    name: 'offline__lorden-trabajo-detalle-list',
    component: load('offline/lordenTrabajoDetalleList/LordenTrabajoDetalleList'),
  },
  {
    path: 'ordenes/:idorden_trabajo/lineas/:idlorden_trabajo/escandallo/:idlorden_trabajo_detalle',
    name: 'offline__lorden-trabajo-detalle-view',
    component: load('offline/lordenTrabajoDetalleView/LordenTrabajoDetalleView'),
  },
  {
    path: 'ordenes/:idorden_trabajo/lineas/:idlorden_trabajo/escandallo/:idlorden_trabajo_detalle/edit',
    name: 'offline__lorden-trabajo-detalle-edit',
    component: load('offline/lordenTrabajoDetalleEdit/LordenTrabajoDetalleEdit'),
  },
  // partes
  {
    path: 'partes/:idparte_trabajo/edit',
    name: 'offline__parte-edit',
    component: load('offline/parteEdit/ParteEdit'),
  },
  {
    path: 'partes/:idparte_trabajo/finalizar',
    name: 'offline__parte-finalizar',
    component: load('offline/parteFinalizar/ParteFinalizar'),
  },
  {
    path: 'partes/:idparte_trabajo/firmar',
    name: 'offline__parte-firmar',
    component: load('offline/parteFirmar/ParteFirmar'),
  },
  {
    path: 'partes',
    name: 'offline__parte-descargados-list',
    component: load('offline/parteDescargadosList/ParteDescargadosList'),
  },
  {
    path: 'partes/:idparte_trabajo/resumen',
    name: 'offline__parte-resumen-cliente-view',
    component: load('offline/parteResumenClienteView/ParteResumenClienteView'),
  },
  {
    path: 'partes/:idparte_trabajo/cobrar',
    name: 'offline__parte-cobrar',
    component: load('offline/parteCobrar/ParteCobrar'),
    meta: {
      permission: {
        idobjeto: permissions.partes.cobrarIntervencion,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'partes/:idparte_trabajo/tecnicos',
    name: 'offline__parte-trabajo-tecnico-list',
    component: load('offline/parteTrabajoTecnicoList/ParteTrabajoTecnicoList'),
  },
  {
    path: 'partes/:idparte_trabajo/tiempos/add',
    name: 'offline__tiempo-add',
    component: load('offline/tiempoAdd/TiempoAdd'),
  },
  {
    path: `partes/:idparte_trabajo`,
    name: 'offline__parte-trabajo-view',
    component: load('offline/parteTrabajoView/ParteTrabajoView'),
  },
  // plantillas de artículos
  {
    path: `plantillas-articulos`,
    name: 'offline__plantilla-articulo-list',
    component: load('offline/plantillaArticuloList/PlantillaArticuloList'),
  },
  // sistemas
  {
    path: 'sistemas/:idsistema',
    name: 'offline__sistema-view',
    component: load('offline/sistemaView/SistemaView'),
  },
  // dato_sistema
  {
    path: 'sistemas/:idsistema/datos',
    name: 'offline__sistemas__dato-sistema-list',
    component: load('offline/datoSistemaList/DatoSistemaList'),
    props: {
      editRoute: 'offline__sistemas__dato-sistema-edit',
      addRoute: 'offline__sistemas__dato-sistema-add'
    },
    meta: {
      permission: {
        idobjeto: permissions.sistemaDato.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'sistemas/:idsistema/datos/add',
    name: 'offline__sistemas__dato-sistema-add',
    component: load('offline/datoSistemaAdd/DatoSistemaAdd'),
    props: {
      afterSubmitRoute: 'offline__sistemas__dato-sistema-list',
    },
    meta: {
      permission: {
        idobjeto: permissions.sistemaDato.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'sistemas/:idsistema/datos/:iddato_sistema/edit',
    name: 'offline__sistemas__dato-sistema-edit',
    component: load('offline/datoSistemaEdit/DatoSistemaEdit'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaDato.id,
        access: ACCESS.edit,
      },
    },
  },
  {
    path: 'sistemas/:idsistema/material-sistema-subsis',
    name: 'offline__material-sistema-subsis-list',
    component: load('offline/materialSistemaSubsisList/MaterialSistemaSubsisList'),
  },
  {
    path: 'sistemas/:idsistema/material-sistema',
    name: 'offline__material-sistema-list',
    component: load('offline/materialSistemaList/MaterialSistemaList'),
    meta: {
      permission: {
        idobjeto: permissions.materialSistema.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'sistemas/:idsistema/material-sistema/add',
    name: 'offline__material-sistema-add',
    component: load('offline/materialSistemaAdd/MaterialSistemaAdd'),
    meta: {
      permission: {
        idobjeto: permissions.materialSistema.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'sistemas/:idsistema/material-sistema/:idmaterial_sistema',
    name: 'offline__material-sistema-view',
    component: load('offline/materialSistemaView/MaterialSistemaView'),
    meta: {
      permission: {
        idobjeto: permissions.materialSistema.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'sistemas/:idsistema/revisiones',
    name: 'offline__sistema-mant-list',
    component: load('offline/sistemaMantList/SistemaMantList'),
  },
  {
    path: 'sistemas/:idsistema/subsistemas',
    name: 'offline__subsis-list',
    component: load('offline/subsisList/SubsisList'),
  },
  // añadir material sistema por plantilla:
  {
    path: `sistemas/:idsistema/plantillas-articulos/:idplantilla_articulo/aplicar`,
    name: 'offline__plantilla-articulo-aplicar-material-sistema',
    component: load('offline/plantillaArticuloAplicarMaterialSistema/PlantillaArticuloAplicarMaterialSistema'),
  },
  // vías de comunicación
  {
    path: 'sistemas/:idsistema/vias-comunicacion',
    name: 'offline__sistemas__sistema-tviacomunicacion-list',
    component: load('offline/sistemaTviacomunicacionList/SistemaTviacomunicacionList'),
  },
  // en ficheros dejo el :id solo para que funcione correctamente
  {
    path: 'sistemas/:id/:idtabla/ficheros',
    name: 'offline__sistema-fichero-list',
    component: load('offline/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'offline__sistema-fichero-add',
      viewRoute: 'offline__sistema-fichero-view',
    },
  },
  {
    path: 'sistemas/:id/:idtabla/ficheros/add',
    name: 'offline__sistema-fichero-add',
    component: load('offline/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'sistemas/:id/:idtabla/ficheros/:idfichero',
    name: 'offline__sistema-fichero-view',
    component: load('offline/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      editRoute: 'offline__sistema-fichero-edit'
    },
  },
  {
    path: 'sistemas/:id/:idtabla/ficheros/:idfichero/edit',
    name: 'offline__sistema-fichero-edit',
    component: load('offline/ficheroEdit/FicheroEdit'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaAdjunto.id,
        access: ACCESS.edit,
      },
    },
  },
  // telefonos
  {
    path: 'clientes/:idcliente/telefonos/add',
    name: 'offline__cliente-telefono-add',
    component: load('offline/clienteTelefonoAdd/ClienteTelefonoAdd'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaTelefono.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'clientes/:idcliente/telefonos',
    name: 'offline__cliente-telefono-list',
    component: load('offline/clienteTelefonoList/ClienteTelefonoList'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaTelefono.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/:idcliente/telefonos/:idcliente_telefono',
    name: 'offline__cliente-telefono-view',
    component: load('offline/clienteTelefonoView/ClienteTelefonoView'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaTelefono.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/:idcliente/telefonos/:idcliente_telefono/edit',
    name: 'offline__cliente-telefono-edit',
    component: load('offline/clienteTelefonoEdit/ClienteTelefonoEdit'),
    meta: {
      permission: {
        idobjeto: permissions.sistemaTelefono.id,
        access: ACCESS.edit,
      },
    },
  },
  {
    path: 'sistemas/:idsistema/servicios',
    name: 'offline__sistema-cuota-list',
    component: load('offline/sistemaCuotaList/SistemaCuotaList'),
  },
  {
    path: 'sistemas/:idsistema/servicios/:idsistema_cuota',
    name: 'offline__sistema-cuota-view',
    component: load('offline/sistemaCuotaView/SistemaCuotaView'),
  },
  // tiempos
  {
    path: 'tiempos/cronometro-iniciar/:idparte_trabajo',
    name: 'offline__cronometro-iniciar',
    component: load('offline/cronometroIniciar/CronometroIniciar'),
  },
  {
    path: 'tiempos/cronometro-detener',
    name: 'offline__cronometro-detener',
    component: load('offline/cronometroDetener/CronometroDetener'),
  },
  {
    path: 'tiempos/:idtiempo_trabajado',
    name: 'offline__tiempo-trabajado-view',
    component: load('offline/tiempoTrabajadoView/TiempoTrabajadoView'),
  },
  {
    path: 'tiempos',
    name: 'offline__tiempo-trabajado-list',
    component: load('offline/tiempoTrabajadoList/TiempoTrabajadoList'),
  },
  // checklists
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot',
    name: 'offline__checklist-view',
    component: load('offline/checklistView/ChecklistView'),
  },
  // acciones correctoras
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:idtanomalia_checklist_ot/acciones-correctoras/add',
    name: 'offline__accion-correctora-add',
    component: load('offline/accionCorrectoraAdd/AccionCorrectoraAdd'),
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:idtanomalia_checklist_ot/acciones-correctoras/:idaccion_correctora_ot',
    name: 'offline__accion-correctora-view',
    component: load('offline/accionCorrectoraView/AccionCorrectoraView'),
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:idtanomalia_checklist_ot/acciones-correctoras/:idaccion_correctora_ot/edit',
    name: 'offline__accion-correctora-edit',
    component: load('offline/accionCorrectoraEdit/AccionCorrectoraEdit'),
  },
  // en ficheros dejo el :id solo para que funcione correctamente
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:idtanomalia_checklist_ot/acciones-correctoras/:id/:idtabla/ficheros',
    name: 'offline__accion-correctora-fichero-list',
    component: load('offline/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.accionCorrectoraAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'offline__accion-correctora-fichero-add',
      viewRoute: 'offline__accion-correctora-fichero-view',
    },
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:idtanomalia_checklist_ot/acciones-correctoras/:id/:idtabla/ficheros/add',
    name: 'offline__accion-correctora-fichero-add',
    component: load('offline/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.accionCorrectoraAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:idtanomalia_checklist_ot/acciones-correctoras/:id/:idtabla/ficheros/:idfichero',
    name: 'offline__accion-correctora-fichero-view',
    component: load('offline/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.accionCorrectoraAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      editRoute: 'offline__accion-correctora-fichero-edit'
    },
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:idtanomalia_checklist_ot/acciones-correctoras/:id/:idtabla/ficheros/:idfichero/edit',
    name: 'offline__accion-correctora-fichero-edit',
    component: load('offline/ficheroEdit/FicheroEdit'),
    meta: {
      permission: {
        idobjeto: permissions.accionCorrectoraAdjunto.id,
        access: ACCESS.edit,
      },
    },
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/acciones-correctoras',
    name: 'offline__accion-correctora-list',
    component: load('offline/accionCorrectoraList/AccionCorrectoraList'),
  },
  // anomalias
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/anomalias',
    name: 'offline__anomalia-list',
    component: load('offline/anomaliaList/AnomaliaList'),
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/add',
    name: 'offline__anomalia-add',
    component: load('offline/anomaliaAdd/AnomaliaAdd'),
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:idtanomalia_checklist_ot',
    name: 'offline__anomalia-view',
    component: load('offline/anomaliaView/AnomaliaView'),
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:idtanomalia_checklist_ot/edit',
    name: 'offline__anomalia-edit',
    component: load('offline/anomaliaEdit/AnomaliaEdit'),
  },
  // en ficheros dejo el :id solo para que funcione correctamente
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:id/:idtabla/ficheros',
    name: 'offline__anomalia-fichero-list',
    component: load('offline/ficheroList/FicheroList'),
    meta: {
      permission: {
        idobjeto: permissions.anomaliaAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      addRoute: 'offline__anomalia-fichero-add',
      viewRoute: 'offline__anomalia-fichero-view',
    },
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:id/:idtabla/ficheros/add',
    name: 'offline__anomalia-fichero-add',
    component: load('offline/ficheroAdd/FicheroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.anomaliaAdjunto.id,
        access: ACCESS.insert,
      },
    },
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:id/:idtabla/ficheros/:idfichero',
    name: 'offline__anomalia-fichero-view',
    component: load('offline/ficheroView/FicheroView'),
    meta: {
      permission: {
        idobjeto: permissions.anomaliaAdjunto.id,
        access: ACCESS.view,
      },
    },
    props: {
      editRoute: 'offline__anomalia-fichero-edit'
    },
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas/:idpregunta_checklist_ot/anomalias/:id/:idtabla/ficheros/:idfichero/edit',
    name: 'offline__anomalia-fichero-edit',
    component: load('offline/ficheroEdit/FicheroEdit'),
    meta: {
      permission: {
        idobjeto: permissions.anomaliaAdjunto.id,
        access: ACCESS.edit,
      },
    },
  },
  // preguntas
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/grupos/:idgrupo_checklist_ot/preguntas',
    name: 'offline__grupos-pregunta-list',
    component: load('offline/preguntaList/PreguntaList'),
  },
  {
    path: 'partes/:idparte_trabajo/checklists/:idchecklist_ot/materiales/:idorden_trabajo_matsist/preguntas',
    name: 'offline__materiales-pregunta-list',
    component: load('offline/preguntaList/PreguntaList'),
  },
  // sincronización
  {
    path: 'sincronizacion/sync-uploads',
    name: 'offline__sync-upload-list',
    component: load('offline/syncUploadList/SyncUploadList'),
  },
  {
    path: 'sincronizacion/sync-uploads/:idsync_upload',
    name: 'offline__sync-upload-view',
    component: load('offline/syncUploadView/SyncUploadView'),
  },
  {
    path: 'sincronizacion/sync',
    name: 'offline__sync-list',
    component: load('offline/syncList/SyncList'),
  },
]
