<template>
  <v-dialog
    v-model="overlayVisible"
    overlay-color="grey"
    persistent
    :transition="false"
    no-click-animation
    width="400"
  >
    <v-card
      v-show="message"
      class="mx-auto text-center pt-5"
      :max-width="400"
    >
      <v-card-text>
        <v-progress-circular
          indeterminate
          color="primary"
          size="48"
          width="8"
        />
      </v-card-text>
      <v-card-text
        class="text-truncate"
        v-html="message"
      />
    </v-card>
  </v-dialog>
</template>

<script>
/*
  * Se usa el store (vuex) para mostrar/ocultar
  * Desde las llamadas a la API (request/response) se muestra/oculta el loader automáticamente
  * Cuando es necesario agrupar varias acciones de un proceso se usa la propiedad
    del store "manualLoading"
*/
import internalShowingMixin from '@/mixins/internalShowingMixin'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

export default {
  mixins: [internalShowingMixin],
  props: {
    message: {
      type: String,
      default: '',
    },
    disableUI: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    overlayVisible () {
      return this.internalShowing && this.disableUI
    },
  },
  watch: {
    showing (newValue) {
      if (!this.message) {
        if (newValue) {
          NProgress.start()
        } else {
          NProgress.done()
        }
      }
    },
  },
  created () {
    NProgress.configure({
      showSpinner: false
    })
  },
}
</script>

<style>
#nprogress .bar {
  background-color: var(--v-orange-base);
  height: 8px;
}
#nprogress .peg {
  box-shadow: 0 0 10px var(--v-orange-base), 0 0 10px var(--v-orange-base);
}
</style>
