import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'

export default class ContactoClienteOnlineResource extends BaseOnlineResource {
  title (row) {
    return `${row.descripcion}`
  }
  subtitle (row) {
    return `${filters.shortDateTime(row.fcontacto)} - ${row.tcontacto_desc}`
  }
  async delete (idcontactoCliente) {
    await this.Vue.$api.call('contactoCliente.delete', { idcontacto_cliente: idcontactoCliente })
  }
}