import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class ClientePotencialOnlineResource extends BaseOnlineResource {
  title (row) {
    if (row.cif) {
      return `${row.nombre} (${row.cif})`
    } else {
      return row.nombre
    }
  }
  subtitle (row) {
    return filters.linebreaksBr(row.cliente_direccion)
  }
  searchFilter (value) {
    const apiFilter = new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('nombre', value)
      .addILike('razon_social', value)
      .addILike('cif', value)
      .addILike('calle', value)
      .addILike('cp', value)
      .addILike('telefono', value)
    if (!isNaN(value)) {
      apiFilter.addExact('idcliente_potencial', value)
    }
    return apiFilter
  }
}
