import BaseOnlineResource from '@/online/base'
import filters from '@/utils/filters'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { TABLA } from '@/utils/consts'
import { OPERACIONCRM } from '@/utils/consts'

export default class OperacionCRMOnlineResource extends BaseOnlineResource {
  name (row) {
    return `${row.descripcion}`
  }
  title (row) {
    return `${row.toperacioncrm_desc} - ${row.est_operacioncrm_dec} (${row.serie_numero})`
  }
  subtitle (row, idempleado) {
    let resumenOperacionCRM = []
    resumenOperacionCRM.push(`Próx. revisión: ${filters.shortDate(row.fproximarevision)}`)
    if (row.operacioncrm_desc) {
      resumenOperacionCRM.push(`${row.operacioncrm_desc}`)
    }
    if (row.idresponsable && row.idresponsable !== idempleado) {
      resumenOperacionCRM.push(`Responsable: ${(row.empleado_responsable)}`)
    }
    if (row.observaciones) {
      resumenOperacionCRM.push(`Observaciones: ${filters.linebreaksBr(row.observaciones)}`)
    }
    return resumenOperacionCRM
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('descripcion', value)
      .addILike('est_operacioncrm_dec', value)
      .addILike('toperacioncrm_desc', value)
  }
  getIdentificador(tabla, routeParams) {
    let identificador = 0
    if (tabla === TABLA.cliente) {
      identificador = routeParams.idcliente
    } else if (tabla === TABLA.sistema) {
      identificador = routeParams.idsistema
    } else if (tabla === TABLA.cliente_potencial) {
      identificador = routeParams.idcliente_potencial
    } else if (tabla === TABLA.presupuestocli) {
      identificador = routeParams.idpresupuestocli
    }
    return identificador
  }
  async delete (idoperacioncrm) {
    await this.Vue.$api.call('operacioncrm.delete', { idoperacioncrm: idoperacioncrm })
  }
  formatListItem(item, idempleado) {
    item.title = this.title(item)
    item.subtitle = this.subtitle(item, idempleado).join(' - ')
    item.avatar = filters.initials(OPERACIONCRM.descripcionesPrioridad[item.idprioridad])
    item.avatarColor = OPERACIONCRM.coloresPrioridad[item.idprioridad]
    return item
  }
}
