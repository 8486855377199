import { load } from '@/router/utils'

export default [
  {
    path: '',
    name: 'pedidos__pedido-cliente-list',
    component: load('pedidos/pedidoClienteList/PedidoClienteList'),
  },
  {
    path: ':idpedidocli/servir',
    name: 'pedidos__pedido-cliente-servir',
    component: load('pedidos/pedidoClienteServir/PedidoClienteServir'),
  },
  {
    path: ':idpedidocli/lineas/:idlpedidocli/servir',
    name: 'pedidos__pedido-cliente-servir-linea',
    component: load('pedidos/pedidoClienteServirLinea/PedidoClienteServirLinea'),
  },
]
