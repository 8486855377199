<template>
  <v-dialog
    v-model="internalShowing"
    transition="false"
    fullscreen
    scrollable
  >
    <v-sheet>
      <v-app-bar
        dark
        fixed
        color="primary"
      >
        <v-btn
          icon
          @click="clickClose"
        >
          <v-icon>{{ $vuetify.icons.values.prev }}</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-app-bar>
      <v-container
        pa-0
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <div
            ref="map"
            class="google-map"
          />
        </v-layout>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import { initGoogleMaps, captureGeolocation } from '@/utils/maps'

export default {
  mixins: [internalShowingMixin],
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
    latFieldname: {
      type: String,
      default: 'latitud',
    },
    lngFieldname: {
      type: String,
      default: 'longitud',
    },
    iconFieldname: {
      type: String,
      default: '',
    },
    zoom: {
      type: Number,
      default: 14,
    },
    title: {
      type: String,
      default: 'Mapa'
    }
  },
  data () {
    return {
      geolocation: null,
      map: null,
      mapMarkers: [],
      markerCluster: null,
      markerSpiderfier: null,
      searchOnBoundsChanged: true,
      searchOnBoundsChangedDiv: null,
    }
  },
  watch: {
    async showing (newValue) {
      if (newValue) {
        await this.initMap()
      }
    },
    markers (newValue) {
      if (this.map) {
        // borrar actuales
        if (this.markerCluster) {
          this.markerCluster.clearMarkers()
        }
        for (const marker of this.mapMarkers) {
          marker.setMap(null)
        }
        this.mapMarkers = []
        // crear nuevos
        for (const marker of newValue) {
          let latlong = new google.maps.LatLng(marker[this.latFieldname], marker[this.lngFieldname])
          let mapMarker = new google.maps.Marker({
            position: latlong,
            map: this.map,
            clickable: true,
            icon: this.iconFieldname ? require(`@/assets/maps/${marker[this.iconFieldname]}`) : ''
          })
          mapMarker.extraInfo = marker
          google.maps.event.addListener(mapMarker, 'spider_click', () => {
            this.$emit('click-marker', marker)
          })
          this.markerSpiderfier.addMarker(mapMarker)
          this.mapMarkers.push(mapMarker)
        }
        const markerClustererOptions = {
          maxZoom: 15,
          styles: [
            MarkerClusterer.withDefaultStyle({ height: 53, url: require(`@/assets/marker-cluster/m1.png`), width: 53, }),
            MarkerClusterer.withDefaultStyle({ height: 56, url: require(`@/assets/marker-cluster/m2.png`), width: 56, }),
            MarkerClusterer.withDefaultStyle({ height: 66, url: require(`@/assets/marker-cluster/m3.png`), width: 66, }),
            MarkerClusterer.withDefaultStyle({ height: 78, url: require(`@/assets/marker-cluster/m4.png`), width: 78, }),
            MarkerClusterer.withDefaultStyle({ height: 90, url: require(`@/assets/marker-cluster/m5.png`), width: 90, }),
          ],
        }
        this.markerCluster = new MarkerClusterer(this.map, this.mapMarkers, markerClustererOptions)
      }
    },
  },
  methods: {
    clickClose () {
      this.internalShowing = false
    },
    async initMap () {
      try {
        this.geolocation = await captureGeolocation(this)
        if (this.geolocation) {
          await initGoogleMaps(this)
          this.map = new google.maps.Map(this.$refs.map, {
            center: { lat: this.geolocation.lat, lng: this.geolocation.lng },
            zoom: this.zoom,
            disableDefaultUI: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: true,
            mapTypeControl: true,
            mapTypeControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
            },
            styles: [
              {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                stylers: [{ visibility: 'off' }],
              },
            ],
          })
          const oms = await require('@/lib/oms.min.js')
          this.markerSpiderfier = new oms.OverlappingMarkerSpiderfier(this.map, {
            keepSpiderfied: true,
            event: 'mouseover',
            markersWontMove: true,
            markersWontHide: true,
            basicFormatEvents: true,
          })
          this.searchOnBoundsChangedDiv = document.createElement('div')
          this.searchOnBoundsChangedControl(this.searchOnBoundsChangedDiv)
          this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.searchOnBoundsChangedDiv)
          this.map.addListener('bounds_changed', this.mapBoundsChanged)
        }
      } catch (e) {
        this.internalShowing = false
        throw(e)
      }
    },
    search () {
      const bounds = this.map.getBounds()
      const NEbound = bounds.getNorthEast()
      const SWbound = bounds.getSouthWest()
      this.$emit('bounds-changed', {
        neLat: NEbound.lat(),
        neLng: NEbound.lng(),
        swLat: SWbound.lat(),
        swLng: SWbound.lng(),
      })
    },
    mapBoundsChanged () {
      if (this.searchOnBoundsChanged) {
        this.search()
      } else {
        const label = document.getElementById('label-on-bounds-changed')
        const checkbox = document.getElementById('checkbox-on-bounds-changed')
        const button = document.getElementById('button-on-bounds-changed')
        if (label && checkbox && button) {
          checkbox.style.visibility = 'hidden'
          checkbox.style.display = 'none'
          label.style.visibility = 'hidden'
          label.style.display = 'none'
          button.style.visibility = 'visible'
          button.style.display = 'inline-block'
          this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].pop(this.searchOnBoundsChangedDiv)
          this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.searchOnBoundsChangedDiv)
        }
      }
    },
    searchOnBoundsChangedControl (searchOnBoundsChangedDiv) {
      const controlUI = document.createElement('div')
      controlUI.style.backgroundColor = '#fff'
      controlUI.style.cursor = 'pointer'
      controlUI.style.marginBottom = '22px'
      controlUI.style.paddingLeft = '10px'
      controlUI.style.paddingRight = '10px'
      controlUI.style.paddingBottom = '4px'
      controlUI.style.paddingTop = '4px'
      controlUI.style.textAlign = 'center'
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
      searchOnBoundsChangedDiv.appendChild(controlUI)
      const checkbox = document.createElement('input')
      checkbox.type = 'checkbox'
      checkbox.name = 'search-on-bounds-changed'
      checkbox.value = 'value'
      checkbox.checked = 'checked'
      checkbox.id = 'checkbox-on-bounds-changed'
      checkbox.style.fontSize = '16px'
      checkbox.addEventListener('click', this.clickCheckboxSearchOnBoundsChanged)
      const label = document.createElement('label')
      label.htmlFor = 'search-on-bounds-changed'
      label.id = 'label-on-bounds-changed'
      label.style.fontSize = '16px'
      label.style.lineHeight = '38px'
      label.style.paddingLeft = '5px'
      label.addEventListener('click', () => {
        const checkbox = document.getElementById('checkbox-on-bounds-changed')
        checkbox.click()
      })
      label.appendChild(document.createTextNode('Buscar mientras me desplazo'))
      const button = document.createElement('input')
      button.type = 'button'
      button.value = 'Buscar en esta zona'
      button.id = 'button-on-bounds-changed'
      button.style.lineHeight = '38px'
      button.style.fontSize = '16px'
      button.style.visibility = 'hidden'
      button.style.display = 'none'
      button.addEventListener('click', this.clickButtonSearchOnBounds)
      controlUI.appendChild(checkbox)
      controlUI.appendChild(label)
      controlUI.appendChild(button)
    },
    clickCheckboxSearchOnBoundsChanged (event) {
      this.searchOnBoundsChanged = event.target.checked
    },
    clickButtonSearchOnBounds () {
      this.search()
      const label = document.getElementById('label-on-bounds-changed')
      const checkbox = document.getElementById('checkbox-on-bounds-changed')
      const button = document.getElementById('button-on-bounds-changed')
      checkbox.style.visibility = 'visible'
      checkbox.style.display = 'inline-block'
      label.style.visibility = 'visible'
      label.style.display = 'inline-block'
      button.style.visibility = 'hidden'
      button.style.display = 'none'
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].pop(this.searchOnBoundsChangedDiv)
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.searchOnBoundsChangedDiv)
    },
  },

}
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 100%;
}
</style>
