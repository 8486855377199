import router from '@/router'

export default {
  install: (Vue) => {
    Vue.$appRouter = Vue.prototype.$appRouter = {
      routerPlugin: null,
      init (routerPlugin) {
        this.routerPlugin = routerPlugin
      },
      push (params) {
        // para evitar los "Navigation aborted from ... to ... via a navigation guard."
        router.push(params).catch((e) => {
          console.info(e.message)
        })
      },
      replace (params) {
        // para evitar los "Navigation aborted from ... to ... via a navigation guard."
        router.replace(params).catch((e) => {
          console.info(e.message)
        })
      },
      go (num) {
        router.go(num)
      }
    }
  }
}
