import { load } from '@/router/utils'
import { permissions, ACCESS } from '@/utils/permissions'

export default [
  // clientes
  {
    path: 'clientes/:idcliente',
    name: 'estudiosfinancieros__clientes-estudio-financiero-list',
    component: load('estudiosFinancieros/estudioFinancieroList/EstudioFinancieroList'),
    props: {
      addRoute: 'estudiosfinancieros__clientes-estudio-financiero-add',
      viewRoute: 'estudiosfinancieros__clientes-estudio-financiero-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteEstudioFinanciero.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/:idcliente/add',
    name: 'estudiosfinancieros__clientes-estudio-financiero-add',
    component: load('estudiosFinancieros/estudioFinancieroAdd/EstudioFinancieroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.clienteEstudioFinanciero.id,
        access: ACCESS.insert,
      },
    },
    props: {
      viewRoute: 'estudiosfinancieros__clientes-estudio-financiero-view',
    },
  },
  {
    path: 'clientes/:idcliente/:idestudio_financiero',
    name: 'estudiosfinancieros__clientes-estudio-financiero-view',
    component: load('estudiosFinancieros/estudioFinancieroView/EstudioFinancieroView'),
    props: {
      editRoute: 'estudiosfinancieros__clientes-estudio-financiero-edit',
    },
    meta: {
      permission: {
        idobjeto: permissions.clienteEstudioFinanciero.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes/:idcliente/:idestudio_financiero/edit',
    name: 'estudiosfinancieros__clientes-estudio-financiero-edit',
    component: load('estudiosFinancieros/estudioFinancieroEdit/EstudioFinancieroEdit'),
    meta: {
      permission: {
        idobjeto: permissions.clienteEstudioFinanciero.id,
        access: ACCESS.edit,
      },
    },
  },
  // clientes potenciales
  {
    path: 'clientes-potenciales/:idcliente_potencial',
    name: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-list',
    component: load('estudiosFinancieros/estudioFinancieroList/EstudioFinancieroList'),
    props: {
      addRoute: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-add',
      viewRoute: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-view',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialEstudioFinanciero.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/add',
    name: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-add',
    component: load('estudiosFinancieros/estudioFinancieroAdd/EstudioFinancieroAdd'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialEstudioFinanciero.id,
        access: ACCESS.insert,
      },
    },
    props: {
      viewRoute: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-view',
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/:idestudio_financiero',
    name: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-view',
    component: load('estudiosFinancieros/estudioFinancieroView/EstudioFinancieroView'),
    props: {
      editRoute: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-edit',
    },
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialEstudioFinanciero.id,
        access: ACCESS.view,
      },
    },
  },
  {
    path: 'clientes-potenciales/:idcliente_potencial/:idestudio_financiero/edit',
    name: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-edit',
    component: load('estudiosFinancieros/estudioFinancieroEdit/EstudioFinancieroEdit'),
    meta: {
      permission: {
        idobjeto: permissions.clientePotencialEstudioFinanciero.id,
        access: ACCESS.edit,
      },
    },

  },
]
