import { ViewStoreBase, CreateStore } from '@/store/pages/base'

const pageStore = {
  state: {
    materiales: [],
  },
  getters: {},
  mutations: {},
  actions: {},
}

export default CreateStore(new ViewStoreBase(), pageStore)
