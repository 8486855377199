import BaseOfflineResource from '@/offline/base'

export default class ChecklistOtSubsisOfflineResource extends BaseOfflineResource {
  async insertSync (values = {}, options = {}) {
    await this.Vue.$offline.sync.insert({
      method: 'checklistOtSubsis.insertSync',
      params: { values },
    })
    return await super.insert(values, options)
  }
  async deleteSync (idchecklistOtSubsis) {
    const tables = this.db.tables
    await this.Vue.$offline.sync.insert({
      method: 'checklistOtSubsis.deleteSync',
      params: {
        idchecklist_ot_subsis: idchecklistOtSubsis
      }
    })
    await this.Vue.$offline.checklistOtSubsis.delete({
      where: tables.checklist_ot_subsis.idchecklist_ot_subsis.eq(idchecklistOtSubsis)
    })
  }
}
