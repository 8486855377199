import BaseOnlineResource from '@/online/base'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default class PresupuestocliMantOnlineResource extends BaseOnlineResource {
  title (row) {
    return row.descripcion
  }
  subtitle (row) {
    return `${row.tactuacion_descripcion} - ${row.periodo_revision_descripcion}`
  }
  searchFilter (value) {
    return new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addILike('descripcion', value)
      .addILike('periodo_revision_descripcion', value)
      .addILike('tactuacion_descripcion', value)
  }
}
