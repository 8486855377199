import { ListStoreBase, CreateStore } from '@/store/pages/base'
import { SISTEMA } from '@/utils/consts'
import filters from '@/utils/filters'

const pageStore = {
  state: {
    filter: {
      descripcion: {
        label: 'Descripción',
        type: 'string',
        value: null,
        field: 'descripcion'
      },
      estado: {
        label: 'Estado del sistema',
        type: 'multiple-select',
        value: null,
        items: [
          {
            estado: SISTEMA.estados.anulado,
            descripcion: filters.capitalize(SISTEMA.descripcionEstado.anulado)
          },
          {
            estado: SISTEMA.estados.activo,
            descripcion: filters.capitalize(SISTEMA.descripcionEstado.activo)
          },
          {
            estado: SISTEMA.estados.baja,
            descripcion: filters.capitalize(SISTEMA.descripcionEstado.baja)
          },
        ],
        field: 'estado',
        selectItemValue: 'estado',
        selectItemText: 'descripcion',
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
}
export default CreateStore(new ListStoreBase(), pageStore)
